import { Component, Input } from "@angular/core";

@Component({
  selector: "switch",
  templateUrl: "switch.html",
  standalone: false,
})
export class SwitchComponent {
  public toggleSwitch = false;

  constructor() {}

  toggle() {
    this.toggleSwitch = !this.toggleSwitch;
  }
}

import { Component, Input, OnInit, OnChanges, OnDestroy } from "@angular/core";
import { RssNewsList } from "../../schemas/rss-news-list";
import { NewsService } from "../../services/news/news.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "news-page",
  templateUrl: "./news.html",
  standalone: false,
})
export class Newspage implements OnInit {
  news: { [key: string]: RssNewsList } | undefined;

  @Input() lang: string | undefined;
  @Input() newsItemId: string | undefined;

  constructor(
    private router: Router,
    private newsService: NewsService,
    private title: Title,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.ngOnInitAsync();
  }

  navigateToLanguage = (lang: string) => this.router.navigate(["news", lang]);

  private async ngOnInitAsync() {
    if (!this.translate.langs.includes(this.lang)) {
      console.warn("Rerouting to 'nl'");
      this.navigateToLanguage("nl");
    }

    this.news = await this.newsService.getNewsItemsAll();
    const newsTranslation = await this.translate
      .get("Navigation.News")
      .toPromise()
      .catch(() => "Nieuws");
    this.title.setTitle(`SURFnet ${newsTranslation.toLowerCase()}`);
  }
}

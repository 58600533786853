import { Component, EventEmitter, Input } from "@angular/core";
import { DialogGenericComponent } from "../../dialogs/dialog-generic/dialog-generic";
import { translateString } from "../../../helpers/translate/translateString";
import { ApiHelper } from "../../../helpers/apihelper";
import { StorageHelper } from "../../../helpers/storage/storagehelper";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "alias-editor",
  templateUrl: "./alias-editor.component.html",
  standalone: false,
})
export class AliasEditorComponent {
  @Input() customerDescription: string;
  @Input() subscriptionId: string;
  isEditable = false;
  temporarySubscriptionName = "";
  public messageBus: EventEmitter<string> = new EventEmitter();

  constructor(
    protected api: ApiHelper,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected translate: TranslateService,
  ) {}

  async startEditingCustomerDescription() {
    this.temporarySubscriptionName = this.customerDescription;
    this.isEditable = true;
  }

  async saveCustomerDescription() {
    if (this.temporarySubscriptionName.length > 0) {
      this.dialog.open(DialogGenericComponent, {
        data: {
          title: await translateString(this.translate, "Subscription.Message.AliasEditTitle", "Edit Alias"),
          message: await translateString(
            this.translate,
            "Subscription.Message.AliasEditMessage",
            "Your change is received and being processed.",
          ),
        },
      });
      this.api
        .set_customer_description(StorageHelper.currentUser, this.subscriptionId, this.temporarySubscriptionName)
        .then((result) => {
          this.customerDescription = this.temporarySubscriptionName;
          this.isEditable = false;
          this.messageBus.emit("refresh");
        })
        .catch((err) => {
          console.log(err);
          this.isEditable = false;
        });
    }
  }

  async cancelEditingCustomerDescription() {
    this.isEditable = false;
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { MessageService } from "../../../services/messageservice";

@Component({
  selector: "filter-bar",
  templateUrl: "filter-bar.html",
  styleUrls: ["filter-bar.scss"],
  standalone: false,
})
export class FilterBarComponent implements OnInit {
  @Input() filterTitle = "";
  @Input() filterIcon = "";
  @Input() filterColor = "";
  @Input() filterClassActive = "";
  @Input() filterDefaultActive = "";
  @Input() filterType = "";
  @Input() filterValue = "";

  public filterBarActive = false;

  constructor(private msgsrv: MessageService) {}

  ngOnInit() {
    if (this.filterBarActive !== this.getDefaultValue()) {
      this.toggleFilter();
    }
  }

  toggleFilter() {
    this.filterBarActive = !this.filterBarActive;
    const msg = { type: this.filterType, value: this.filterValue };
    if (this.filterBarActive) {
      this.msgsrv.enableFilter(msg);
    } else {
      this.msgsrv.disableFilter(msg);
    }
  }

  getDefaultValue() {
    return this.filterDefaultActive && this.filterDefaultActive.length > 0 && this.filterDefaultActive !== "false";
  }

  reset() {
    if (this.filterBarActive !== this.getDefaultValue()) {
      this.toggleFilter();
    }
  }
}

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SurfNotification } from "../../models/notification";

import { MatDialog } from "@angular/material/dialog";
import { DialogAffectedServicesComponent } from "../../../components/dialogs/dialog-affected-services/dialog-affected-services";

import { ICalendar } from "datebook";

@Component({
  selector: "malfunction-card",
  templateUrl: "malfunction-card.html",
  styleUrls: ["malfunction-card.scss"],
  standalone: false,
})
export class MalfunctionCardComponent implements OnChanges {
  @Input() showTimeElement: boolean;
  @Input() message: SurfNotification;
  public isFutureMessage = false;
  public impactsString: string;

  constructor(public dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(DialogAffectedServicesComponent, {
      data: {
        message: this.message,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.forceScrollTop === true) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["message"]) {
      const today = Math.round(new Date().getTime() / 1000);
      const msgTs = new Date(this.message.start_timestamp).getTime();

      if (this.message.start_timestamp > today) {
        this.isFutureMessage = true;
      }
      if (msgTs > new Date().getTime()) {
        this.isFutureMessage = true;
      }

      this.generateImpactsString();
    }
  }

  addToCalendar() {
    const icalendar = new ICalendar({
      title: this.message.heading,
      description: this.message.contents,
      // start: new Date('2020-12-04T19:00:00'),
      // end: new Date('2020-12-04T23:30:00'),
      start: new Date(this.message.start_timestamp),
      end: new Date(this.message.end_timestamp),
    });
    icalendar.download();
  }

  /**
   * find all unique impact_types in the affected_subscriptions array
   * */
  generateImpactsString() {
    const arr = this.message.affected_subscriptions.map((p) => p.impact_type); // get all impact_types
    const s = new Set(arr); // remove duplicates
    this.impactsString = [...s].join(" & "); // convert object to array -> to a comma string
  }
}

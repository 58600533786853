import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import * as pMap from "p-map";
import {
  CustomerSubscriptionWithId,
  CustomerWithSubscriptionIds,
  SubscriptionWithDdosStatus,
} from "../../../graphql/custom-types";
import { SUBSCRIPTIONS_OF_TYPE_QUERY } from "../../../graphql/domain/subscription";
import { CERT_DDOS_QUERY, transform } from "../../../graphql/component/cert-ddos";
import { Instance } from "../../models/instance";
import { AuthService } from "../../../services/AuthService";
import { Router } from "@angular/router";
import { DialogMessageComponent } from "../../dialogs/dialog-message/dialog-message";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { openSelfServiceDialog } from "../../dialogs/dialog-selfservice/dialog-selfservice.helpers";

@Component({
  selector: "cert-security",
  templateUrl: "cert-security.html",
  styleUrls: ["cert-security.scss"],
  standalone: false,
})
export class CertSecurityComponent implements OnInit {
  public subscriptions: any[] = [];
  private subscriptionCount = 1;
  constructor(
    private apollo: Apollo,
    public auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  get loading() {
    return this.subscriptions.length < this.subscriptionCount;
  }

  get hasCorrectRole(): boolean {
    return this.auth.hasRole(["surfcert-kernel", "Beveiligingsverantwoordelijke", "Infraverantwoordelijke"]);
  }

  ngOnInit() {
    this.apollo
      .query<CustomerWithSubscriptionIds>({
        query: SUBSCRIPTIONS_OF_TYPE_QUERY,
        variables: {
          status: ["active", "migrated"],
          products: ["IP", "FW"],
          customer: localStorage.getItem("viewingCustomerGUID"),
        },
      })
      .subscribe(({ data }) => {
        this.subscriptionCount = data?.customer?.subscriptions?.length || 0;
        this.batchSubscriptionData(data?.customer?.subscriptions || []).then(() => {
          // check for pending auth action.
          // if it's there, the state contains a subscription
          if (this.auth.hasPendingStrongAction("certFilter")) {
            this.auth.clearPendingStrongAction("certFilter");
            const state = this.auth.getSelfserviceState();
            if (state?.subscription?.subscriptionId) {
              const subscription = this.subscriptions.find(
                (sub) => sub.subscriptionId === state.subscription.subscriptionId,
              );

              if (subscription) {
                this.openDdosDialog(subscription);
              }
            }
          }
        });
      });
  }

  async batchSubscriptionData(subscriptions: any[]) {
    return await pMap(subscriptions, this.getSubscriptionData.bind(this), { concurrency: 4 });
  }

  async getSubscriptionData(subscription: CustomerSubscriptionWithId) {
    this.apollo
      .query<{ subscription: SubscriptionWithDdosStatus }>({
        query: CERT_DDOS_QUERY,
        variables: {
          id: subscription.subscriptionId,
        },
      })
      .subscribe(({ data }) => {
        const transformedResult = transform(data.subscription);

        const inst = Object.assign(new Instance(), {
          ...subscription,
          insync: data.subscription.insync ?? false,
          ...transformedResult,
        });
        this.subscriptions = [...this.subscriptions.filter((sub) => sub.subscriptionId !== inst.subscriptionId), inst];
      });
  }

  toggleClick(subscription: any) {
    if (!this.hasCorrectRole) {
      return false;
    }

    if (subscription.productType === "IP") {
      // this.auth.requestSelfserviceDialog("ddos");
      // this.routeToService(subscription);
      if (!this.auth.isAuthenticatedForSelfService()) {
        this.dialog.open(DialogAuthComponent, {
          data: {
            selfserviceState: { subscription },
            initialAction: "certFilter",
          },
        });
      } else {
        this.openDdosDialog(subscription);
      }
    } else {
      this.showUnchangeableDialog();
    }
  }

  // notify the user that DDOS cannot be changed
  showUnchangeableDialog() {
    this.dialog.open(DialogMessageComponent, {
      data: {
        title: this.translate.instant("Cert.DDOSSecurity"),
        message: this.translate.instant("Cert.Unchangeable"),
      },
    });
  }

  // open the self service dialog for DDoS.
  // Only supply the needed bits (subscription ID and surfcertFilterEnabled)
  openDdosDialog(subscription: any) {
    const data = {
      type: "ddos",
      subscription: {
        customerId: subscription.customerId,
        description: "",
        insync: subscription.insync,
        ipss: {
          surfcertFilterEnabled: subscription.surfcertFilterEnabled,
        },
        product: {
          type: subscription.productType,
          tag: subscription.productTag,
        },
        subscriptionId: subscription.subscriptionId,
      },
    };

    const dialogRef = openSelfServiceDialog(this.dialog, data);
    dialogRef.componentInstance.close.subscribe((event: string) => {
      if (event === "refresh") {
        this.getSubscriptionData(subscription);
      }
    });
  }

  routeToService(subscription: any) {
    const productType = subscription.productType;
    const productTag = subscription.productTag;
    const subscriptionId = subscription.subscriptionId;
    this.router.navigate([`/subscription/${productType}/${productTag}/${subscriptionId}`]);
  }
}

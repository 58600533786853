import { Component, Input, ViewContainerRef, SimpleChanges, AfterViewInit, OnChanges, OnInit } from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { ApiHelper } from "../../../helpers/apihelper";
import { SurfLog } from "../../../helpers/surflog";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "sla-report",
  templateUrl: "sla-report.html",
  standalone: false,
})
export class SLAReport implements OnChanges {
  @Input() service: any;
  public slsData = null;
  public fetching = true;
  public year: number;
  public month: number;
  public icons = ["frown.svg", "smile.svg"];
  public slsOffset = 0.3;
  public availableYears = [];
  public availableMonths = [];
  public firstMonth = 0;
  public lastMonth = 11;
  public dataError = "";
  public apiError = "";
  private refreshTemplateData = true; // calculate navigation options only if the user adjusts the year

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    public api: ApiHelper,
    public viewContainerRef: ViewContainerRef,
    private translate: TranslateService,
  ) {
    // super(auth, dialog, api, viewContainerRef);
    const date = new Date();
    date.setDate(0);
    this.year = date.getFullYear();
    this.month = date.getMonth();
    // this.year = new Date().getFullYear();
    // // https://stackoverflow.com/questions/605575/getting-the-previous-months-first-date-from-current-date-in-javascript
    // const x = new Date();
    // x.setDate(0); // 0 will result in the last day of the previous month
    // x.setDate(1); // 1 will result in the first day of the month
    // this.month = x.getMonth();
    SurfLog.warn("Loaded SLA-REPORT module");
    this.updateSlsData();
  }

  updateSlsData() {
    // if no selectedCircuit
    if (this.service === undefined) {
      SurfLog.err("Not reloading sls data: no service");
      return;
    }
    // SurfLog.info('update sls data');
    this.fetching = true;
    this.api
      .sls(this.service.subscriptionId, this.year, this.month + 1)
      .then((data) => {
        this.fetching = false;
        this.slsData = data;
        this.dataError = "";
        this.prepareTemplateData();
      })
      .catch((err) => {
        if (err.status === 404) {
          this.dataError = this.translate.instant("SLA.Report.NoData");
          this.slsData = { startDateSubscription: new Date(this.year - 1, this.month, 1).toISOString() };
          this.prepareTemplateData();
        }
        this.fetching = false;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["service"]) {
      this.updateSlsData();
    }
  }

  prepareTemplateData() {
    if (!this.refreshTemplateData) {
      return true;
    }
    this.refreshTemplateData = false;

    this.availableYears = [];
    this.availableMonths = [];

    // get the year of the prev month after subscription startDate
    let date = new Date();
    date.setDate(0);
    const thisYear = date.getFullYear();

    // get the first year of the next month after subscription startDate
    date = new Date(this.slsData.startDateSubscription);
    date.setMonth(date.getMonth() + 1);
    const firstYear = date.getFullYear();

    for (let i = firstYear; i <= thisYear; i++) {
      this.availableYears.push(i);
    }

    // reset to defaults
    this.firstMonth = 0;
    this.lastMonth = 11;

    // try and set sensible values
    if (this.year === firstYear) {
      // get the first month after the subscription startDate
      date = new Date(this.slsData.startDateSubscription + "T12:00:00Z");
      date.setMonth(date.getMonth() + 1); // show net month after subscription start
      this.firstMonth = date.getMonth();

      // if only the year has changed,
      // the month value could be invalid.
      if (this.month < this.firstMonth) {
        this.month = this.firstMonth;
      } else if (this.month > this.lastMonth) {
        this.month = this.lastMonth;
      }
    }

    if (this.year === thisYear) {
      date = new Date();
      date.setMonth(date.getMonth() - 1);
      this.lastMonth = date.getMonth();
      this.month = date.getMonth();
    }
  }

  updateSlsFilter(id: string, value: string) {
    if (id === "year") {
      this.year = parseInt(value);
      this.refreshTemplateData = true;
      this.prepareTemplateData();
    } else if (id === "month") {
      this.month = parseInt(value);
    }

    this.updateSlsData();
  }
}

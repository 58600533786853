/**
 * Created by gert on 22/03/2018.
 */
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "monthname",
  standalone: false,
})
export class MonthNamePipe implements PipeTransform {
  transform(value: number, len?: number): string {
    const names = [
      "Januari",
      "Februari",
      "Maart",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "December",
    ];
    if (value < 0 || value > 11) {
      return "🤔";
    }
    const rv = names[value];
    return len ? rv.substring(0, len) : rv;
  }
}

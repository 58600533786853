import { Component, Input } from "@angular/core";

@Component({
  selector: "footer",
  templateUrl: "footer.html",
  standalone: false,
})
export class FooterComponent {
  @Input() activeItem = 0;

  public enableSupportLink = false;
  public year = new Date().getFullYear();

  constructor() {}
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { PageInfo } from "../../../gql/generated";

@Component({
  selector: "pagination",
  templateUrl: "pagination.html",
  styleUrls: ["pagination.scss"],
  standalone: false,
})
export class PaginationComponent implements OnChanges {
  @Output() paginationChanged = new EventEmitter<number>();
  @Input() pageInfo: PageInfo;
  @Input() itemsPerPage = 20;

  public firstItem: number;
  public lastItem: number;
  public hasNextPage = false;

  constructor() {}

  nextPage() {
    if (this.pageInfo.hasNextPage) {
      let endCursor = (this.pageInfo?.endCursor ?? 0) + 1;
      this.paginationChanged.emit(endCursor);
    }
  }

  prevPage() {
    if (this.pageInfo.hasPreviousPage) {
      let startCursor = (this.pageInfo?.startCursor ?? 0) - this.itemsPerPage;
      this.paginationChanged.emit(Math.max(startCursor, 0));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["pageInfo"]) {
      this.firstItem = Number(this.pageInfo?.startCursor ?? -1) + 1;
      this.lastItem = Number(this.pageInfo?.endCursor ?? -1) + 1;

      // pageInfo.hasNextPage and pageInfo.totalItems seem to be buggy in backend,
      // work around like this.
      this.hasNextPage = (this.pageInfo?.endCursor ?? -2) + 2 === (this.pageInfo?.totalItems ?? 0);
    }
  }
}

<navigation [forceTitle]="'Subscription.notfound.Name' | translate" [activeItem]="" [requestsBubble]="3"></navigation>
<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
        <div class="card">
          <h2>{{ 'Global.NotFound' | translate }}</h2>
          <p>{{ 'Page.NotFound.Description' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container"><footer></footer></div>
</div>

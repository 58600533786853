import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { L2vpnSubscriptionDetailsGQL, L2vpnSubscriptionDetailsQuery } from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { L2vpnSubscriptionDetails } from "../../types";
import { ApolloQueryResult } from "@apollo/client";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "l2vpn-subscription",
  templateUrl: "l2vpn-subscription.html",
  standalone: false,
})
export class L2vpnSubscriptionComponent extends GenericSubscriptionComponent<
  L2vpnSubscriptionDetailsGQL,
  L2vpnSubscriptionDetailsQuery,
  L2vpnSubscriptionDetails
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";

  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected query: L2vpnSubscriptionDetailsGQL,
    protected translate: TranslateService,
  ) {
    super(api, auth, dialog, route, translate);
  }

  protected onQuerySuccess(result: ApolloQueryResult<L2vpnSubscriptionDetailsQuery>) {
    return result.data.l2vpnDetails;
  }
}

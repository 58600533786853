import { Component, Input } from "@angular/core";

@Component({
  selector: "service-tile-available",
  templateUrl: "service-tile-available.html",
  styleUrls: ["../service-tile/service-tile.scss"],
  standalone: false,
})
export class ServiceTileAvailableComponent {
  @Input() serviceUrl = "";
  @Input() serviceTitle = "";
  @Input() serviceIcon = "";
  @Input() healthIndicators;
  @Input() serviceBody = "";
  public hideLoader = false;

  public colors: any = {
    ok: "green",
    notfound: "orange",
    notraffic: "orange",
    pending: "orange",
    initial: "orange",
    stopped: "red",
    unknown: "grey",
  };

  constructor() {}
}

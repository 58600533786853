import { Component, Input } from "@angular/core";

@Component({
  selector: "[third-party-service-ids-row-content]",
  templateUrl: "third-party-service-ids-row-content.html",
  standalone: false,
})
export class ThirdPartyServiceIdsRowContentComponent {
  @Input() subscription;
}

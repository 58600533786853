import { Component } from "@angular/core";
import { AuthService } from "../../../services/AuthService";

@Component({
  selector: "selfservice-authentication-box",
  templateUrl: "selfservice-authentication-box.html",
  styleUrls: ["selfservice-authentication-box.scss"],
  standalone: false,
})
export class SelfserviceAuthenticationBoxComponent {
  constructor(public auth: AuthService) {}
}

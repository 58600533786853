import { Component, Input } from "@angular/core";
import { ProductType } from "../../../helpers/enums/productType";
import { Router } from "@angular/router";
import { CombinedServiceTileHealthIndicators, CombinedServiceTileInput } from "./combined-service-tile.helpers";
import { log } from "console";

const ALLOWED_PRODUCT_TYPES = [
  ProductType.IP,
  ProductType.L3VPN,
  ProductType.Port,
  ProductType.L2VPN,
  ProductType.IPPrefixes,
  ProductType.LightPath,
];

@Component({
  selector: "combined-service-tile",
  templateUrl: "combined-service-tile.html",
  styleUrls: ["combined-service-tile.scss", "../service-tile/service-tile.scss"],
  standalone: false,
})
export class CombinedServiceTileComponent {
  @Input() subscriptions: Map<ProductType, CombinedServiceTileInput> = new Map();

  constructor(private router: Router) {}

  get healthIndicators(): CombinedServiceTileHealthIndicators {
    let okCounts = 0;
    let notrafficCounts = 0;
    let malfunctionCounts = 0;

    for (const type of ALLOWED_PRODUCT_TYPES) {
      const input = this.subscriptions.get(type);
      // we don't include the IP prefix in the count, as it can't report on traffic status
      if (input && input.counts && type != ProductType.IPPrefixes) {
        const counts = input.counts;
        okCounts += counts.ok;
        notrafficCounts += counts.notraffic;
        malfunctionCounts += counts.malfunction;
      }
    }

    return [
      {
        name: "ok",
        icon: "status-ok",
        tooltip: "Services.Status.Ok",
        count: okCounts,
      },
      {
        name: "notraffic",
        icon: "status-no-traffic",
        tooltip: "Services.Status.NoTraffic",
        count: notrafficCounts,
      },
      {
        name: "malfunction",
        icon: "status-warning",
        tooltip: "Services.Status.Warning",
        count: malfunctionCounts,
      },
    ];
  }

  get servicesCount(): number {
    let count = 0;
    for (const type of ALLOWED_PRODUCT_TYPES) {
      const productSubscription = this.subscriptions.get(type);
      if (productSubscription && typeof productSubscription.subscriptions === "number") {
        count += productSubscription.subscriptions;
      }
    }
    return count;
  }

  serviceClick(subscription): void {
    const url = subscription.value.url;
    if (url) {
      window.open(url, "_blank");
    } else {
      if (subscription.key === ProductType.IPPrefixes) {
        this.router.navigate([`/ip-prefixes`]);
      } else {
        this.router.navigate([`/diensten/${subscription.key}`]);
      }
    }
  }

  trackByIndex(index: number): number {
    return index;
  }
}

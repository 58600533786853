/**
 * Created by gert on 22/03/2018.
 */
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "enabled_or_value",
  pure: true,
  standalone: false,
})
export class EnabledPipe implements PipeTransform {
  transform(value: boolean | string): string {
    if (typeof value === "boolean") {
      return value ? "Enabled" : "Disabled";
    }
    return value;
  }
}

import { Input, Component } from "@angular/core";
import { formatDate } from "@angular/common";
import { MessageService } from "../../../services/messageservice";
import { FlatPickrDayCreateOutputOptions, FlatPickrOutputOptions } from "angularx-flatpickr/lib/flatpickr.directive";

@Component({
  selector: "webarchive-calendar",
  templateUrl: "webarchive-calendar.html",
  styleUrls: ["webarchive-calendar.scss"],
  standalone: false,
})
export class WebarchiveCalendarComponent {
  @Input() year: number;
  @Input() webarchiveData: Map<number, { [key: string]: any }>;

  public months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  constructor(private msgsrv: MessageService) {}

  onDayCreate(parameter: FlatPickrDayCreateOutputOptions) {
    // @ts-ignore
    const dateString = formatDate(parameter.dayElement.dateObj, "yyyy-MM-dd", "en-US");
    const maintenanceArray: [string] = this.webarchiveData.get(this.year).get("maintenance");
    const malfunctionArray: [string] = this.webarchiveData.get(this.year).get("malfunction");
    if (maintenanceArray.includes(dateString)) {
      parameter.dayElement.className += " event event-maintenance";
    }
    if (malfunctionArray.includes(dateString)) {
      parameter.dayElement.className += " event event-malfunction";
    }
  }

  onDayChange(parameter: FlatPickrOutputOptions) {
    const msg = {
      filterDate: new Date(parameter.dateString),
    };
    this.msgsrv.setMalfunctionFilterDate(msg);
  }
}

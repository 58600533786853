import { Component, Input } from "@angular/core";
import { MessageService } from "../../../services/messageservice";

@Component({
  selector: "timeline-footer",
  templateUrl: "timeline-footer.html",
  styleUrls: ["timeline-footer.scss"],
  standalone: false,
})
export class TimelineFooterComponent {
  @Input() public showEmptyState: boolean;
  @Input() public hasMoreResults: boolean;
  constructor(private msgsrv: MessageService) {}

  loadMore() {
    this.msgsrv.setMalfunctionLoadMore({});
  }
}

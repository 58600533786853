/**
 * Created by gert on 22/03/2018.
 */
import { PipeTransform, Pipe } from "@angular/core";
import { Instance } from "../../components/models/instance";

@Pipe({
  name: "custom_description",
  pure: true,
  standalone: false,
})
export class CustomDescriptionPipe implements PipeTransform {
  transform(value: any): string {
    const obj = Object.assign(new Instance(), value);
    return obj.presentableName;
  }
}

@Pipe({
  name: "customer_description",
  pure: true,
  standalone: false,
})
export class CustomerDescriptionPipe implements PipeTransform {
  transform(subscription: CustomerDescriptionInput, fallback: string = "-"): string {
    const customerId = localStorage.getItem("viewingCustomerGUID");
    const comparator = (cd: CustomerDescription) => cd.customerId === customerId;
    const description: CustomerDescription | undefined = subscription?.customerDescriptions.find(comparator);
    return description?.description ?? fallback;
  }
}

interface CustomerDescriptionInput {
  customerDescriptions: CustomerDescription[];
}

export interface CustomerDescription {
  customerId: string;
  description: string;
}

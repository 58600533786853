<div class="dialog">
  <div class="dialog__header">
    <h3 class="dialog__title">
      {{ title }}
      <span *ngIf="titleSuffix" class="dialog__title-suffix">{{ titleSuffix }}</span>
    </h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog('refresh')" />
  </div>

  <div
    *ngIf="loadingData"
    class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
  >
    <div>
      <loader *ngIf="loadingData"></loader>
    </div>
  </div>

  <div *ngIf="!loadingData && subscription && !auth.isCurrentOrganisation(subscription.customerId); else allowed">
    No access
  </div>

  <ng-template #allowed>
    <selfservice-unavailable *ngIf="subscription && !subscription.insync" class="dialog__grow-wrapper" />

    <selfservice-ddos
      *ngIf="!loader && data.type === 'ddos' && subscription.insync && isIp(subscription)"
      class="dialog__grow-wrapper"
      (dialogCloseEmitter)="closeDialog($event)"
      [subscription]="subscription"
      [bus]="communication"
    />

    <selfservice-rps
      *ngIf="!loader && data.type === 'rps' && subscription.insync"
      class="dialog__grow-wrapper"
      (dialogCloseEmitter)="closeDialog($event)"
      [subscription]="subscription"
      [bus]="communication"
    />

    <selfservice-speedpolicer
      *ngIf="!loader && data.type === 'sp' && subscription.insync"
      class="dialog__grow-wrapper"
      (dialogCloseEmitter)="closeDialog($event)"
      [subscription]="selfServiceSpCompatible"
      [bus]="communication"
    />

    <selfservice-bfd
      *ngIf="!loader && data.type === 'bfd' && subscription.insync"
      class="dialog__grow-wrapper"
      (dialogCloseEmitter)="closeDialog($event)"
      [subscription]="subscription"
      [instance]="instance"
      [sapIndex]="sapIndex"
      [bus]="communication"
    />

    <selfservice-bgp
      *ngIf="!loader && data.type === 'bgp' && subscription.insync"
      class="dialog__grow-wrapper"
      (dialogCloseEmitter)="closeDialog($event)"
      [subscription]="ipSubscriptionDetails"
      [bus]="communication"
    />

    <selfservice-ip-prefixes
      *ngIf="!loader && data.type === 'ip-prefixes' && subscription.insync"
      class="dialog__grow-wrapper"
      (dialogCloseEmitter)="closeDialog($event)"
      [subscription]="ipSubscriptionDetails"
      [sapIndex]="sapIndex"
      [bus]="communication"
      [currentStep]="data.currentStep"
      [selectedPrefix]="data.selectedPrefix"
    />

    <selfservice-ddos-auto-mitigation
      *ngIf="!loader && !success && data.type === 'autoMitigation' && subscription.insync"
      class="dialog__grow-wrapper"
      (dialogCloseEmitter)="closeDialog($event)"
      [subscription]="subscription"
      [bus]="communication"
      [mitigationState]="data.mitigationState"
    />

    <selfservice-add-port
      *ngIf="!loader && data.type === 'add-port' && subscription.insync"
      class="dialog__grow-wrapper"
      [subscription]="subscription"
      [bus]="communication"
    />

    <selfservice-remove-port
      *ngIf="!loader && data.type === 'remove-port' && subscription.insync"
      class="dialog__grow-wrapper"
      [subscription]="l2vpnSubscriptionDetails"
      [bus]="communication"
    />

    <selfservice-vlan-lightpath
      *ngIf="!loader && data.type === 'vlan-lightpath' && subscription.insync"
      class="dialog__grow-wrapper"
      [subscription]="lightpathSubscriptionDetails"
      [bus]="communication"
    />

    <selfservice-vlan-l2vpn
      *ngIf="!loader && data.type === 'vlan-l2vpn' && subscription.insync"
      class="dialog__grow-wrapper"
      [subscription]="subscription"
      [bus]="communication"
    />

    <selfservice-loader
      *ngIf="loader && !success"
      class="dialog__grow-wrapper"
      [processId]="processId"
      [bus]="communication"
    />

    <selfservice-success *ngIf="success" class="dialog__grow-wrapper" />
  </ng-template>
</div>

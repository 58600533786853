import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ErrorHandler, NgModule, inject, provideAppInitializer } from "@angular/core";
import { Router } from "@angular/router";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import * as Sentry from "@sentry/angular";

import { TruncateModule } from "@yellowspot/ng-truncate";
import { NgxMdModule } from "ngx-md";
import { SafePipe } from "../helpers/safepipe/safepipe";

import { AppComponent } from "./app.component";

import { registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
registerLocaleData(localeNl);

// Dialog components
import { ChooseOrganisationDialogComponent } from "../components/dialogs/choose-organisation/dialog";
import { DialogActivityComponent } from "../components/dialogs/dialog-activity/dialog-activity";
import { DialogAffectedServicesComponent } from "../components/dialogs/dialog-affected-services/dialog-affected-services";
import { DialogAuthComponent } from "../components/dialogs/dialog-auth/dialog-auth";
import { DialogCertComponent } from "../components/dialogs/dialog-cert/dialog-cert";
import { DialogEditComponent } from "../components/dialogs/dialog-edit/dialog-edit";
import { DialogFirewallComponent } from "../components/dialogs/dialog-firewall/dialog-firewall";
import { DialogFulfilmentComponent } from "../components/dialogs/dialog-fulfilment/dialog-fulfilment";
import { DialogIncidentComponent } from "../components/dialogs/dialog-incident/dialog-incident";
import { DialogIPPrefixesAddComponent } from "../components/dialogs/dialog-ip-prefixes-add/dialog-ip-prefixes-add";
import { DialogIPPrefixesRequestComponent } from "../components/dialogs/dialog-ip-prefixes-request/dialog-ip-prefixes-request";
import { DialogNotificationSettingsComponent } from "../components/dialogs/dialog-notification-settings/dialog-notification-settings";
import { DialogOnboardingComponent } from "../components/dialogs/dialog-onboarding/dialog-onboarding";
import { DialogOrderComponent } from "../components/dialogs/dialog-order/dialog-order";
import { DialogSendConfigComponent } from "../components/dialogs/dialog-send-config/dialog-send-config";
import { DialogSlsComponent } from "../components/dialogs/dialog-sls/dialog-sls";
import { DialogTicketsComponent } from "../components/dialogs/dialog-tickets/dialog-tickets";
import { EditLightpathOptionsDirective } from "../components/dialogs/dialog/type/editLightpathOptions";

// Malfunction components
import { MalfunctionCardComponent } from "../components/malfunction/malfunction-card/malfunction-card";
import { MalfunctionFilterComponent } from "../components/malfunction/malfunction-filter/malfunction-filter";
import { TimelineFooterComponent } from "../components/malfunction/timeline-footer/timeline-footer";
import { WebarchiveCalendarComponent } from "../components/malfunction/webarchive-calendar/webarchive-calendar";
import { WebarchiveComponent } from "../components/malfunction/webarchive/webarchive";

// Selfservice components
import { SelfserviceAddPortComponent } from "../components/selfservice/selfservice-add-port/selfservice-add-port";
import { SelfserviceAuthenticationBoxComponent } from "../components/selfservice/selfservice-authentication-box/selfservice-authentication-box";
import { SelfserviceBfdComponent } from "../components/selfservice/selfservice-bfd/selfservice-bfd";
import { SelfserviceBgpComponent } from "../components/selfservice/selfservice-bgp/selfservice-bgp";
import { SelfserviceDdosComponent } from "../components/selfservice/selfservice-ddos/selfservice-ddos";
import { SelfserviceLoaderComponent } from "../components/selfservice/selfservice-loader/selfservice-loader";
import { SelfserviceRemovePortComponent } from "../components/selfservice/selfservice-remove-port/selfservice-remove-port";
import { SelfserviceRpsComponent } from "../components/selfservice/selfservice-rps/selfservice-rps";
import { SelfserviceSpeedPolicerComponent } from "../components/selfservice/selfservice-speedpolicer/selfservice-speedpolicer";
import { SelfserviceSuccessComponent } from "../components/selfservice/selfservice-success/selfservice-success";
import { SelfserviceUnavailableComponent } from "../components/selfservice/selfservice-unavailable/selfservice-unavailable";
import { SelfserviceVlanL2vpnComponent } from "../components/selfservice/selfservice-vlan-l2vpn/selfservice-vlan-l2vpn";
// import { SelfserviceVlanLightpathComponent } from "../components/selfservice/selfservice-vlan-lightpath/selfservice-vlan-lightpath";

import { DialogSelfServiceComponent as DialogSelfServiceComponent } from "../components/dialogs/dialog-selfservice/dialog-selfservice";
import { SelfserviceIPPrefixes } from "../components/selfservice/selfservice-ip-prefixes/selfservice-ip-prefixes";
import { SelfserviceVlanLightpath } from "../components/selfservice/selfservice-vlan-lightpath/selfservice-vlan-lightpath";

// Common components
import { FilterBarComponent } from "../components/common/filter-bar/filter-bar";
import { ScrollEventModule } from "../components/common/ngx-scroll-event/scroll.module";

// Subscriptions (list) components
import { ChecktagComponent } from "../components/subscriptions-list/checktag/checktag";
import { KpiRowComponent } from "../components/subscriptions-list/kpi-row/kpi-row";
import { ServiceElementSimple } from "../components/subscriptions-list/service-element-simple/service-element-simple";
import { ServiceElement } from "../components/subscriptions-list/service-element/service-element";
import { ServiceStatusComponent } from "../components/subscriptions-list/service-status/service-status";

// Subscription detail components
import { ConnectionComponent } from "../components/subscription-detail/connection/connection";
import { EmptyStateComponent } from "../components/subscription-detail/empty-state/empty-state";
import { FirewallRulesComponent } from "../components/subscription-detail/firewall-rules/firewall-rules";
import { KpiTableComponent } from "../components/subscription-detail/kpi-table/kpi-table";
import { TerminatedCardComponent } from "../components/subscription-detail/terminated-card/terminated-card";
import { UserStatsComponent } from "../components/subscription-detail/user-stats/user-stats";
import { PageComponent } from "../components/page/page";
import { SLAReport } from "../components/subscription-detail/sla-report/sla-report";

// Pages
import { HighchartsChartModule } from "highcharts-angular";
import { AboutPage } from "../pages/about/about";
import { AvailabilityExportPage } from "../pages/availability-export/availability-export";
import { DisclaimerPage } from "../pages/disclaimer/disclaimer";
import { ErrorPage } from "../pages/error/error";
import { IPPrefixesPage } from "../pages/ip-prefixes/ip-prefixes";
import { LoginPage } from "../pages/login/login";
import { MalfunctionPage } from "../pages/malfunction/malfunction";
import { MessagecenterPage } from "../pages/messagecenter/messagecenter";
import { NotfoundPage } from "../pages/notfound/notfound";
import { NotificationSettingsPage } from "../pages/notification-settings/notification-settings";
import { SubscriptionDetailRedirectionPage } from "../pages/subscription-detail/subscription-detail-redirection";
import { SubscriptionsPage } from "../pages/subscriptions/subscriptions";
import { SupportPage } from "../pages/support/support";
import { AppRoutingModule } from "./app-routing.module";

import { ApiHelper } from "../helpers/apihelper";
import { AuthService } from "../services/AuthService";
import { MessageService } from "../services/messageservice";
import { SubscriptionService } from "../services/subscriptionservice";

import { ReadableSizePipe, ReadableVolumePipe } from "../helpers/bitspipe/bitspipe";
import { TwentyFourHourTimePipe } from "../helpers/datehelper/24htime";
import { MonthNamePipe } from "../helpers/datehelper/monthname";
import { GuidPipe } from "../helpers/guidpipe/guidpipe";
import { KeysPipe } from "../helpers/keyspipe/keyspipe";
import { AsInstancePipe } from "../helpers/instance/to-instance.pipe";
import { SlsEventDescriptionPipe } from "../helpers/self-service/pipes/eventdescription";
import { StateToCamelcase } from "../helpers/statetocamelcase/statetocamelcase";

import { NWDHttpInterceptor } from "../helpers/interceptor/http.interceptor";
import { ForbiddenPage } from "../pages/forbidden/forbidden";

import { FlatpickrModule } from "angularx-flatpickr";
import { SelfserviceStatusService } from "../helpers/selfservicestatus.service";
import { WebsocketService } from "../helpers/websocket.service";

import { DragDropModule } from "@angular/cdk/drag-drop";
import { DialogMessageComponent } from "../components/dialogs/dialog-message/dialog-message";
import { DialogGenericComponent } from "../components/dialogs/dialog-generic/dialog-generic";
import { DialogRolesComponent } from "../components/dialogs/dialog-roles/dialog-roles";
import { CustomerDescriptionPipe, CustomDescriptionPipe } from "../helpers/descriptionpipe/customdescription";

import { ApolloModule } from "apollo-angular";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CertChartComponent } from "../components/cert/cert-chart/cert-chart";
import { CertDdosLinechartComponent } from "../components/cert/cert-ddos-chart/cert-ddos-chart";
import { CertFilterComponent } from "../components/cert/cert-filter/cert-filter";
import { CertHeaderComponent } from "../components/cert/cert-header/cert-header";
import { CertMitigationComponent } from "../components/cert/cert-mitigation/cert-mitigation";
import { CertSecurityComponent } from "../components/cert/cert-security/cert-security";
import { CertTableComponent } from "../components/cert/cert-table/cert-table";
import { CertTabsComponent } from "../components/cert/cert-tabs/cert-tabs";
import { FooterComponent } from "../components/common/footer/footer";
import { TagsInputModule } from "../components/common/ngx-tags-input/tags-input.module";
import { SwitchComponent } from "../components/common/switch/switch";
import { SelfserviceDdosAutoMitigationComponent } from "../components/selfservice/selfservice-ddos-auto-mitigation/selfservice-ddos-auto-mitigation";
import { CertPage } from "../pages/cert/cert";
import { CacheService } from "../services/cacheservice";
import { NwdSharedModule } from "./nwd-shared/nwd-shared.module";
import { ApiModule as DomainsApiModule } from "./domains-api/api.module";
import { EffectsModule } from "@ngrx/effects";

import { PortSubscriptionComponent } from "../pages/subscription-detail/components/port-subscription/port-subscription";
import { MscSubscriptionComponent } from "../pages/subscription-detail/components/port-subscription/msc-subscription";
import { PortVisualiser } from "../components/subscription-detail/port-visualiser/port-visualiser";
import { SubscriptionDetailContainer } from "../components/subscription-detail/subscription-detail-container/subscription-detail-container";
import { IrbSubscriptionComponent } from "../pages/subscription-detail/components/irb-subscription/irb-subscription";
import { ServiceSummary } from "../components/subscription-detail/service-summary/service-summary";
import { FirewallSubscriptionComponent } from "../pages/subscription-detail/components/firewall-subscription/firewall-subscription";
import { L2vpnSubscriptionComponent } from "../pages/subscription-detail/components/l2vpn-subscription/l2vpn-subscription";
import { L3vpnSubscriptionComponent } from "../pages/subscription-detail/components/l3vpn-subscription/l3vpn-subscription";
import { WirelessSubscriptionComponent } from "../pages/subscription-detail/components/wireless-subscription/wireless-subscription";
import { IpBgpSubscriptionComponent } from "../pages/subscription-detail/components/ip-subscription/ip-bgp-subscription";
import { IpStaticSubscriptionComponent } from "../pages/subscription-detail/components/ip-subscription/ip-static-subscription";
import { AccessPointsComponent } from "../components/subscription-detail/access-points/access-points";
import { TabbedDetailContainer } from "../components/subscription-detail/tabbed-detail-container/tabbed-detail-container";
import { ThirdPartyServiceIdsRowContentComponent } from "../components/subscription-detail/third-party-service-ids-row-content/third-party-service-ids-row-content";
import { NotificationButton } from "../components/subscription-detail/notification-button/notification-button";
import { ReportIncident } from "../components/subscription-detail/report-incident/report-incident";
import { SubscriptionDetailHeader } from "../components/subscription-detail/subscription-detail-header/subscription-detail-header";

// add non-default functionality to HighCharts here.
// the modules are already available, they just
// need loading and extending HighCharts
import * as Highcharts from "highcharts";
import HC_nodata from "highcharts/modules/no-data-to-display";
import HC_export from "highcharts/modules/exporting";
import HC_exportdata from "highcharts/modules/export-data";
import HC_networkgraph from "highcharts/modules/networkgraph";
import HC_offline from "highcharts/modules/offline-exporting";
import { ShortcutMenu } from "../components/subscription-detail/shortcut-menu/shortcut-menu";
import { LinechartComponent } from "../components/subscription-detail/linechart/linechart";
import { PortTile } from "../components/subscription-detail/port-tile/port-tile";
import { LightpathSubscriptionComponent } from "../pages/subscription-detail/components/lightpath-subscription/lightpath-subscription";
import { PageTabComponent } from "../components/subscription-detail/page-tab/page-tab";
import { NetworkgraphComponent } from "../components/subscription-detail/networkgraph/networkgraph";
import { LightpathRedundantSubscriptionComponent } from "../pages/subscription-detail/components/lightpath-subscription/lightpath-redundant-subscription";
import { ServiceTileComponent } from "../components/dashboard/service-tile/service-tile";
import { ServiceTileAvailableComponent } from "../components/dashboard/service-tile-available/service-tile-available";
import { CombinedServiceTileComponent } from "../components/dashboard/combined-service-tile/combined-service-tile";
import { DashboardPage } from "../pages/dashboard/dashboard";
import { ActivityContainer } from "../components/dashboard/activity-container/activity-container";
import { NotificationContainer } from "../components/dashboard/notification-container/notification-container";
import { UplinkDetailContainer } from "../components/subscription-detail/uplink-detail-container/uplink-detail-container";
import { DialogIPPrefixesComponent } from "../components/dialogs/dialog-ip-prefixes/dialog-ip-prefixes";
import { SystemStatsComponent } from "../components/subscription-detail/system-stats/system-stats";
import { ENV } from "./app.runtime";
import { AliasEditorComponent } from "../components/common/alias-editor/alias-editor.component";
import { AggspSubscriptionComponent } from "../pages/subscription-detail/components/port-subscription/aggsp-subscription";
import { NewsModule } from "./news/news.module";
import { NWD_VERSION } from "../version";

HC_nodata(Highcharts);
HC_export(Highcharts);
HC_exportdata(Highcharts);
HC_networkgraph(Highcharts);
HC_offline(Highcharts);

Highcharts.setOptions({
  time: {
    timezone: "Europe/Amsterdam",
    useUTC: false,
  },
  lang: {
    noData: "Geen data beschikbaar",
  },
});

// AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, "./assets/i18n/", `.json?v=${NWD_VERSION}`);
export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, "./assets/i18n/", `.json?v=${NWD_VERSION}`);

const providers = [
  ApiHelper,
  AuthService,
  SubscriptionService,
  MessageService,
  CacheService,
  WebsocketService,
  SelfserviceStatusService,
  { provide: HTTP_INTERCEPTORS, useClass: NWDHttpInterceptor, multi: true },
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
];

if (ENV.ENABLED_ROUTES.split(",").includes("dashboard")) {
  // TODO Make dashboard, news, domains part of an enum
  // TODO Put dashboard specific declarations, providers, etc. in dashboard module
  const requireAuth = provideAppInitializer(() => {
    const initializerFn = (
      (ds: AuthService) => () =>
        ds.load()
    )(inject(AuthService));
    return initializerFn();
  });
  // @ts-ignore Provider object is valid
  providers.push(requireAuth);

  // Hack: In case we have dashboard routes enabled, we want to set the site title to Netwerk Dashboard
  // This is done like this, because when we only enable the news routes, we don't want to see Netwerk Dashboard in the title
  const setNwdTitle = provideAppInitializer(() => {
    const initializerFn = (
      (title: Title) => () =>
        title.setTitle("Netwerk Dashboard")
    )(inject(Title));
    return initializerFn();
  });
  // @ts-ignore Provider object is valid
  providers.push(setNwdTitle);
}

@NgModule({
  declarations: [
    AppComponent,
    ServiceStatusComponent,
    FilterBarComponent,
    ChecktagComponent,
    SwitchComponent,
    FooterComponent,
    NetworkgraphComponent,
    EmptyStateComponent,
    DialogAffectedServicesComponent,
    DialogIPPrefixesRequestComponent,
    DialogIPPrefixesAddComponent,
    SubscriptionsPage,
    SupportPage,
    MessagecenterPage,
    AboutPage,
    DisclaimerPage,
    IPPrefixesPage,
    LoginPage,
    MalfunctionPage,
    ErrorPage,
    NotfoundPage,
    ForbiddenPage,
    CertPage,
    AvailabilityExportPage,
    NotificationSettingsPage,
    SubscriptionDetailRedirectionPage,
    SafePipe,
    KeysPipe,
    GuidPipe,
    StateToCamelcase,
    ReadableSizePipe,
    ReadableVolumePipe,
    MonthNamePipe,
    TwentyFourHourTimePipe,
    SlsEventDescriptionPipe,
    CustomDescriptionPipe,
    DialogTicketsComponent,
    DialogEditComponent,
    DialogOrderComponent,
    DialogFirewallComponent,
    DialogMessageComponent,
    DialogGenericComponent,
    DialogRolesComponent,
    DialogAuthComponent,
    DialogSelfServiceComponent,
    DialogOnboardingComponent,
    DialogFulfilmentComponent,
    DialogActivityComponent,
    DialogAffectedServicesComponent,
    DialogSendConfigComponent,
    DialogCertComponent,
    DialogIncidentComponent,
    DialogNotificationSettingsComponent,
    ChooseOrganisationDialogComponent,
    ServiceElement,
    ServiceElementSimple,
    EditLightpathOptionsDirective,
    SLAReport,
    ConnectionComponent,
    DialogSlsComponent,
    MalfunctionCardComponent,
    MalfunctionFilterComponent,
    WebarchiveComponent,
    WebarchiveCalendarComponent,
    TimelineFooterComponent,
    FirewallRulesComponent,
    AccessPointsComponent,
    KpiTableComponent,
    TerminatedCardComponent,
    UserStatsComponent,
    SelfserviceDdosComponent,
    SelfserviceDdosAutoMitigationComponent,
    SelfserviceRpsComponent,
    SelfserviceSpeedPolicerComponent,
    SelfserviceBfdComponent,
    SelfserviceBgpComponent,
    SelfserviceAddPortComponent,
    SelfserviceRemovePortComponent,
    SelfserviceVlanL2vpnComponent,
    SelfserviceAuthenticationBoxComponent,
    SelfserviceLoaderComponent,
    SelfserviceSuccessComponent,
    SelfserviceUnavailableComponent,
    KpiRowComponent,
    CertChartComponent,
    CertFilterComponent,
    CertHeaderComponent,
    CertMitigationComponent,
    CertSecurityComponent,
    CertTableComponent,
    CertTabsComponent,
    CertDdosLinechartComponent,
    PortSubscriptionComponent,
    MscSubscriptionComponent,
    AggspSubscriptionComponent,
    WirelessSubscriptionComponent,
    FirewallSubscriptionComponent,
    IpBgpSubscriptionComponent,
    IpStaticSubscriptionComponent,
    PortVisualiser,
    SubscriptionDetailContainer,
    TabbedDetailContainer,
    UplinkDetailContainer,
    CustomerDescriptionPipe,
    ServiceSummary,
    ShortcutMenu,
    LinechartComponent,
    IrbSubscriptionComponent,
    PortTile,
    LightpathSubscriptionComponent,
    LightpathRedundantSubscriptionComponent,
    PageTabComponent,
    NetworkgraphComponent,
    ServiceTileComponent,
    ServiceTileAvailableComponent,
    CombinedServiceTileComponent,
    DashboardPage,
    ActivityContainer,
    NotificationContainer,
    L2vpnSubscriptionComponent,
    L3vpnSubscriptionComponent,
    ThirdPartyServiceIdsRowContentComponent,
    PageComponent,
    NotificationButton,
    ReportIncident,
    SubscriptionDetailHeader,
    SelfserviceIPPrefixes,
    SelfserviceVlanLightpath,
    DialogIPPrefixesComponent,
    SystemStatsComponent,
    AliasEditorComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    NwdSharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularSvgIconModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    TruncateModule,
    ScrollEventModule,
    BrowserAnimationsModule,
    TagsInputModule.forRoot(),
    NgxMdModule.forRoot(),
    HighchartsChartModule,
    NgxSkeletonLoaderModule,
    FlatpickrModule.forRoot(),
    DragDropModule,
    NgMultiSelectDropDownModule.forRoot(),
    ApolloModule,
    DomainsApiModule.forRoot({
      rootUrl: ENV.DOMAINS_API,
    }),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    EffectsModule.forRoot([]),
    AsInstancePipe,
    NewsModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi()), ...providers],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}

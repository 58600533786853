import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "keys",
  standalone: false,
})
export class KeysPipe implements PipeTransform {
  transform(value): string[] {
    return Object.keys(value);
  }
}

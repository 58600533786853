import { Component, Input, Output, Inject, EventEmitter } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
  selector: "dialog-edit",
  templateUrl: "dialog-edit.html",
  standalone: false,
})
export class DialogEditComponent {
  @Output() oneditready: EventEmitter<any> = new EventEmitter();
  public service: any;
  public editType: number;

  public EDIT_TYPE_NAME = 1;
  public EDIT_TYPE_OPTIONS = 2;
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.service = data.service;
    this.editType = data.editType;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirm() {
    this.oneditready.emit(this.service);
  }
}

import { NgModule } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { provideRouter, withComponentInputBinding } from "@angular/router";
import { routes } from "./news-routing.module";

import { NewsRoutingModule } from "./news-routing.module";
import { NwdSharedModule } from "../nwd-shared/nwd-shared.module";
import { NewsHeaders } from "./components/news-headers-list/news-headers-list.component";
import { NewsService } from "./services/news/news.service";
import { ParseDatePipe } from "./pipes/parse-date.pipe";
import { NewsItemComponent } from "./components/news-item/news-item.component";
import { SelectNewsItemPipe } from "./pipes/find-news-item.pipe";
import { GetRssNewsItemLink } from "./pipes/get-rss-news-item-link";
import { NavigationNews } from "./components/navigation-news/navigation-news.component";
import { Newspage } from "./pages/news/news";

import localeNl from "@angular/common/locales/nl";
import localeEn from "@angular/common/locales/en";
import { ExtractNewsItemsSafelyPipe } from "./pipes/extract-news-items.pipe";
registerLocaleData(localeNl, "nl");
registerLocaleData(localeEn, "en");

@NgModule({
  declarations: [
    ExtractNewsItemsSafelyPipe,
    SelectNewsItemPipe,
    NavigationNews,
    NewsHeaders,
    NewsItemComponent,
    Newspage,
    ParseDatePipe,
    GetRssNewsItemLink,
  ],
  imports: [CommonModule, NewsRoutingModule, NwdSharedModule, StoreDevtoolsModule.instrument({ maxAge: 25 })],
  exports: [NewsHeaders, ExtractNewsItemsSafelyPipe],
  providers: [NewsService, provideRouter(routes, withComponentInputBinding())],
})
export class NewsModule {}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Instance } from "../../components/models/instance";
import { SlsReport } from "../../helpers/self-service/models/types";
import { SubscriptionService } from "../../services/subscriptionservice";

@Component({
  selector: "page-availability-export",
  templateUrl: "availability-export.html",
  styleUrls: ["availability-export.scss"],
  standalone: false,
})
export class AvailabilityExportPage {
  public subscription: Instance;
  public slsReport: SlsReport;
  public productType = "";
  public reportReady = false;

  constructor(
    private router: Router,
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute,
  ) {
    const subscriptionId = this.route.snapshot.paramMap.get("id");
    this.subscriptionService.getBaseSubscription(subscriptionId).then((basicSub: any) => {
      this.productType = basicSub.product.productType;
      this.subscriptionService.getSubscriptionDetails(this.productType, subscriptionId).then((extendedSub: any) => {
        this.subscription = extendedSub;
        this.getSlsReport();
      });
    });
  }

  get currentDate() {
    return new Date();
  }

  get url() {
    if (this.subscription && this.productType) {
      return window.location.hostname + "/subscription/" + this.productType + "/" + this.subscription.subscriptionId;
    }
    return "";
  }

  get customerDescriptionExists() {
    const obj = this.subscription?.customerDescriptions;

    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
      return false;
    }
    return true;
  }

  getSlsReport() {
    this.subscriptionService.getSlsReport(this.subscription.subscriptionId).then((sls: SlsReport) => {
      this.slsReport = sls;
      this.slsReport.months = sls.months.map((m) => ({
        ...m,
        date: new Date(m.firstDayOfTheMonth),
      }));
      console.log(this.slsReport);
      this.reportReady = true;
    });
  }

  print() {
    window.print();
  }
}

import { Component, EventEmitter, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Message, SelfserviceStatusService } from "../../../helpers/selfservicestatus.service";

@Component({
  selector: "selfservice-loader",
  templateUrl: "selfservice-loader.html",
  styleUrls: ["selfservice-loader.scss"],
  standalone: false,
})
export class SelfserviceLoaderComponent implements OnChanges {
  @Input() processId = "";
  @Input() bus: EventEmitter<any>;
  public progress = 0;
  public errorState = false;
  public loading = false;
  public buttonLoading = false;
  private timerId;

  constructor(private selfserviceStatusService: SelfserviceStatusService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["processId"]) {
      this.selfserviceStatusService.setProcessId(this.processId);
      this.selfserviceStatusService.messages.subscribe(
        (msg: Message) => {
          this.progress = msg.percentage;
          switch (msg.status) {
            case "failed":
              this.errorState = true;
              clearInterval(this.timerId);
              break;
            case "completed":
              clearInterval(this.timerId);
              this.bus.emit({ status: msg.status });
              this.selfserviceStatusService.close();
              break;
            default:
              break;
          }
        },
        (err: any) => {
          this.errorState = true;
        },
      );

      this.timerId = setInterval(() => (this.errorState = true), 600000);
    }
  }

  close() {
    this.bus.emit({ status: "error" });
    this.selfserviceStatusService.close();
  }

  notifySupport() {
    this.bus.emit({ status: "notify_support" });
  }
}

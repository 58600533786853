import { Component, Input, Inject } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SurfNotification } from "../../models/notification";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "dialog-affected-services",
  templateUrl: "dialog-affected-services.html",
  styleUrls: ["dialog-affected-services.scss"],
  standalone: false,
})
export class DialogAffectedServicesComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: SurfNotification },
    public dialogRef: MatDialogRef<DialogAffectedServicesComponent>,
    private router: Router,
  ) {}

  closeDialog(): void {
    this.dialogRef.close({ forceScrollTop: false });
  }

  segueToService(affectedService: any) {
    this.router.navigate([`/subscription/${affectedService.product_type}/${affectedService.subscription_id}`]);
    this.dialogRef.close({ forceScrollTop: true });
  }
}

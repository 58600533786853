<page
  [detailData]="{title: pageTitle, status: subscription?.status}"
  [firewalled]="firewalled"
  [productType]="productType"
  [terminated]="isTerminated"
>
  <div class="container" *ngIf="subscription">
    <div class="row">
      <div class="col-lg-3 col--flex">
        <shortcut-menu
          *ngIf="!isTerminated && subscription"
          [subscription]="subscription"
          [productType]="productType"
          [messages]="activities"
        ></shortcut-menu>
        <terminated-card *ngIf="isTerminated" [date]="subscription?.endDate * 1000 | date:'y-MM-dd hh:mm'">
        </terminated-card>
      </div>

      <div class="col-lg-9">
        <div class="card">
          <subscription-detail-header [subscription]="subscription" [isTerminated]="isTerminated" />

          <div class="row">
            <div class="col-12 col-sm-7">
              <table class="table-properties table-properties--no-margin">
                <tr>
                  <td>{{ 'ServiceElement.Owner' | translate }}</td>
                  <td>{{ subscription?.organisation }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.OwnAlias' | translate }}</td>
                  <td>
                    <alias-editor
                      [customerDescription]="subscription.customerDescription"
                      [subscriptionId]="subscription.subscriptionId"
                    />
                  </td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.SURFnetDescription' | translate }}</td>
                  <td>{{ subscription?.description }}</td>
                </tr>
                <tr
                  third-party-service-ids-row-content
                  *ngIf="subscription?.references?.length"
                  [subscription]="subscription"
                ></tr>
                <tr
                  third-party-service-ids-row-content
                  *ngIf="subscription.references?.length"
                  [subscription]="subscription"
                ></tr>
                <tr>
                  <td>{{ 'ServiceElement.PortID' | translate }}</td>
                  <td>
                    <span class="clipboard__rule" *ngIf="subscription?.subscriptionId"
                      >{{ subscription?.subscriptionId | shorten_guid }}
                      <span class="clipboard__link" ngxClipboard [cbContent]="subscription?.subscriptionId">
                        <div class="clipboard__icon">
                          <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
                        </div> </span
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.PortSpeed' | translate }}</td>
                  <td>{{ subscription?.portSpeed * 1000000 | readable_size }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.PortMode' | translate }}</td>
                  <td>{{ subscription?.portMode }}</td>
                </tr>
                <tr *ngIf="subscription?.autoNegotiation">
                  <td>{{ 'ServiceElement.Autonegotiation' | translate }}</td>
                  <td>{{ subscription.autoNegotiation | enabled_or_value }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.L2MTU' | translate }}</td>
                  <td>9100</td>
                </tr>
                <tr *ngIf="subscription?.product.tag === 'SP' && subscription.aggregateParent?.customerDescription">
                  <td>{{ 'ServiceElement.MemberOfAggregate' | translate }}</td>
                  <td>
                    <a
                      *ngIf="subscription.aggregateParent"
                      [routerLink]="['/subscription/Port/AGGSP/', subscription.aggregateParent.subscriptionId]"
                      class="link"
                    >
                      {{ subscription.aggregateParent.customerDescription }}
                    </a>
                  </td>
                </tr>
                <ng-container [ngSwitch]="true">
                  <tr *ngSwitchCase="subscription?.portSubscription && subscription?.product.tag === 'AGGSP'">
                    <td>{{ 'ServiceElement.PhysicalPort' | translate }}</td>
                    <td>
                      <a
                        *ngIf="linkPortSubscription"
                        [routerLink]="['/subscription/Port/' + subscription.portSubscription]"
                        class="link"
                        >{{ portSubscriptionName }}</a
                      >
                      <span *ngIf="!linkPortSubscription">{{ portSubscriptionName }}</span>
                    </td>
                  </tr>
                  <tr *ngSwitchCase="subscription?.portSubscription && subscription?.product.tag === 'MSC'">
                    <td>{{ 'ServiceElement.PhysicalPort' | translate }}</td>
                    <td>
                      <!-- @todo include linked-subscription tag -->
                      <a
                        *ngIf="linkPortSubscription"
                        [routerLink]="['/subscription/' + subscription.portSubscription.subscriptionId]"
                        class="link"
                      >
                        {{ subscription.portSubscription.description }}
                      </a>
                      <span *ngIf="!linkPortSubscription"> {{ subscription.portSubscription?.description }} </span>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
            <div class="col-12 col-sm-5">
              <table class="table-properties table-properties--no-margin">
                <tr *ngIf="!isTerminated">
                  <td>{{ 'ServiceElement.City' | translate }}</td>
                  <td>
                    <span *ngIf="subscription?.address"
                      >{{ subscription?.address.city }} ({{ subscription?.surfLocationCode }})</span
                    >
                  </td>
                </tr>
                <tr *ngIf="!isTerminated">
                  <td>{{ 'ServiceElement.Address' | translate }}</td>
                  <td>
                    <span *ngIf="subscription?.address"
                      >{{ subscription?.address.street }} {{ subscription?.address.number }} {{
                      subscription?.address.city }}</span
                    >
                  </td>
                </tr>
                <tr *ngIf="subscription?.patchPosition && !isTerminated">
                  <td>{{ 'ServiceElement.PatchPosition' | translate }}</td>
                  <td>{{ subscription?.patchPosition }}</td>
                </tr>
                <ng-container [ngSwitch]="true">
                  <tr *ngSwitchCase="!isTerminated && !!subscription?.ieeeInterfaceType">
                    <td>{{ 'ServiceElement.InterfaceType' | translate }}</td>
                    <td>{{ subscription?.ieeeInterfaceType }}</td>
                  </tr>
                  <tr *ngSwitchCase="subscription?.product.tag === 'AGGSP'">
                    <td>{{ 'ServiceElement.InterfaceType' | translate }}</td>
                    <td>Aggregate</td>
                  </tr>
                  <tr *ngSwitchCase="subscription?.product.tag === 'MSC'">
                    <td>{{ 'ServiceElement.InterfaceType' | translate }}</td>
                    <td>Virtual Port</td>
                  </tr>
                </ng-container>
                <tr *ngIf="subscription?.fiberType && !isTerminated">
                  <td>{{ 'ServiceElement.FiberConnectorType' | translate }}</td>
                  <td>{{ subscription?.fiberType }} {{ subscription?.connectorType }}</td>
                </tr>
                <tr *ngIf="isTerminated">
                  <td>{{ 'ServiceElement.Status' | translate }}</td>
                  <td>{{ subscription?.status }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.Start' | translate }}</td>
                  <td>{{ subscription?.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
                </tr>
                <tr *ngIf="isTerminated">
                  <td>{{ 'ServiceElement.End' | translate }}</td>
                  <td>{{ subscription?.endDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.Domain' | translate }}</td>
                  <td>{{ subscription?.domain }}</td>
                </tr>
                <tr *ngIf="subscription?.serviceTag">
                  <td>{{ 'ServiceElement.ServiceTag' | translate }}</td>
                  <td>{{ subscription?.serviceTag }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isTerminated" class="hide-everything-after-this-div"></div>
    <div class="divider">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div *ngIf="subscription">
      <div class="section">
        <subscription-detail-container
          [productType]="productType"
          [subscription]="subscription"
        ></subscription-detail-container>
      </div>
      <div class="section">
        <div
          class="row"
          *ngIf="subscription.linkMemberSubscriptions !== null && subscription.linkMemberSubscriptions !== undefined"
        >
          <div class="col-12">
            <br />
            <h3 class="title-with-icon">
              <svg-icon src="assets/images/icons/port.svg" class="purple"></svg-icon>
              <span>Linkmembers</span>
            </h3>
          </div>
          <div class="col-12 col-md-6" *ngFor="let linkMemberSubscription of subscription.linkMemberSubscriptions">
            <port-tile [service]="linkMemberSubscription" />
          </div>
        </div>
      </div>
    </div>
    <linechart *ngIf="subscription" id="chart" #chart [subscription]="subscription" />
  </div>
</page>

import { Apollo } from "apollo-angular";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as dayjs from "dayjs";
import { ApiHelper } from "../../../helpers/apihelper";
import { CIM_QUERY } from "../../../graphql/domain/cim";
import { Notification } from "../../../gql/generated";
import { environment } from "../../../environments/environment";
import {
  cimToPlannedWork,
  filterCimAndPlannedWork,
  mergeCimAndPlannedWork,
  sortNotifications,
} from "../../../helpers/subscription/planned-work";
import { CimQueryImpactTypes, CimQueryStatuses } from "../../../graphql/custom-types";

@Component({
  selector: "service-status",
  templateUrl: "service-status.html",
  standalone: false,
})
export class ServiceStatusComponent implements OnInit {
  @Input() subscriptionId = "";
  @Input() productType = "";

  /**
   * A list of notifications received from the backend.
   */
  public notifications = [];
  public cimNotifications: Notification[] = [];
  public messageIcon = "";
  public messageIconColor = "";

  constructor(
    public api: ApiHelper,
    private apollo: Apollo,
    public dialog: MatDialog,
  ) {}

  get notificationIcon(): string {
    const thisNotification = this.firstNotification;

    if (!thisNotification) {
      return "";
    }

    switch (thisNotification.category) {
      case "Malfunction":
        return "status-malfunction";
      case "PlannedWork":
        if (this.isNowActive(thisNotification)) {
          return "status-maintenance";
        } else {
          return "status-maintenance-planned";
        }
      case "General":
        return "status-maintenance";
    }
  }

  get notificationColor(): string {
    const thisNotification = this.firstNotification;

    if (!thisNotification) {
      return "";
    }

    switch (thisNotification.category) {
      case "Malfunction":
        return "red";
      case "PlannedWork":
        return "blue";
      case "General":
        return "blue";
    }
  }

  get messageDescription(): string {
    const thisNotification = this.firstNotification;
    if (!thisNotification) {
      return "";
    }

    switch (thisNotification.category) {
      case "PlannedWork":
        return "Onderhoud bezig";
      case "Malfunction":
        return "red";
      case "General":
        return "blue";
    }
  }

  get impactType(): string {
    const thisNotification = this.firstNotification;
    if (!thisNotification) {
      return "";
    }

    if (thisNotification.affected_subscriptions?.length) {
      return thisNotification.affected_subscriptions[Object.keys(thisNotification.affected_subscriptions)[0]]
        .impact_type;
    }
    return thisNotification.impact;
  }

  get firstNotification(): any {
    // const notificationList = mergeCimAndPlannedWork(this.cimNotifications, this.notifications);
    return this.notifications.length ? this.notifications[0] : null;
  }

  ngOnInit() {
    this.loadHealthForSubscriptions(this.subscriptionId);
  }

  openMessage() {
    // this.router.navigate([this.messageLink]);
  }

  async loadHealthForSubscriptions(subscriptionId: string) {
    this.apollo
      .query<any>({
        query: CIM_QUERY,
        variables: {
          includeAffectedSubscriptions: false,
          filter: {
            subscriptionIds: [subscriptionId],
            beginTimestamp: dayjs().startOf("day").toDate(),
            endTimestamp: dayjs().add(2, "week").toDate(),
            impacts: [
              CimQueryImpactTypes.DOWN,
              CimQueryImpactTypes.REDUCED_REDUNDANCY,
              CimQueryImpactTypes.RESILIENCE_LOSS,
            ],
            statuses: [CimQueryStatuses.OPEN, CimQueryStatuses.UPDATED],
          },
        },
      })
      .subscribe((result) => {
        // TODO #206 Address technical debt Notifications/Messages/PlannedWorks
        this.cimNotifications = this.notifications = sortNotifications(
          mergeCimAndPlannedWork(result.data.notifications, []),
        );
      });
  }

  getRouterLink() {
    return `/storing-en-onderhoud/${this.productType}/${this.subscriptionId}`;
  }

  isNowActive(thisNotification) {
    const timestampNow = Date.now();
    if (thisNotification.start_timestamp && timestampNow > thisNotification.start_timestamp) {
      return true;
    }

    return false;
  }
}

import { Component, Inject, Output, EventEmitter } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "dialog-generic",
  templateUrl: "dialog-generic.html",
  styleUrls: ["dialog-generic.scss"],
  standalone: false,
})
export class DialogGenericComponent {
  @Output() public emitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public title: any;
  public message: any;
  public state: "success" | "error";
  public code: any;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogGenericComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.message = data.message;
    this.state = data.state;
    this.code = data.code;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

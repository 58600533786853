import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FulfilmentRequest } from "../../../helpers/self-service/models/types";
import { ProductType } from "../../../helpers/enums/productType";
import { TranslateService } from "@ngx-translate/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { AuthService } from "../../../services/AuthService";

@Component({
  selector: "dialog-ip-prefixes-request",
  templateUrl: "dialog-ip-prefixes-request.html",
  styleUrls: ["dialog-ip-prefixes-request.scss"],
  standalone: false,
})
export class DialogIPPrefixesRequestComponent {
  public activeStep = 1;
  public ipv4addresses = [
    "/31 (2 adressen)",
    "/30 (4 adressen)",
    "/29 (8 adressen)",
    "/28 (16 adressen)",
    "/27 (32 adressen)",
    "/26 (64 adressen)",
    "/25 (128 adressen)",
    "/24 (256 adressen)",
    "/23 (512 adressen)",
    "/22 (1024 adressen)",
    "/21 (2048 adressen)",
    "/20 (4096 adressen)",
    "/19 (8192 adressen)",
    "/18 (16384 adressen)",
    "/17 (32768 adressen)",
    "/16 (65536 adressen)",
  ];
  public ipv6addresses = ["/48", "/47", "/46", "/45", "/44", "/43", "/42", "/41", "/40"];

  public model = {
    ipv: "",
    addressSpace: "",
    addressSpaceReason: "",
    ippAmount: "",
    addressSpaceReasonWhyNot: "",
    ippGrowthExpected: "",
    ippIPV6: "",
    fullName: "",
    email: "",
    phone: "",
  };

  public possibleAddresses = [];

  errorMessage = "";
  successMessage = "";
  numberplanUpload: File;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogIPPrefixesRequestComponent>,
    private router: Router,
    private auth: AuthService,
    private translate: TranslateService,
    private api: ApiHelper,
  ) {
    this.model.fullName = this.auth.state.currentUser.displayName;
    this.model.email = this.auth.state.currentUser.email;
  }

  next() {
    this.activeStep += 1;
  }

  prev() {
    this.activeStep -= 1;
    this.errorMessage = "";
  }

  onFileSelected(event) {
    this.numberplanUpload = event.target.files[0];
  }

  async submit() {
    const customerId = localStorage.getItem("viewingCustomerGUID");
    let attachments = [];

    if (this.model.ipv === "") {
      this.errorMessage = this.translate.instant("Dialog.IPP.ChooseVersion");
      return true;
    }

    if (this.model.addressSpace.length < 3) {
      this.errorMessage = this.translate.instant("Dialog.IPP.AddressSpace");
      return true;
    }

    if (this.model.addressSpaceReason.length < 3) {
      this.errorMessage = this.translate.instant("Dialog.IPP.AddressSpaceReason");
      return true;
    }

    if (this.model.ippAmount.length === 0) {
      this.errorMessage = this.translate.instant("Dialog.IPP.Amount");
      return true;
    }

    if (this.model.addressSpaceReasonWhyNot.length < 3) {
      this.errorMessage = this.translate.instant("Dialog.IPP.AddressSpaceReasonWhyNot");
      return true;
    }

    if (this.model.ippGrowthExpected.length < 3) {
      this.errorMessage = this.translate.instant("Dialog.IPP.GrowthExpected");
      return true;
    }

    if (this.model.ippIPV6.length < 3) {
      this.errorMessage = this.translate.instant("Dialog.IPP.IPV6");
      return true;
    }

    // if file selected, upload and save file id in attachments list
    if (this.numberplanUpload) {
      await this.api
        .upload_fulfilment_attachment(customerId, this.numberplanUpload)
        .then((result: any) => {
          attachments.push(result.file_name);
        })
        .catch((err) => {
          if (err.error) {
            this.errorMessage = err.error.detail;
          }
        });
    }

    const request: FulfilmentRequest = {
      contacts: [
        {
          fullName: this.model.fullName,
          email: this.model.email,
          phone: this.model.phone,
        },
      ],
      serviceRequest: "create",
      text:
        this.translate.instant("Dialog.IPP.ChooseVersion") +
        "\nAnswer:\n\n" +
        this.model.ipv +
        "\n\n" +
        this.translate.instant("Dialog.IPP.AddressSpace") +
        "\nAnswer:\n\n" +
        this.model.addressSpace +
        "\n\n" +
        this.translate.instant("Dialog.IPP.AddressSpaceReason") +
        "\nAnswer:\n\n" +
        this.model.addressSpaceReason +
        "\n\n" +
        this.translate.instant("Dialog.IPP.Amount") +
        "\nAnswer:\n\n" +
        this.model.ippAmount +
        "\n\n" +
        this.translate.instant("Dialog.IPP.AddressSpaceReasonWhyNot") +
        "\nAnswer:\n\n" +
        this.model.addressSpaceReasonWhyNot +
        "\n\n" +
        this.translate.instant("Dialog.IPP.GrowthExpected") +
        "\nAnswer:\n\n" +
        this.model.ippGrowthExpected +
        "\n\n" +
        this.translate.instant("Dialog.IPP.IPV6") +
        "\nAnswer:\n\n" +
        this.model.ippIPV6,
      product: "IP_PREFIX",
    };

    if (attachments.length > 0) {
      request.attachments = [attachments.join()];
    }

    this.api
      .add_new_fulfilment_problem(customerId, JSON.stringify(request))
      .then((result) => {
        this.successMessage = this.translate.instant("Selfservice.NotificationSent");
        this.next();
      })
      .catch((err) => {
        if (err.error) {
          this.errorMessage = err.error.detail;
        }
      });
  }

  IPVChanged() {
    if (this.model.ipv === "4") {
      this.possibleAddresses = this.ipv4addresses;
      this.model.addressSpace = "/24 (256 adressen)";
    }
    if (this.model.ipv === "6") {
      this.possibleAddresses = this.ipv6addresses;
      this.model.addressSpace = "/48";
    }
  }
}

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FulfilmentRequest } from "../../../helpers/self-service/models/types";
import { AuthService } from "../../../services/AuthService";
import { TranslateService } from "@ngx-translate/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { ProductType } from "../../../helpers/enums/productType";

@Component({
  selector: "dialog-ip-prefixes-add",
  templateUrl: "dialog-ip-prefixes-add.html",
  styleUrls: ["../dialog-ip-prefixes-request/dialog-ip-prefixes-request.scss"],
  standalone: false,
})
export class DialogIPPrefixesAddComponent {
  public activeStep = 1;
  public __fulfilmentRequest: any;
  errorMessage = "";
  successMessage = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogIPPrefixesAddComponent>,
    private router: Router,
    private auth: AuthService,
    private translate: TranslateService,
    private api: ApiHelper,
  ) {
    this.__fulfilmentRequest = {};

    this.__fulfilmentRequest.fullName = this.auth.state.currentUser.displayName;
    this.__fulfilmentRequest.email = this.auth.state.currentUser.email;
    this.__fulfilmentRequest.phone = "";
    this.__fulfilmentRequest.ipRegister = "";
  }

  get disableSubmitButton() {
    return !this.__fulfilmentRequest.ipRegister;
  }

  next() {
    this.activeStep += 1;
  }

  prev() {
    this.activeStep -= 1;
  }

  submit() {
    const customerId = localStorage.getItem("viewingCustomerGUID");

    const request: FulfilmentRequest = {
      contacts: [
        {
          fullName: this.__fulfilmentRequest.fullName,
          email: this.__fulfilmentRequest.email,
          phone: this.__fulfilmentRequest.phone,
        },
      ],
      serviceRequest: "create",
      text: this.translate.instant("Dialog.IPP.IPRegister") + "\nAnswer:\n\n" + this.__fulfilmentRequest.ipRegister,
      product: "IP_PREFIX",
    };

    this.api
      .add_new_fulfilment_problem(customerId, JSON.stringify(request))
      .then((result) => {
        this.successMessage = this.translate.instant("Selfservice.NotificationSent");
        this.next();
      })
      .catch((err) => {
        if (err.error) {
          this.errorMessage = err.error.detail;
        }
      });
  }
}

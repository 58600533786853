import { Component, Input, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { Customer } from "../../models/customer";
import { AuthService } from "../../../services/AuthService";

@Component({
  selector: "dialog",
  templateUrl: "dialog.html",
  standalone: false,
})
export class ChooseOrganisationDialogComponent {
  public activeTab = 0;
  public activeTabDialog = 0;
  public organisations: Customer[] = [];

  constructor(
    private auth: AuthService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ChooseOrganisationDialogComponent>,
  ) {
    this.organisations = auth.state.organisations;
  }

  switchTab(tab: number) {
    this.activeTab = tab;
  }

  switchTabDialog(tabdialog: number) {
    this.activeTabDialog = tabdialog;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  switchCustomer(guid: string): void {
    this.auth.switchViewingCustomer(guid, "/");
  }
}

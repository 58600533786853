import { Pipe, PipeTransform } from "@angular/core";
import { RssNewsList } from "../schemas/rss-news-list";
import { parseGuid, RssNewsItem } from "../schemas/rss-news-item";

@Pipe({
  name: "selectNewsItem",
  standalone: false,
})
export class SelectNewsItemPipe implements PipeTransform {
  transform(items: RssNewsItem[], id: string): RssNewsItem {
    const identify = (i: RssNewsItem) => parseGuid(i.guid) == id;
    return items.find(identify);
  }
}

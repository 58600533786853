import { Component, EventEmitter, Output, Input, SimpleChanges, OnInit } from "@angular/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { ModifyBgpCommand } from "../../../helpers/self-service/modify_bgp";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ModifyErrorDetail, ModifyResponse } from "../../../helpers/self-service/models/types";

import { HttpErrorResponse } from "@angular/common/http";
import { transformError } from "../../../helpers/self-service/transform-error";
import { IpSubscriptionDetails } from "../../../pages/subscription-detail/types";

@Component({
  selector: "selfservice-bgp",
  templateUrl: "selfservice-bgp.html",
  styleUrls: ["selfservice-bgp.scss"],
  standalone: false,
})
export class SelfserviceBgpComponent implements OnInit {
  @Input() subscription: IpSubscriptionDetails;
  @Input() currentStep = 1;
  @Input() bus: EventEmitter<any>;
  @Output() dialogCloseEmitter = new EventEmitter<string>();

  public initialSaps;
  public processId: string;
  public hasErrors = false;
  public errors: ModifyErrorDetail[];
  public errorMessage = null;
  public buttonLoading = false;
  public subscriptionCopy;

  constructor(public api: ApiHelper) {}

  ngOnInit() {
    // @ts-ignore FIXME Remove using _saps
    const saps = this.subscription._saps || this.subscription.saps;
    this.subscriptionCopy = {
      ...this.subscription,
      _saps: saps.map((s) => Object.assign({}, s)), // FIXME Do not add data on top of object
    };
    this.initialSaps = this.subscriptionCopy._saps.map((s) => ({
      instance_id: s.subscriptionInstanceId,
      bgpSessionPriority: s.bgpSessionPriority,
      previousSessionPriority: s.bgpSessionPriority,
    }));
  }

  getInitialSessionPriority(i) {
    return this.initialSaps[i].previousSessionPriority;
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  prev() {
    this.currentStep--;
  }

  next() {
    // reset error messages
    this.errors = [];
    this.hasErrors = false;

    this.currentStep++;
  }

  submit() {
    this.buttonLoading = true;
    const modify = new ModifyBgpCommand();
    const command = new SelfServiceCommand(
      this.subscriptionCopy.subscriptionId,
      SelfServiceCommandKeys.ModifyBgpPriority,
    );
    this.subscriptionCopy._saps.map((sap) => {
      modify.setForInstance(sap.subscriptionInstanceId, sap.bgpSessionPriority);
    });
    command.payload = modify;
    this.api
      .selfServiceCommand(command)
      .then((response: ModifyResponse) => {
        this.bus.emit({
          processId: response.pid,
          action: SelfServiceCommandKeys.ModifyBgpPriority,
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.errors = transformError(err.error.detail);
        this.hasErrors = true;
        this.buttonLoading = false;
        console.log(err);
        // this.errorMessage = err.error.detail;
      });
  }
}

import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "cert-tabs",
  templateUrl: "cert-tabs.html",
  styleUrls: ["cert-tabs.scss"],
  standalone: false,
})
export class CertTabsComponent {
  @Input() activeTab: string;
  @Output() tabEmitter = new EventEmitter<string>();

  constructor() {}

  changeTab(tab) {
    this.tabEmitter.emit((this.activeTab = tab));
  }
}

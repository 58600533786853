import { Component, Input } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { DialogActivityComponent } from "../../dialogs/dialog-activity/dialog-activity";
import { Notification as GqlNotification } from "../../../gql/generated";
import { cimToPlannedWork } from "../../../helpers/subscription/planned-work";

@Component({
  selector: "notification-container",
  templateUrl: "notification-container.html",
  standalone: false,
})
export class NotificationContainer {
  @Input() messages: GqlNotification[];

  constructor(public dialog: MatDialog) {}

  openDialog(message: GqlNotification) {
    // TODO #206 Address technical debt Notifications/Messages/PlannedWorks
    const subscription_message = cimToPlannedWork(message);
    this.dialog.open(DialogActivityComponent, {
      data: subscription_message,
    });
  }
}

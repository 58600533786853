/**
 * Created by gert on 22/03/2018.
 */
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "sls_event_description",
  standalone: false,
})
export class SlsEventDescriptionPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/([SNTP]{4}-[0-9-]+)/, "<strong>$1</strong>");
  }
}

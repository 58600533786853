import { Pipe, PipeTransform } from "@angular/core";
import { RssNewsList } from "../schemas/rss-news-list";
import { RssNewsItem } from "../schemas/rss-news-item";

@Pipe({
  name: "extractNewsItemsSafely",
  standalone: false,
})
export class ExtractNewsItemsSafelyPipe implements PipeTransform {
  transform(feed: RssNewsList): RssNewsItem[] {
    return extractNewsItems(feed);
  }
}

export function extractNewsItems(feed: RssNewsList | undefined): RssNewsItem[] {
  const oneOrMore = feed?.rss?.channel?.item ?? [];
  return Array.isArray(oneOrMore) ? oneOrMore : [oneOrMore];
}

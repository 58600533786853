import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { RssNewsItem } from "../../schemas/rss-news-item";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "news-item",
  templateUrl: "./news-item.component.html",
  styleUrl: "./news-item.component.scss",
  standalone: false,
})
export class NewsItemComponent implements OnChanges, OnInit {
  @Input() item!: RssNewsItem;
  @Input() summary: boolean = false;
  @Input() setBrowserTitle: boolean = false;

  public firstImageUrl: string = "";

  constructor(
    private title: Title,
    public translate: TranslateService,
  ) {}

  Array = Array;

  ngOnInit() {
    this.getFirstImageUrl().then((s) => (this.firstImageUrl = s));
  }

  ngOnChanges() {
    if (this.setBrowserTitle) this.ngOnChangesAsync();
  }

  private async ngOnChangesAsync() {
    this.title.setTitle(this.item.title);
  }

  private async getFirstImageUrl(): Promise<string> {
    const re = /<img[^>]*src="([^"]+)"[^>]*>/;
    const findings = re.exec(this.item["content:encoded"]);
    return findings?.[0] ?? "";
  }
}

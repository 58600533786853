import { Component, SimpleChanges, OnChanges, Input } from "@angular/core";
import { Apollo } from "apollo-angular";
import * as Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import { CertChartOptions } from "./cert-chart-options";
import { SearchFilter } from "../../../gql/generated";
import { AIRT_SCAN_CLASSIFICATION_PROTOCOL_QUERY } from "../../../graphql/domain/airtscan";
import { chartStats } from "../../../graphql/fragments/airt";
import { Options } from "highcharts";
HC_more(Highcharts);

@Component({
  selector: "cert-chart",
  templateUrl: "cert-chart.html",
  styleUrls: ["cert-chart.scss"],
  standalone: false,
})
export class CertChartComponent implements OnChanges {
  @Input() searchFilter: SearchFilter;
  public chartRef: any;
  // public classifications = [
  //   { name: "High", percentage: 30 },
  //   { name: "Medium", percentage: 18 },
  //   { name: "Low", percentage: 36 },
  // ];

  highcharts: typeof Highcharts = Highcharts;
  // @ts-ignore  TODO Remove ignoring the faulty typing
  chartOptions: Options = CertChartOptions;
  public classifications: any[] = [];
  private loading = true;
  private deferLoading = false;
  private filterUpdated = false;
  private transportGrouped: any[] = [];
  private classifications_unsorted: any[] = [];

  constructor(private apollo: Apollo) {}

  get noDataFound(): boolean {
    if (!this.filterUpdated && !this.loading && this.transportGrouped.length === 0) {
      return true;
    }
    return false;
  }

  get haveChart(): boolean {
    return this.chartRef && "angular" in this.chartRef;
  }

  toggleLoading(state: boolean) {
    this.loading = state;
    if (!this.haveChart) {
      return;
    }

    if (state) {
      this.chartRef.showLoading(" ");
    } else {
      this.chartRef.hideLoading(" ");
    }
  }

  setChartInstance(chart: Highcharts.Chart) {
    this.chartRef = chart;
    this.toggleLoading(true);
    if (this.deferLoading) {
      console.log("Deferred loading start");
      this.deferLoading = false;
      this.fetchBubbleChartData();
    }

    // this.chartRef.hideNoData();
  }

  async fetchBubbleChartData() {
    this.toggleLoading(true);
    this.apollo
      .query<any>({
        query: AIRT_SCAN_CLASSIFICATION_PROTOCOL_QUERY,
        // fetchPolicy: "network-only",
        variables: {
          filter: this.searchFilter,
        },
      })
      .subscribe(({ data }) => {
        const transformedStats = chartStats(data.airtScans.edges || []);
        this.transportGrouped = transformedStats.transports;
        this.classifications_unsorted = transformedStats.classifications;
        this.classifications = this.classifications_unsorted.sort((x, y) =>
          x.order > y.order ? 1
          : x.order < y.order ? -1
          : 0,
        );
        this.setSeries();
        this.toggleLoading(false);
        this.filterUpdated = false;
      });
  }

  clearSeries() {
    if (!this.chartRef || !this.chartRef.series) {
      return;
    }

    while (this.chartRef.series.length > 0) {
      this.chartRef.series[0].remove(false);
    }
    this.chartRef.redraw(); // update the visual aspect
  }

  setSeries() {
    this.clearSeries();

    this.transportGrouped.forEach((data, index) => {
      if (!this.chartRef.series || this.chartRef.series[index] === undefined) {
        this.chartRef.addSeries(
          {
            name: "",
            data: [data],
          },
          false,
          false,
        );
      } else {
        this.chartRef.series[index].update({ name: "" }, false);
        this.chartRef.series[index].setData([data], false, false, false);
      }
    });
    this.chartRef.redraw(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchFilter && "beforeSourceDate" in this.searchFilter) {
      this.filterUpdated = true;
      if (!this.haveChart) {
        console.log("Defer loading of chartData until chartRef appears");
        this.deferLoading = true;
        return;
      }

      this.fetchBubbleChartData();
    }
  }
}

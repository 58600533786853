/**
 * Created by gert on 22/03/2018.
 */
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "status_to_icon",
  pure: true,
  standalone: false,
})
export class StatusToIconPipe implements PipeTransform {
  transform(value: string): string {
    let ret = "";
    switch (value) {
      case "active":
      case "ok":
        ret = "ok";
        break;
      case "notfound":
      case "unknown":
        ret = "unknown";
        break;
      case "notraffic":
        ret = "no-traffic";
        break;
      case "malfunction":
        ret = "warning";
        break;
      case "initial":
      case "pending":
        ret = "pending";
        break;
      case "active-corero-filters":
        ret = "ok";
        break;
      case "inactive-corero-filters":
        ret = "no-traffic";
        break;
      case "open-incidents":
        ret = "warning";
        break;
      default:
        ret = "unknown";
    }
    return ret;
  }
}

import { Component, Input } from "@angular/core";
import { WirelessHealthDetail } from "../../../gql/generated";

@Component({
  selector: "user-stats",
  templateUrl: "user-stats.html",
  styleUrls: ["user-stats.scss"],
  standalone: false,
})
export class UserStatsComponent {
  @Input() wirelessHealthData: WirelessHealthDetail;

  constructor() {}
}

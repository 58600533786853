import { Component } from "@angular/core";
import { NavigationComponent } from "../../../../components/common/navigation/navigation";
import { ENV } from "../../../app.runtime";

@Component({
  selector: "navigation-news",
  templateUrl: "./navigation-news.component.html",
  styleUrls: ["../../../../components/common/navigation/navigation.scss"],
  standalone: false,
})
export class NavigationNews extends NavigationComponent {
  newsUrl = ENV.NWD_NEWS_URL;
  dashboardUrl = ENV.NWD_DASHBOARD_URL;
}

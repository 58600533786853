/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date with time (isoformat) */
  DateTime: any;
  IPv4Address: any;
  IPv4Network: any;
  IPv6Address: any;
  IPv6Network: any;
  UUID: any;
};

/** Information about wireless access point */
export type AccessPointType = {
  __typename?: "AccessPointType";
  brand?: Maybe<Scalars["String"]>;
  ciStatus?: Maybe<Scalars["String"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  supplier?: Maybe<Scalars["String"]>;
};

/** Information about address */
export type AddressType = {
  __typename?: "AddressType";
  addition?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  extraAddressLine?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

/** Information about aggregate parent of a service port */
export type AggregateParentType = {
  __typename?: "AggregateParentType";
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  description: Scalars["String"];
  product: ProductType;
  subscriptionId: Scalars["UUID"];
};

/** Information about aggsp */
export type AggspSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "AggspSubscriptionDetailsType";
  address?: Maybe<AddressType>;
  amountReserved: Scalars["Int"];
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  linkMemberSubscriptions: Array<LinkMemberDetailsType>;
  locationDescription?: Maybe<Scalars["String"]>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  portId?: Maybe<Scalars["Int"]>;
  portMode?: Maybe<Scalars["String"]>;
  portSpeed?: Maybe<Scalars["Int"]>;
  portSubscription?: Maybe<Scalars["UUID"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  services?: Maybe<Array<ServiceType>>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  surfLocationCode?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
};

export type BaseEnrichedTagsSubscription = {
  enrichedTags: Array<Scalars["String"]>;
  subscriptionId: Scalars["UUID"];
};

export type BaseHealthSubscription = {
  enrichedTags: Array<Scalars["String"]>;
  healthStatus?: Maybe<HealthStatus>;
  subscriptionId: Scalars["UUID"];
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type BaseSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Base sort ordering of results */
export type BaseSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about a circuit */
export type CircuitType = {
  __typename?: "CircuitType";
  endpoints: Array<ServiceAttachPointType>;
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed?: Maybe<Scalars["Int"]>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
};

export type Counts = {
  __typename?: "Counts";
  /** Firewall subscriptions count */
  fw: SubscriptionCount;
  /** IP subscriptions count */
  ip: SubscriptionCount;
  /** IP Prefix subscriptions count */
  ipPrefix: SubscriptionCount;
  /** L2VPN subscriptions count */
  l2vpn: SubscriptionCountWithFirewallsEnabled;
  /** L3VPN subscriptions count */
  l3vpn: SubscriptionCountWithFirewallsEnabled;
  /** LightPath subscriptions count */
  lp: SubscriptionCount;
  /** Port subscriptions count */
  port: SubscriptionCount;
  /** IP Prefix subscriptions count */
  wireless: SubscriptionCount;
};

export type CountsFwArgs = {
  customerId: Scalars["UUID"];
};

export type CountsIpArgs = {
  customerId: Scalars["UUID"];
};

export type CountsIpPrefixArgs = {
  customerId: Scalars["UUID"];
};

export type CountsL2vpnArgs = {
  customerId: Scalars["UUID"];
};

export type CountsL3vpnArgs = {
  customerId: Scalars["UUID"];
};

export type CountsLpArgs = {
  customerId: Scalars["UUID"];
};

export type CountsPortArgs = {
  customerId: Scalars["UUID"];
};

export type CountsWirelessArgs = {
  customerId: Scalars["UUID"];
};

/** list of subscription description by customer */
export type CustomerDescriptionType = {
  __typename?: "CustomerDescriptionType";
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  subscriptionId?: Maybe<Scalars["UUID"]>;
};

/** Information about customer */
export type CustomerType = {
  __typename?: "CustomerType";
  customerId: Scalars["String"];
  fullname: Scalars["String"];
};

/** Information about ESI */
export type EthernetSegmentIdentifierType = {
  __typename?: "EthernetSegmentIdentifierType";
  endpoints?: Maybe<Array<ServiceAttachPointType>>;
  firewallInterconnect?: Maybe<L2InterconnectType>;
  segmentId: Scalars["Int"];
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type EvpnPtpSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type EvpnPtpSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about firewall */
export type FirewallSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "FirewallSubscriptionDetailsType";
  availabilityZone?: Maybe<Scalars["String"]>;
  customer?: Maybe<CustomerType>;
  customerAsn?: Maybe<Scalars["Int"]>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  firewallEnabled: Scalars["Boolean"];
  fwAsn: Scalars["Float"];
  insync: Scalars["Boolean"];
  ipGateway?: Maybe<IpGatewayType>;
  l2Endpoints: Array<L2EndpointType>;
  l3Endpoints: Array<L3EndpointType>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  serviceSpeed: Scalars["Int"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  topology: Array<Array<TopologyObjectType>>;
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type FirewallSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type FirewallSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about SURFfirewall */
export type FirewallSubscriptionType = SubscriptionDetails & {
  __typename?: "FirewallSubscriptionType";
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
};

export type FirewallSubscriptionTypeConnectionV2 = {
  __typename?: "FirewallSubscriptionTypeConnectionV2";
  page: Array<FirewallSubscriptionType>;
  pageInfo: PageInfo;
};

export type FwSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "FwSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type HealthStatus = {
  __typename?: "HealthStatus";
  status?: Maybe<Scalars["String"]>;
  traffic?: Maybe<Traffic>;
};

/** Information about ip service attach point */
export type IpServiceAttachPointType = {
  __typename?: "IPServiceAttachPointType";
  address?: Maybe<AddressType>;
  bfd?: Maybe<Scalars["Boolean"]>;
  bfdMinimumInterval?: Maybe<Scalars["Int"]>;
  bfdMultiplier?: Maybe<Scalars["Int"]>;
  bgpExportPolicy?: Maybe<Scalars["String"]>;
  bgpHashAlgorithm?: Maybe<Scalars["String"]>;
  bgpPassword?: Maybe<Scalars["String"]>;
  bgpSessionPriority?: Maybe<Scalars["String"]>;
  ipPrefixes: Array<Scalars["String"]>;
  ipv4Mtu?: Maybe<Scalars["Int"]>;
  ipv4PointToPointPrefix?: Maybe<IpPointToPointPrefixType>;
  ipv6Mtu?: Maybe<Scalars["Int"]>;
  ipv6PointToPointPrefix?: Maybe<IpPointToPointPrefixType>;
  name: Scalars["String"];
  organisation?: Maybe<Scalars["String"]>;
  port?: Maybe<ServicePortSubscriptionType>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
  vlanrange: Scalars["String"];
};

export type IpSubscriptionListItemType = {
  __typename?: "IPSubscriptionListItemType";
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  product: ProductType;
  subscriptionId: Scalars["UUID"];
};

/** Information about ip gateway */
export type IpGatewayType = {
  __typename?: "IpGatewayType";
  ipPrefixes: Array<Scalars["String"]>;
  ipv4Mtu?: Maybe<Scalars["Int"]>;
  ipv6Mtu?: Maybe<Scalars["Int"]>;
  multiCast: Scalars["Boolean"];
  serviceSpeed: Scalars["Int"];
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  surfcertFilter: Scalars["String"];
  upLinkLocations: Array<UplinkLocationType>;
};

/** Information about ip ptp prefix */
export type IpPointToPointPrefixType = {
  __typename?: "IpPointToPointPrefixType";
  clientFQDN: Scalars["String"];
  clientIpAddress: Scalars["String"];
  network: Scalars["String"];
  surfFQDN: Scalars["String"];
  surfIpAddress: Scalars["String"];
};

/** Information about ip_prefix ddos */
export type IpPrefixDdosType = {
  __typename?: "IpPrefixDdosType";
  /** Get auto mitigation for this prefix */
  autoMitigation: Scalars["Boolean"];
  customerId: Scalars["String"];
  description: Scalars["String"];
  insync: Scalars["Boolean"];
  prefix: Scalars["String"];
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  version: Scalars["Int"];
};

export type IpPrefixDdosTypeConnectionV2 = {
  __typename?: "IpPrefixDdosTypeConnectionV2";
  page: Array<IpPrefixDdosType>;
  pageInfo: PageInfo;
};

export type IpPrefixSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "IpPrefixSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

/** Filter list by attribute, all ranges are inclusive */
export type IpPrefixSubscriptionDdosFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type IpPrefixSubscriptionDdosSort = {
  customerId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  ipPrefix?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type IpPrefixSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type IpPrefixSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about ipprefix */
export type IpPrefixSubscriptionType = SubscriptionDetails & {
  __typename?: "IpPrefixSubscriptionType";
  autoMitigation: Scalars["String"];
  availableIpSubscriptions: Array<IpSubscriptionListItemType>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  ipPrefix?: Maybe<IpPrefixType>;
  ipSubscriptions: Array<IpSubscriptionListItemType>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
};

export type IpPrefixSubscriptionTypeConnectionV2 = {
  __typename?: "IpPrefixSubscriptionTypeConnectionV2";
  page: Array<IpPrefixSubscriptionType>;
  pageInfo: PageInfo;
};

/** Information about ipprefix */
export type IpPrefixType = {
  __typename?: "IpPrefixType";
  afi: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  ipamPrefixId: Scalars["Int"];
  ipamPrefixTags?: Maybe<Array<Scalars["String"]>>;
  name?: Maybe<Scalars["String"]>;
  parent?: Maybe<Scalars["Int"]>;
  parentLabel?: Maybe<Scalars["String"]>;
  prefix: Scalars["String"];
  state: Scalars["Int"];
  stateLabel?: Maybe<Scalars["String"]>;
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  tag?: Maybe<Scalars["String"]>;
  vrf: Scalars["Int"];
  vrfLabel?: Maybe<Scalars["String"]>;
};

/** Information about ip service settings */
export type IpServiceSettingsType = {
  __typename?: "IpServiceSettingsType";
  asn?: Maybe<Scalars["Int"]>;
  multicast?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  subscriptionInstanceId: Scalars["UUID"];
  surfcertFilter: Scalars["String"];
  tag: Scalars["String"];
};

/** Information about ip */
export type IpSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "IpSubscriptionDetailsType";
  availabilityZone?: Maybe<Scalars["String"]>;
  circuits: Array<CircuitType>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  firewallEnabled: Scalars["Boolean"];
  insync: Scalars["Boolean"];
  ipRoutingType: Scalars["String"];
  ipss: IpServiceSettingsType;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection?: Maybe<Scalars["String"]>;
  references: Array<ReferenceType>;
  saps: Array<IpServiceAttachPointType>;
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed?: Maybe<Scalars["Int"]>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  topology: Array<Array<TopologyObjectType>>;
};

/** Information about IP */
export type IpSubscriptionListType = SubscriptionDetails & {
  __typename?: "IpSubscriptionListType";
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
};

export type IpSubscriptionListTypeConnectionV2 = {
  __typename?: "IpSubscriptionListTypeConnectionV2";
  page: Array<IpSubscriptionListType>;
  pageInfo: PageInfo;
};

/** Information about irb */
export type IrbSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "IrbSubscriptionDetailsType";
  address?: Maybe<AddressType>;
  amountReserved: Scalars["Int"];
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  locationDescription?: Maybe<Scalars["String"]>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  portId?: Maybe<Scalars["Int"]>;
  portMode?: Maybe<Scalars["String"]>;
  portSpeed?: Maybe<Scalars["Int"]>;
  portSubscription?: Maybe<Scalars["UUID"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  services: Array<ServiceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  surfLocationCode?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
};

/** Information about L2 endpoint */
export type L2EndpointType = {
  __typename?: "L2EndpointType";
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  ipv4PtpPrefixes: Array<Scalars["String"]>;
  ipv6PtpPrefixes: Array<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  otherSubscriptionId?: Maybe<Scalars["UUID"]>;
  product: ProductType;
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  tags: Array<Scalars["String"]>;
};

/** Information about L2 Interconnect */
export type L2InterconnectType = {
  __typename?: "L2InterconnectType";
  availabilityZone?: Maybe<Scalars["String"]>;
  endpoints?: Maybe<Array<ServiceAttachPointType>>;
  firewallSubscriptionId?: Maybe<Scalars["UUID"]>;
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
};

/** Information about L2VPN circuit */
export type L2VpnCircuitType = {
  __typename?: "L2VPNCircuitType";
  bumFilter?: Maybe<Scalars["Boolean"]>;
  endpoints: Array<ServiceAttachPointType>;
  esis: Array<EthernetSegmentIdentifierType>;
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed?: Maybe<Scalars["Int"]>;
  speedPolicer?: Maybe<Scalars["Boolean"]>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
  vlanRetagging?: Maybe<Scalars["Boolean"]>;
};

/** Information about L2VPN */
export type L2VpnSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "L2VPNSubscriptionDetailsType";
  circuits: Array<L2VpnCircuitType>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  esis: Array<EthernetSegmentIdentifierType>;
  firewallEnabled: Scalars["Boolean"];
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  saps: Array<ServiceAttachPointType>;
  serviceId: Scalars["String"];
  serviceSpeed: Scalars["Int"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  topology: Array<Array<TopologyObjectType>>;
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type L2VpnSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type L2VpnSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about l2vpn */
export type L2VpnSubscriptionType = {
  __typename?: "L2VpnSubscriptionType";
  customer: CustomerType;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  description: Scalars["String"];
  domain: Scalars["String"];
  locations: Array<LocationType>;
  product: ProductType;
  services: Array<PortServiceType>;
  status: Scalars["String"];
  subscriptionId: Scalars["String"];
  tags: Array<Scalars["String"]>;
};

export type L2VpnSubscriptionTypeConnectionV2 = {
  __typename?: "L2VpnSubscriptionTypeConnectionV2";
  page: Array<L2VpnSubscriptionType>;
  pageInfo: PageInfo;
};

/** Information about L3 endpoint */
export type L3EndpointType = {
  __typename?: "L3EndpointType";
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  organisation?: Maybe<Scalars["String"]>;
  otherSubscriptionId?: Maybe<Scalars["UUID"]>;
  product: ProductType;
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  tags: Array<Scalars["String"]>;
};

/** Information about L3VPN firewall interconnect */
export type L3FwInterconnectType = {
  __typename?: "L3FwInterconnectType";
  availabilityZone: Scalars["String"];
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  firewallAsn: Scalars["String"];
  firewallSubscriptionId: Scalars["UUID"];
  ipv4AddressFirewall?: Maybe<Scalars["IPv4Address"]>;
  ipv4MaxPrefix?: Maybe<Scalars["Int"]>;
  ipv4Mtu?: Maybe<Scalars["Int"]>;
  ipv4Prefix?: Maybe<Scalars["IPv4Network"]>;
  ipv6AddressFirewall?: Maybe<Scalars["IPv6Address"]>;
  ipv6MaxPrefix?: Maybe<Scalars["Int"]>;
  ipv6Mtu?: Maybe<Scalars["Int"]>;
  ipv6Prefix?: Maybe<Scalars["IPv6Network"]>;
  organisation?: Maybe<Scalars["String"]>;
  saps: Array<ServiceAttachPointType>;
  subscriptionInstanceId: Scalars["UUID"];
};

/** Information about L3VPN circuit */
export type L3VpnCircuitType = {
  __typename?: "L3VPNCircuitType";
  endpoints: Array<ServiceAttachPointType>;
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed?: Maybe<Scalars["Int"]>;
  speedPolicer?: Maybe<Scalars["Boolean"]>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
};

/** Information about L3VPN service settings */
export type L3VpnsapServiceSettingsType = {
  __typename?: "L3VPNSAPServiceSettingsType";
  asn: Scalars["Int"];
  bfd?: Maybe<Scalars["Boolean"]>;
  bfdMinimumInterval?: Maybe<Scalars["Int"]>;
  bfdMultiplier?: Maybe<Scalars["Int"]>;
  bgpMetric?: Maybe<Scalars["String"]>;
  bgpPassword?: Maybe<Scalars["String"]>;
  ipv4Address?: Maybe<Scalars["String"]>;
  ipv4MaxPrefix?: Maybe<Scalars["Int"]>;
  ipv4Mtu?: Maybe<Scalars["Int"]>;
  ipv4RemoteAddress?: Maybe<Scalars["String"]>;
  ipv6Address?: Maybe<Scalars["String"]>;
  ipv6MaxPrefix?: Maybe<Scalars["Int"]>;
  ipv6Mtu?: Maybe<Scalars["Int"]>;
  ipv6RemoteAddress?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  sap: ServiceAttachPointType;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
};

/** Information about L3VPN service settings */
export type L3VpnServiceSettingsType = {
  __typename?: "L3VPNServiceSettingsType";
  l3vpnAsn: Scalars["Int"];
  name: Scalars["String"];
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
};

/** Information about L3VPN */
export type L3VpnSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "L3VPNSubscriptionDetailsType";
  circuits: Array<L3VpnCircuitType>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  firewallEnabled: Scalars["Boolean"];
  firewallInterconnects: Array<L3FwInterconnectType>;
  insync: Scalars["Boolean"];
  l3vpnsapss: Array<L3VpnsapServiceSettingsType>;
  l3vpnss: L3VpnServiceSettingsType;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  serviceSpeed: Scalars["Int"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  topology: Array<Array<TopologyObjectType>>;
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type L3VpnSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type L3VpnSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about l3vpn */
export type L3VpnSubscriptionType = {
  __typename?: "L3VpnSubscriptionType";
  customer: CustomerType;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  description: Scalars["String"];
  domain: Scalars["String"];
  locations: Array<LocationType>;
  product: ProductType;
  services: Array<PortServiceType>;
  status: Scalars["String"];
  subscriptionId: Scalars["String"];
  tags: Array<Scalars["String"]>;
};

export type L3VpnSubscriptionTypeConnectionV2 = {
  __typename?: "L3VpnSubscriptionTypeConnectionV2";
  page: Array<L3VpnSubscriptionType>;
  pageInfo: PageInfo;
};

/** Information about light path circuit */
export type LightPathCircuitType = {
  __typename?: "LightPathCircuitType";
  endpoints: Array<ServiceAttachPointType>;
  name: Scalars["String"];
  remotePortShutdown?: Maybe<Scalars["Boolean"]>;
  serviceId?: Maybe<Scalars["String"]>;
  serviceSpeed?: Maybe<Scalars["Int"]>;
  speedPolicer?: Maybe<Scalars["Boolean"]>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
};

/** Information about light path */
export type LightPathSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "LightPathSubscriptionDetailsType";
  circuits: Array<LightPathCircuitType>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  protection: Scalars["String"];
  references: Array<ReferenceType>;
  saps: Array<ServiceAttachPointType>;
  serviceId: Scalars["String"];
  serviceSpeed: Scalars["Int"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  topology: Array<Array<TopologyObjectType>>;
};

/** Information about lightpath */
export type LightPathSubscriptionType = SubscriptionDetails & {
  __typename?: "LightPathSubscriptionType";
  customer: CustomerType;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  services: Array<PortServiceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
};

export type LightPathSubscriptionTypeConnectionV2 = {
  __typename?: "LightPathSubscriptionTypeConnectionV2";
  page: Array<LightPathSubscriptionType>;
  pageInfo: PageInfo;
};

export type LinkMemberDetailsType = {
  __typename?: "LinkMemberDetailsType";
  amountReserved: Scalars["Int"];
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  ieeeInterfaceType?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  patchPosition?: Maybe<Scalars["String"]>;
  portSpeed: Scalars["Int"];
  product: ProductType;
  services: Array<ServiceType>;
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
  vlanrange?: Maybe<Scalars["String"]>;
};

/** Information about a location (e.g. address, description, code) */
export type LocationType = {
  __typename?: "LocationType";
  address?: Maybe<AddressType>;
  locationDescription?: Maybe<Scalars["String"]>;
  surfLocationCode?: Maybe<Scalars["String"]>;
};

/** list of subscription minimal impact notification by customer */
export type MinimalImpactNotificationType = {
  __typename?: "MinimalImpactNotificationType";
  customerId: Scalars["UUID"];
  impact: Scalars["String"];
};

export type MscPortSubscriptionType = {
  __typename?: "MscPortSubscriptionType";
  customerDescription?: Maybe<Scalars["String"]>;
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  subscriptionId: Scalars["UUID"];
};

/** Information about msc */
export type MscSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "MscSubscriptionDetailsType";
  address?: Maybe<AddressType>;
  amountReserved: Scalars["Int"];
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  locationDescription?: Maybe<Scalars["String"]>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  portId?: Maybe<Scalars["Int"]>;
  portMode?: Maybe<Scalars["String"]>;
  portSpeed?: Maybe<Scalars["Int"]>;
  portSubscription?: Maybe<MscPortSubscriptionType>;
  product: ProductType;
  references: Array<ReferenceType>;
  serviceTag?: Maybe<Scalars["Int"]>;
  services: Array<ServiceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  subscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  surfLocationCode?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
};

/** Pagination context to navigate objects with cursor-based pagination */
export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["Int"]>;
  filterFields: Array<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  sortFields: Array<Scalars["String"]>;
  startCursor?: Maybe<Scalars["Int"]>;
  totalItems?: Maybe<Scalars["Int"]>;
};

/** Information about a service on a port */
export type PortServiceType = {
  __typename?: "PortServiceType";
  description: Scalars["String"];
  percentage: Scalars["Float"];
  productType: Scalars["String"];
  speed: Scalars["Int"];
  subscriptionId: Scalars["String"];
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type PortSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of port results */
export type PortSubscriptionSort = {
  amountReserved?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  portMode?: InputMaybe<SortOrder>;
  portSpeed?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

export type PrivateQuery = {
  __typename?: "PrivateQuery";
  /** Return aggsp details */
  aggspDetails: AggspSubscriptionDetailsType;
  /** Grouped subscription counts */
  counts: Counts;
  /** Returns a list of evpn ptp subscriptions */
  evpnPtpList: LightPathSubscriptionTypeConnectionV2;
  /** Return firewall details */
  firewallDetails: FirewallSubscriptionDetailsType;
  /** Return a list of firewall subscriptions */
  firewallList: FirewallSubscriptionTypeConnectionV2;
  /** Return ip bgp details */
  ipBgpDetails: IpSubscriptionDetailsType;
  /** Return IP list */
  ipList: IpSubscriptionListTypeConnectionV2;
  /** Return ip prefix ddos list */
  ipPrefixDdos: IpPrefixDdosTypeConnectionV2;
  /** Return ip prefix list */
  ipPrefixList: IpPrefixSubscriptionTypeConnectionV2;
  /** Return ip static details */
  ipStaticDetails: IpSubscriptionDetailsType;
  /** Return irb details */
  irbDetails: IrbSubscriptionDetailsType;
  /** Return L2VPN details */
  l2vpnDetails: L2VpnSubscriptionDetailsType;
  /** Returns a list of l2vpn subscriptions */
  l2vpnList: L2VpnSubscriptionTypeConnectionV2;
  /** Return L2VPN details */
  l3vpnDetails: L3VpnSubscriptionDetailsType;
  /** Returns a list of l3vpn subscriptions */
  l3vpnList: L3VpnSubscriptionTypeConnectionV2;
  /** Return lightpath details */
  lpDetails: LightPathSubscriptionDetailsType;
  /** Return lightpath redundant details */
  lrDetails: LightPathSubscriptionDetailsType;
  /** Return msc details */
  mscDetails: MscSubscriptionDetailsType;
  /** Returns a list of port subscriptions */
  portList: ServicePortSubscriptionTypeConnectionV2;
  /** Return service port details */
  servicePortDetails: ServicePortSubscriptionDetailsType;
  /** Return wireless details */
  wirelessDetails: WirelessSubscriptionDetailsType;
  /** Return a list of wireless subscriptions */
  wirelessList: WirelessSubscriptionTypeConnectionV2;
};

export type PrivateQueryAggspDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryEvpnPtpListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<EvpnPtpSubscriptionFilter>;
  sort?: InputMaybe<EvpnPtpSubscriptionSort>;
};

export type PrivateQueryFirewallDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryFirewallListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<FirewallSubscriptionFilter>;
  sort?: InputMaybe<FirewallSubscriptionSort>;
};

export type PrivateQueryIpBgpDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryIpListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<BaseSubscriptionFilter>;
  sort?: InputMaybe<BaseSubscriptionSort>;
};

export type PrivateQueryIpPrefixDdosArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<IpPrefixSubscriptionDdosFilter>;
  sort?: InputMaybe<IpPrefixSubscriptionDdosSort>;
};

export type PrivateQueryIpPrefixListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<IpPrefixSubscriptionFilter>;
  sort?: InputMaybe<IpPrefixSubscriptionSort>;
};

export type PrivateQueryIpStaticDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryIrbDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryL2vpnDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryL2vpnListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<L2VpnSubscriptionFilter>;
  sort?: InputMaybe<L2VpnSubscriptionSort>;
};

export type PrivateQueryL3vpnDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryL3vpnListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<L3VpnSubscriptionFilter>;
  sort?: InputMaybe<L3VpnSubscriptionSort>;
};

export type PrivateQueryLpDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryLrDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryMscDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryPortListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<PortSubscriptionFilter>;
  sort?: InputMaybe<PortSubscriptionSort>;
};

export type PrivateQueryServicePortDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryWirelessDetailsArgs = {
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type PrivateQueryWirelessListArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<WirelessSubscriptionFilter>;
  sort?: InputMaybe<WirelessSubscriptionSort>;
};

/** Information about product */
export type ProductType = {
  __typename?: "ProductType";
  id: Scalars["UUID"];
  name: Scalars["String"];
  tag: Scalars["String"];
  type: Scalars["String"];
};

/** Metadata to reference customer specific ID's */
export type ReferenceType = {
  __typename?: "ReferenceType";
  externalId: Scalars["String"];
  name: Scalars["String"];
};

/** Information about service attach point */
export type ServiceAttachPointType = {
  __typename?: "ServiceAttachPointType";
  address?: Maybe<AddressType>;
  name: Scalars["String"];
  organisation?: Maybe<Scalars["String"]>;
  port?: Maybe<ServicePortSubscriptionType>;
  subscriptionInstanceId: Scalars["UUID"];
  tag: Scalars["String"];
  vlanrange: Scalars["String"];
};

/** Information about port */
export type ServicePortSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "ServicePortSubscriptionDetailsType";
  address?: Maybe<AddressType>;
  aggregateParent?: Maybe<AggregateParentType>;
  amountReserved: Scalars["Int"];
  autoNegotiation?: Maybe<Scalars["Boolean"]>;
  connectorType?: Maybe<Scalars["String"]>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  domain: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  fiberType?: Maybe<Scalars["String"]>;
  ieeeInterfaceType?: Maybe<Scalars["String"]>;
  insync: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  locationDescription?: Maybe<Scalars["String"]>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  patchPosition?: Maybe<Scalars["String"]>;
  portMode?: Maybe<Scalars["String"]>;
  portSpeed?: Maybe<Scalars["Int"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  services?: Maybe<Array<ServiceType>>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  surfLocationCode?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
};

/** Information about port */
export type ServicePortSubscriptionType = {
  __typename?: "ServicePortSubscriptionType";
  address?: Maybe<AddressType>;
  amountReserved?: Maybe<Scalars["Int"]>;
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  description: Scalars["String"];
  domain: Scalars["String"];
  locationDescription?: Maybe<Scalars["String"]>;
  locations: Array<LocationType>;
  portMode: Scalars["String"];
  portSpeed: Scalars["Int"];
  product: ProductType;
  services: Array<ServiceType>;
  speed: Scalars["String"];
  status: Scalars["String"];
  subscriptionId: Scalars["String"];
  tags: Array<Scalars["String"]>;
  usedVlans: Array<Scalars["String"]>;
};

export type ServicePortSubscriptionTypeConnectionV2 = {
  __typename?: "ServicePortSubscriptionTypeConnectionV2";
  page: Array<ServicePortSubscriptionType>;
  pageInfo: PageInfo;
};

/** information about service port services */
export type ServiceType = {
  __typename?: "ServiceType";
  amountReserved?: Maybe<Scalars["Int"]>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  insync: Scalars["Boolean"];
  organisation?: Maybe<Scalars["String"]>;
  percentage: Scalars["Float"];
  portSpeed?: Maybe<Scalars["Int"]>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product?: Maybe<ProductType>;
  services?: Maybe<Array<ServiceType>>;
  speed: Scalars["Int"];
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  vlanrange?: Maybe<Scalars["String"]>;
};

export type Sn8AggregatedServicePortSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "Sn8AggregatedServicePortSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8IpBgpSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "Sn8IpBgpSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8IpStaticSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "Sn8IpStaticSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8IrbServicePortSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "Sn8IrbServicePortSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8L2VpnSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "Sn8L2VpnSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8L3VpnSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "Sn8L3VpnSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8LightPathRedundantSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "Sn8LightPathRedundantSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8LightPathSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "Sn8LightPathSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8MscSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "Sn8MscSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

export type Sn8ServicePortSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription & {
    __typename?: "Sn8ServicePortSubscription";
    enrichedTags: Array<Scalars["String"]>;
    healthStatus?: Maybe<HealthStatus>;
    subscriptionId: Scalars["UUID"];
  };

/** Sort direction: ascending or descending */
export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type SubscriptionCount = {
  __typename?: "SubscriptionCount";
  all: Scalars["Int"];
  inactive: Scalars["Int"];
  malfunction: Scalars["Int"];
  notfound: Scalars["Int"];
  notraffic: Scalars["Int"];
  ok: Scalars["Int"];
  pending: Scalars["Int"];
  unknown: Scalars["Int"];
};

export type SubscriptionCountWithFirewallsEnabled = {
  __typename?: "SubscriptionCountWithFirewallsEnabled";
  all: Scalars["Int"];
  firewallsEnabled: Scalars["Int"];
  inactive: Scalars["Int"];
  malfunction: Scalars["Int"];
  notfound: Scalars["Int"];
  notraffic: Scalars["Int"];
  ok: Scalars["Int"];
  pending: Scalars["Int"];
  unknown: Scalars["Int"];
};

export type SubscriptionDetails = {
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tags: Array<Scalars["String"]>;
};

export enum SubscriptionLifecycle {
  Active = "ACTIVE",
  Disabled = "DISABLED",
  Initial = "INITIAL",
  Migrating = "MIGRATING",
  Provisioning = "PROVISIONING",
  Terminated = "TERMINATED",
}

export type SurfWirelessSubscription = BaseEnrichedTagsSubscription & {
  __typename?: "SurfWirelessSubscription";
  enrichedTags: Array<Scalars["String"]>;
  healthStatus?: Maybe<WirelessHealthStatus>;
  healthStatusDetail?: Maybe<WirelessHealthDetail>;
  subscriptionId: Scalars["UUID"];
};

export type SurfWirelessSubscriptionHealthStatusArgs = {
  countNumAccessPoints?: Scalars["Boolean"];
};

/** Information about topology object */
export type TopologyObjectType = {
  __typename?: "TopologyObjectType";
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  description?: Maybe<Scalars["String"]>;
  otherSubscriptionId?: Maybe<Scalars["UUID"]>;
  product?: Maybe<ProductType>;
  subscriptionInstanceId: Scalars["UUID"];
  topologyObjectType: Scalars["String"];
  vlanrange?: Maybe<Scalars["String"]>;
};

/** The traffic metrics for this subscription */
export type Traffic = {
  __typename?: "Traffic";
  last?: Maybe<TrafficMetrics>;
  max?: Maybe<TrafficMetrics>;
  mean?: Maybe<TrafficMetrics>;
};

export type TrafficMetrics = {
  __typename?: "TrafficMetrics";
  in: Scalars["Float"];
  out: Scalars["Float"];
};

/** Information about uplink location */
export type UplinkLocationType = {
  __typename?: "UplinkLocationType";
  address?: Maybe<AddressType>;
  locationDescription?: Maybe<Scalars["String"]>;
};

export type WirelessHealthDetail = {
  __typename?: "WirelessHealthDetail";
  last3Days?: Maybe<WirelessHealthDetailModel>;
  lastWeek?: Maybe<WirelessHealthDetailModel>;
  today?: Maybe<WirelessHealthDetailModel>;
};

export type WirelessHealthDetailModel = {
  __typename?: "WirelessHealthDetailModel";
  five?: Maybe<WirelessKpi>;
  maxActiveUsers?: Maybe<Scalars["Int"]>;
  twofour?: Maybe<WirelessKpi>;
};

export type WirelessHealthStatus = {
  __typename?: "WirelessHealthStatus";
  activeAps?: Maybe<Scalars["Int"]>;
  inactiveAps?: Maybe<Scalars["Int"]>;
  kpiAverages?: Maybe<WirelessKpi>;
};

export type WirelessKpi = {
  __typename?: "WirelessKPI";
  DHCP?: Maybe<Scalars["Float"]>;
  MOSVOIPDOWN?: Maybe<Scalars["Float"]>;
  MOSVOIPUP?: Maybe<Scalars["Float"]>;
  RADIUS?: Maybe<Scalars["Float"]>;
  THROUGHPUTDOWN?: Maybe<Scalars["Float"]>;
  THROUGHPUTUP?: Maybe<Scalars["Float"]>;
};

/** Information about wireless */
export type WirelessLocationActivityType = {
  __typename?: "WirelessLocationActivityType";
  activeAccessPoints?: Maybe<Scalars["Int"]>;
  inactiveAccessPoints?: Maybe<Scalars["Int"]>;
};

/** Information about wireless location */
export type WirelessLocationDetailsType = {
  __typename?: "WirelessLocationDetailsType";
  accessPoints: Array<AccessPointType>;
  activeAccessPoints?: Maybe<Scalars["Int"]>;
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  inactiveAccessPoints?: Maybe<Scalars["Int"]>;
  institute?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

/** Information about wireless */
export type WirelessSubscriptionDetailsType = SubscriptionDetails & {
  __typename?: "WirelessSubscriptionDetailsType";
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  location?: Maybe<WirelessLocationDetailsType>;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  sensorPresent: Scalars["Boolean"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  supplier: Scalars["String"];
  tags: Array<Scalars["String"]>;
};

/** Filter subscriptions by attribute, all ranges are inclusive */
export type WirelessSubscriptionFilter = {
  /** Search subscriptions accessible to this customer */
  accessCustomerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search by description */
  description?: InputMaybe<Scalars["String"]>;
  /** Search by product id */
  productId?: InputMaybe<Scalars["UUID"]>;
  /** Search by status */
  status?: InputMaybe<SubscriptionLifecycle>;
  /** Search by subscription id */
  subscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** Sort ordering of results */
export type WirelessSubscriptionSort = {
  customerId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscriptionId?: InputMaybe<SortOrder>;
};

/** Information about SURFwireless */
export type WirelessSubscriptionType = SubscriptionDetails & {
  __typename?: "WirelessSubscriptionType";
  customer?: Maybe<CustomerType>;
  customerDescription: Scalars["String"];
  customerDescriptions: Array<CustomerDescriptionType>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  endDate?: Maybe<Scalars["Int"]>;
  insync: Scalars["Boolean"];
  location: WirelessLocationActivityType;
  locations: Array<LocationType>;
  minimalImpactNotification?: Maybe<Scalars["String"]>;
  minimalImpactNotifications: Array<MinimalImpactNotificationType>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Scalars["String"]>;
  product: ProductType;
  references: Array<ReferenceType>;
  sensorPresent: Scalars["Boolean"];
  startDate?: Maybe<Scalars["Int"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  supplier: Scalars["String"];
  tags: Array<Scalars["String"]>;
};

export type WirelessSubscriptionTypeConnectionV2 = {
  __typename?: "WirelessSubscriptionTypeConnectionV2";
  page: Array<WirelessSubscriptionType>;
  pageInfo: PageInfo;
};

export type CertMitigationPrefixesQueryVariables = Exact<{
  customer: Scalars["UUID"];
}>;

export type CertMitigationPrefixesQuery = {
  __typename?: "PrivateQuery";
  ipPrefixDdos: {
    __typename?: "IpPrefixDdosTypeConnectionV2";
    page: Array<{
      __typename?: "IpPrefixDdosType";
      subscriptionId: any;
      autoMitigation: boolean;
      customerId: string;
      description: string;
      insync: boolean;
      prefix: string;
      status: string;
      version: number;
    }>;
  };
};

type BaseSubscriptionFragment_AggspSubscriptionDetailsType_Fragment = {
  __typename?: "AggspSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_FirewallSubscriptionDetailsType_Fragment = {
  __typename?: "FirewallSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_FirewallSubscriptionType_Fragment = {
  __typename?: "FirewallSubscriptionType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_IpPrefixSubscriptionType_Fragment = {
  __typename?: "IpPrefixSubscriptionType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_IpSubscriptionDetailsType_Fragment = {
  __typename?: "IpSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_IpSubscriptionListType_Fragment = {
  __typename?: "IpSubscriptionListType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_IrbSubscriptionDetailsType_Fragment = {
  __typename?: "IrbSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_L2VpnSubscriptionDetailsType_Fragment = {
  __typename?: "L2VPNSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_L3VpnSubscriptionDetailsType_Fragment = {
  __typename?: "L3VPNSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_LightPathSubscriptionDetailsType_Fragment = {
  __typename?: "LightPathSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_LightPathSubscriptionType_Fragment = {
  __typename?: "LightPathSubscriptionType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_MscSubscriptionDetailsType_Fragment = {
  __typename?: "MscSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_ServicePortSubscriptionDetailsType_Fragment = {
  __typename?: "ServicePortSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_WirelessSubscriptionDetailsType_Fragment = {
  __typename?: "WirelessSubscriptionDetailsType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

type BaseSubscriptionFragment_WirelessSubscriptionType_Fragment = {
  __typename?: "WirelessSubscriptionType";
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

export type BaseSubscriptionFragmentFragment =
  | BaseSubscriptionFragment_AggspSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_FirewallSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_FirewallSubscriptionType_Fragment
  | BaseSubscriptionFragment_IpPrefixSubscriptionType_Fragment
  | BaseSubscriptionFragment_IpSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_IpSubscriptionListType_Fragment
  | BaseSubscriptionFragment_IrbSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_L2VpnSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_L3VpnSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_LightPathSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_LightPathSubscriptionType_Fragment
  | BaseSubscriptionFragment_MscSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_ServicePortSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_WirelessSubscriptionDetailsType_Fragment
  | BaseSubscriptionFragment_WirelessSubscriptionType_Fragment;

export type LinkedServicesFragmentFragment = {
  __typename?: "ServiceType";
  insync: boolean;
  subscriptionId: any;
  portSubscriptionInstanceId?: any | null;
  customerId: any;
  status: string;
  description: string;
  customerDescription: string;
  organisation?: string | null;
  speed: number;
  percentage: number;
  vlanrange?: string | null;
  portSpeed?: number | null;
  amountReserved?: number | null;
  product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
  services?: Array<{
    __typename?: "ServiceType";
    description: string;
    percentage: number;
    speed: number;
    product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
  }> | null;
};

export type LinkMemberFragmentFragment = {
  __typename?: "LinkMemberDetailsType";
  subscriptionId: any;
  customerId: any;
  description: string;
  organisation?: string | null;
  customerDescription: string;
  vlanrange?: string | null;
  patchPosition?: string | null;
  ieeeInterfaceType?: string | null;
  portSpeed: number;
  amountReserved: number;
  tags: Array<string>;
  product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
  services: Array<{
    __typename?: "ServiceType";
    insync: boolean;
    subscriptionId: any;
    portSubscriptionInstanceId?: any | null;
    customerId: any;
    status: string;
    description: string;
    customerDescription: string;
    organisation?: string | null;
    speed: number;
    percentage: number;
    vlanrange?: string | null;
    portSpeed?: number | null;
    amountReserved?: number | null;
    product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
    services?: Array<{
      __typename?: "ServiceType";
      description: string;
      percentage: number;
      speed: number;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
    }> | null;
  }>;
};

export type PortSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type PortSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  servicePortDetails: {
    __typename: "ServicePortSubscriptionDetailsType";
    label?: string | null;
    locationDescription?: string | null;
    surfLocationCode?: string | null;
    portSpeed?: number | null;
    portMode?: string | null;
    autoNegotiation?: boolean | null;
    patchPosition?: string | null;
    fiberType?: string | null;
    connectorType?: string | null;
    ieeeInterfaceType?: string | null;
    domain: string;
    amountReserved: number;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    address?: {
      __typename?: "AddressType";
      city?: string | null;
      street?: string | null;
      number?: string | null;
    } | null;
    services?: Array<{
      __typename?: "ServiceType";
      insync: boolean;
      subscriptionId: any;
      portSubscriptionInstanceId?: any | null;
      customerId: any;
      status: string;
      description: string;
      customerDescription: string;
      organisation?: string | null;
      speed: number;
      percentage: number;
      vlanrange?: string | null;
      portSpeed?: number | null;
      amountReserved?: number | null;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      services?: Array<{
        __typename?: "ServiceType";
        description: string;
        percentage: number;
        speed: number;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }> | null;
    }> | null;
    aggregateParent?: { __typename?: "AggregateParentType"; subscriptionId: any; customerDescription: string } | null;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type MscSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type MscSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  mscDetails: {
    __typename?: "MscSubscriptionDetailsType";
    label?: string | null;
    locationDescription?: string | null;
    surfLocationCode?: string | null;
    portSpeed?: number | null;
    portId?: number | null;
    portMode?: string | null;
    domain: string;
    amountReserved: number;
    subscriptionInstanceId?: any | null;
    serviceTag?: number | null;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    address?: {
      __typename?: "AddressType";
      city?: string | null;
      street?: string | null;
      number?: string | null;
    } | null;
    portSubscription?: {
      __typename?: "MscPortSubscriptionType";
      subscriptionId: any;
      customerId: any;
      description: string;
    } | null;
    services: Array<{
      __typename?: "ServiceType";
      insync: boolean;
      subscriptionId: any;
      portSubscriptionInstanceId?: any | null;
      customerId: any;
      status: string;
      description: string;
      customerDescription: string;
      organisation?: string | null;
      speed: number;
      percentage: number;
      vlanrange?: string | null;
      portSpeed?: number | null;
      amountReserved?: number | null;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      services?: Array<{
        __typename?: "ServiceType";
        description: string;
        percentage: number;
        speed: number;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }> | null;
    }>;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type AggspSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type AggspSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  aggspDetails: {
    __typename?: "AggspSubscriptionDetailsType";
    label?: string | null;
    locationDescription?: string | null;
    surfLocationCode?: string | null;
    portSpeed?: number | null;
    portId?: number | null;
    portMode?: string | null;
    domain: string;
    amountReserved: number;
    subscriptionInstanceId?: any | null;
    portSubscription?: any | null;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    linkMemberSubscriptions: Array<{
      __typename?: "LinkMemberDetailsType";
      subscriptionId: any;
      customerId: any;
      description: string;
      organisation?: string | null;
      customerDescription: string;
      vlanrange?: string | null;
      patchPosition?: string | null;
      ieeeInterfaceType?: string | null;
      portSpeed: number;
      amountReserved: number;
      tags: Array<string>;
      product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
      services: Array<{
        __typename?: "ServiceType";
        insync: boolean;
        subscriptionId: any;
        portSubscriptionInstanceId?: any | null;
        customerId: any;
        status: string;
        description: string;
        customerDescription: string;
        organisation?: string | null;
        speed: number;
        percentage: number;
        vlanrange?: string | null;
        portSpeed?: number | null;
        amountReserved?: number | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
        services?: Array<{
          __typename?: "ServiceType";
          description: string;
          percentage: number;
          speed: number;
          product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
        }> | null;
      }>;
    }>;
    address?: {
      __typename?: "AddressType";
      city?: string | null;
      street?: string | null;
      number?: string | null;
    } | null;
    services?: Array<{
      __typename?: "ServiceType";
      insync: boolean;
      subscriptionId: any;
      portSubscriptionInstanceId?: any | null;
      customerId: any;
      status: string;
      description: string;
      customerDescription: string;
      organisation?: string | null;
      speed: number;
      percentage: number;
      vlanrange?: string | null;
      portSpeed?: number | null;
      amountReserved?: number | null;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      services?: Array<{
        __typename?: "ServiceType";
        description: string;
        percentage: number;
        speed: number;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }> | null;
    }> | null;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type IrbSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type IrbSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  irbDetails: {
    __typename?: "IrbSubscriptionDetailsType";
    label?: string | null;
    locationDescription?: string | null;
    surfLocationCode?: string | null;
    portSpeed?: number | null;
    portId?: number | null;
    portMode?: string | null;
    domain: string;
    amountReserved: number;
    subscriptionInstanceId?: any | null;
    portSubscription?: any | null;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    address?: {
      __typename?: "AddressType";
      city?: string | null;
      street?: string | null;
      number?: string | null;
    } | null;
    services: Array<{
      __typename?: "ServiceType";
      insync: boolean;
      subscriptionId: any;
      portSubscriptionInstanceId?: any | null;
      customerId: any;
      status: string;
      description: string;
      customerDescription: string;
      organisation?: string | null;
      speed: number;
      percentage: number;
      vlanrange?: string | null;
      portSpeed?: number | null;
      amountReserved?: number | null;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      services?: Array<{
        __typename?: "ServiceType";
        description: string;
        percentage: number;
        speed: number;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }> | null;
    }>;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type LightPathSubscriptionDetailsFragmentFragment = {
  __typename?: "LightPathSubscriptionDetailsType";
  domain: string;
  protection: string;
  serviceId: string;
  serviceSpeed: number;
  customerId: any;
  description: string;
  endDate?: number | null;
  insync: boolean;
  name?: string | null;
  organisation?: string | null;
  startDate?: number | null;
  status: string;
  subscriptionId: any;
  tags: Array<string>;
  customerDescription: string;
  product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
  saps: Array<{
    __typename?: "ServiceAttachPointType";
    name: string;
    organisation?: string | null;
    subscriptionInstanceId: any;
    tag: string;
    vlanrange: string;
  }>;
  circuits: Array<{
    __typename?: "LightPathCircuitType";
    name: string;
    remotePortShutdown?: boolean | null;
    serviceId?: string | null;
    serviceSpeed?: number | null;
    speedPolicer?: boolean | null;
    subscriptionInstanceId: any;
    tag: string;
    endpoints: Array<{
      __typename?: "ServiceAttachPointType";
      name: string;
      organisation?: string | null;
      subscriptionInstanceId: any;
      tag: string;
      vlanrange: string;
      port?: {
        __typename?: "ServicePortSubscriptionType";
        amountReserved?: number | null;
        customerDescription: string;
        description: string;
        domain: string;
        portMode: string;
        portSpeed: number;
        speed: string;
        status: string;
        subscriptionId: string;
        tags: Array<string>;
        usedVlans: Array<string>;
        customer?: { __typename?: "CustomerType"; customerId: string } | null;
        product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
        services: Array<{
          __typename?: "ServiceType";
          insync: boolean;
          subscriptionId: any;
          portSubscriptionInstanceId?: any | null;
          customerId: any;
          status: string;
          description: string;
          customerDescription: string;
          organisation?: string | null;
          speed: number;
          percentage: number;
          vlanrange?: string | null;
          portSpeed?: number | null;
          amountReserved?: number | null;
          product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
          services?: Array<{
            __typename?: "ServiceType";
            description: string;
            percentage: number;
            speed: number;
            product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
          }> | null;
        }>;
        address?: {
          __typename?: "AddressType";
          addition?: string | null;
          city?: string | null;
          countryCode?: string | null;
          extraAddressLine?: string | null;
          number?: string | null;
          street?: string | null;
          zipcode?: string | null;
        } | null;
      } | null;
    }>;
  }>;
  topology: Array<
    Array<{
      __typename?: "TopologyObjectType";
      customerDescription: string;
      otherSubscriptionId?: any | null;
      subscriptionInstanceId: any;
      topologyObjectType: string;
      vlanrange?: string | null;
      product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
    }>
  >;
  minimalImpactNotifications: Array<{ __typename?: "MinimalImpactNotificationType"; customerId: any; impact: string }>;
  references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
};

export type LightpathSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type LightpathSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  lpDetails: {
    __typename?: "LightPathSubscriptionDetailsType";
    domain: string;
    protection: string;
    serviceId: string;
    serviceSpeed: number;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    saps: Array<{
      __typename?: "ServiceAttachPointType";
      name: string;
      organisation?: string | null;
      subscriptionInstanceId: any;
      tag: string;
      vlanrange: string;
    }>;
    circuits: Array<{
      __typename?: "LightPathCircuitType";
      name: string;
      remotePortShutdown?: boolean | null;
      serviceId?: string | null;
      serviceSpeed?: number | null;
      speedPolicer?: boolean | null;
      subscriptionInstanceId: any;
      tag: string;
      endpoints: Array<{
        __typename?: "ServiceAttachPointType";
        name: string;
        organisation?: string | null;
        subscriptionInstanceId: any;
        tag: string;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          amountReserved?: number | null;
          customerDescription: string;
          description: string;
          domain: string;
          portMode: string;
          portSpeed: number;
          speed: string;
          status: string;
          subscriptionId: string;
          tags: Array<string>;
          usedVlans: Array<string>;
          customer?: { __typename?: "CustomerType"; customerId: string } | null;
          product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
          services: Array<{
            __typename?: "ServiceType";
            insync: boolean;
            subscriptionId: any;
            portSubscriptionInstanceId?: any | null;
            customerId: any;
            status: string;
            description: string;
            customerDescription: string;
            organisation?: string | null;
            speed: number;
            percentage: number;
            vlanrange?: string | null;
            portSpeed?: number | null;
            amountReserved?: number | null;
            product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
            services?: Array<{
              __typename?: "ServiceType";
              description: string;
              percentage: number;
              speed: number;
              product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
            }> | null;
          }>;
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
        } | null;
      }>;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type LightpathRedundantSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type LightpathRedundantSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  lrDetails: {
    __typename?: "LightPathSubscriptionDetailsType";
    domain: string;
    protection: string;
    serviceId: string;
    serviceSpeed: number;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    saps: Array<{
      __typename?: "ServiceAttachPointType";
      name: string;
      organisation?: string | null;
      subscriptionInstanceId: any;
      tag: string;
      vlanrange: string;
    }>;
    circuits: Array<{
      __typename?: "LightPathCircuitType";
      name: string;
      remotePortShutdown?: boolean | null;
      serviceId?: string | null;
      serviceSpeed?: number | null;
      speedPolicer?: boolean | null;
      subscriptionInstanceId: any;
      tag: string;
      endpoints: Array<{
        __typename?: "ServiceAttachPointType";
        name: string;
        organisation?: string | null;
        subscriptionInstanceId: any;
        tag: string;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          amountReserved?: number | null;
          customerDescription: string;
          description: string;
          domain: string;
          portMode: string;
          portSpeed: number;
          speed: string;
          status: string;
          subscriptionId: string;
          tags: Array<string>;
          usedVlans: Array<string>;
          customer?: { __typename?: "CustomerType"; customerId: string } | null;
          product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
          services: Array<{
            __typename?: "ServiceType";
            insync: boolean;
            subscriptionId: any;
            portSubscriptionInstanceId?: any | null;
            customerId: any;
            status: string;
            description: string;
            customerDescription: string;
            organisation?: string | null;
            speed: number;
            percentage: number;
            vlanrange?: string | null;
            portSpeed?: number | null;
            amountReserved?: number | null;
            product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
            services?: Array<{
              __typename?: "ServiceType";
              description: string;
              percentage: number;
              speed: number;
              product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
            }> | null;
          }>;
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
        } | null;
      }>;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type WirelessSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type WirelessSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  wirelessDetails: {
    __typename?: "WirelessSubscriptionDetailsType";
    sensorPresent: boolean;
    supplier: string;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    location?: {
      __typename?: "WirelessLocationDetailsType";
      country?: string | null;
      accessPoints: Array<{
        __typename?: "AccessPointType";
        brand?: string | null;
        model?: string | null;
        supplier?: string | null;
        ciStatus?: string | null;
      }>;
    } | null;
    product: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string };
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type FirewallSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type FirewallSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  firewallDetails: {
    __typename?: "FirewallSubscriptionDetailsType";
    availabilityZone?: string | null;
    customerAsn?: number | null;
    domain: string;
    firewallEnabled: boolean;
    fwAsn: number;
    protection: string;
    serviceSpeed: number;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    ipGateway?: {
      __typename?: "IpGatewayType";
      ipv4Mtu?: number | null;
      ipv6Mtu?: number | null;
      serviceSpeed: number;
      subscriptionInstanceId?: any | null;
      surfcertFilter: string;
      ipPrefixes: Array<string>;
    } | null;
    l3Endpoints: Array<{
      __typename?: "L3EndpointType";
      customerId: any;
      description: string;
      organisation?: string | null;
      otherSubscriptionId?: any | null;
      subscriptionInstanceId?: any | null;
      tags: Array<string>;
      product: { __typename?: "ProductType"; type: string; tag: string };
    }>;
    l2Endpoints: Array<{
      __typename?: "L2EndpointType";
      customerId: any;
      description: string;
      ipv4PtpPrefixes: Array<string>;
      ipv6PtpPrefixes: Array<string>;
      organisation?: string | null;
      otherSubscriptionId?: any | null;
      subscriptionInstanceId?: any | null;
      tags: Array<string>;
      product: { __typename?: "ProductType"; type: string; tag: string };
    }>;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type L2vpnSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type L2vpnSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  l2vpnDetails: {
    __typename?: "L2VPNSubscriptionDetailsType";
    firewallEnabled: boolean;
    domain: string;
    serviceSpeed: number;
    protection: string;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    circuits: Array<{
      __typename?: "L2VPNCircuitType";
      speedPolicer?: boolean | null;
      endpoints: Array<{
        __typename?: "ServiceAttachPointType";
        name: string;
        organisation?: string | null;
        subscriptionInstanceId: any;
        tag: string;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          portMode: string;
          subscriptionId: string;
          tags: Array<string>;
          speed: string;
          customerDescription: string;
          services: Array<{ __typename?: "ServiceType"; vlanrange?: string | null; insync: boolean }>;
        } | null;
        address?: {
          __typename?: "AddressType";
          city?: string | null;
          street?: string | null;
          number?: string | null;
        } | null;
      }>;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    saps: Array<{
      __typename?: "ServiceAttachPointType";
      subscriptionInstanceId: any;
      name: string;
      port?: {
        __typename?: "ServicePortSubscriptionType";
        subscriptionId: string;
        description: string;
        domain: string;
        portMode: string;
        portSpeed: number;
        amountReserved?: number | null;
        speed: string;
        status: string;
        tags: Array<string>;
        customerDescription: string;
        customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
        product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
        services: Array<{
          __typename?: "ServiceType";
          description: string;
          speed: number;
          percentage: number;
          vlanrange?: string | null;
          status: string;
          product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
        }>;
        locations: Array<{
          __typename?: "LocationType";
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
        }>;
      } | null;
    }>;
    esis: Array<{
      __typename?: "EthernetSegmentIdentifierType";
      segmentId: number;
      firewallInterconnect?: {
        __typename?: "L2InterconnectType";
        firewallSubscriptionId?: any | null;
        subscriptionInstanceId?: any | null;
      } | null;
      endpoints?: Array<{
        __typename?: "ServiceAttachPointType";
        subscriptionInstanceId: any;
        organisation?: string | null;
        name: string;
        tag: string;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          subscriptionId: string;
          description: string;
          amountReserved?: number | null;
          portSpeed: number;
          tags: Array<string>;
          customerDescription: string;
          usedVlans: Array<string>;
          customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
          locations: Array<{
            __typename?: "LocationType";
            address?: {
              __typename?: "AddressType";
              addition?: string | null;
              city?: string | null;
              number?: string | null;
              street?: string | null;
            } | null;
          }>;
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
          services: Array<{
            __typename?: "ServiceType";
            percentage: number;
            customerDescription: string;
            description: string;
            subscriptionId: any;
            vlanrange?: string | null;
            product?: { __typename?: "ProductType"; id: any; type: string; tag: string } | null;
          }>;
          product: { __typename?: "ProductType"; id: any; type: string; tag: string };
        } | null;
      }> | null;
    }>;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type L3vpnSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type L3vpnSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  l3vpnDetails: {
    __typename?: "L3VPNSubscriptionDetailsType";
    firewallEnabled: boolean;
    domain: string;
    insync: boolean;
    serviceSpeed: number;
    protection: string;
    customerId: any;
    description: string;
    endDate?: number | null;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    circuits: Array<{
      __typename?: "L3VPNCircuitType";
      speedPolicer?: boolean | null;
      endpoints: Array<{
        __typename?: "ServiceAttachPointType";
        name: string;
        organisation?: string | null;
        subscriptionInstanceId: any;
        tag: string;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          customerDescription: string;
          portMode: string;
          subscriptionId: string;
          tags: Array<string>;
          speed: string;
          services: Array<{ __typename?: "ServiceType"; vlanrange?: string | null; insync: boolean }>;
        } | null;
        address?: {
          __typename?: "AddressType";
          city?: string | null;
          street?: string | null;
          number?: string | null;
        } | null;
      }>;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    l3vpnss: { __typename?: "L3VPNServiceSettingsType"; l3vpnAsn: number };
    l3vpnsapss: Array<{
      __typename?: "L3VPNSAPServiceSettingsType";
      subscriptionInstanceId: any;
      name: string;
      bfd?: boolean | null;
      bfdMinimumInterval?: number | null;
      bfdMultiplier?: number | null;
      bgpPassword?: string | null;
      ipv4Mtu?: number | null;
      ipv6Mtu?: number | null;
      bgpMetric?: string | null;
      ipv4Address?: string | null;
      ipv4MaxPrefix?: number | null;
      ipv6Address?: string | null;
      ipv4RemoteAddress?: string | null;
      ipv6MaxPrefix?: number | null;
      ipv6RemoteAddress?: string | null;
      sap: {
        __typename?: "ServiceAttachPointType";
        subscriptionInstanceId: any;
        vlanrange: string;
        port?: {
          __typename?: "ServicePortSubscriptionType";
          description: string;
          domain: string;
          portMode: string;
          portSpeed: number;
          amountReserved?: number | null;
          speed: string;
          status: string;
          subscriptionId: string;
          tags: Array<string>;
          customerDescription: string;
          product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
          customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
          services: Array<{
            __typename?: "ServiceType";
            description: string;
            speed: number;
            percentage: number;
            vlanrange?: string | null;
            status: string;
            product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
          }>;
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
          locations: Array<{
            __typename?: "LocationType";
            address?: {
              __typename?: "AddressType";
              addition?: string | null;
              city?: string | null;
              countryCode?: string | null;
              extraAddressLine?: string | null;
              number?: string | null;
              street?: string | null;
              zipcode?: string | null;
            } | null;
          }>;
        } | null;
      };
    }>;
    firewallInterconnects: Array<{
      __typename?: "L3FwInterconnectType";
      availabilityZone: string;
      firewallAsn: string;
      ipv4Mtu?: number | null;
      ipv6Mtu?: number | null;
      ipv4Prefix?: any | null;
      ipv6Prefix?: any | null;
      ipv4MaxPrefix?: number | null;
      ipv6MaxPrefix?: number | null;
      ipv4AddressFirewall?: any | null;
      ipv6AddressFirewall?: any | null;
      firewallSubscriptionId: any;
      subscriptionInstanceId: any;
      organisation?: string | null;
      customerId: any;
      customerDescription: string;
      saps: Array<{
        __typename?: "ServiceAttachPointType";
        subscriptionInstanceId: any;
        name: string;
        tag: string;
        organisation?: string | null;
        vlanrange: string;
        address?: {
          __typename?: "AddressType";
          street?: string | null;
          number?: string | null;
          city?: string | null;
        } | null;
      }>;
    }>;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
  };
};

export type IpSapPortDataFragment = {
  __typename?: "IPServiceAttachPointType";
  port?: {
    __typename?: "ServicePortSubscriptionType";
    amountReserved?: number | null;
    description: string;
    domain: string;
    locationDescription?: string | null;
    portMode: string;
    portSpeed: number;
    speed: string;
    status: string;
    subscriptionId: string;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
    services: Array<{
      __typename?: "ServiceType";
      description: string;
      percentage: number;
      speed: number;
      status: string;
      vlanrange?: string | null;
      product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
    }>;
    locations: Array<{
      __typename?: "LocationType";
      address?: {
        __typename?: "AddressType";
        addition?: string | null;
        city?: string | null;
        countryCode?: string | null;
        extraAddressLine?: string | null;
        number?: string | null;
        street?: string | null;
        zipcode?: string | null;
      } | null;
    }>;
  } | null;
};

export type IpSapDataFragment = {
  __typename?: "IPServiceAttachPointType";
  subscriptionInstanceId: any;
  bfd?: boolean | null;
  bfdMinimumInterval?: number | null;
  bfdMultiplier?: number | null;
  bgpExportPolicy?: string | null;
  bgpHashAlgorithm?: string | null;
  bgpPassword?: string | null;
  bgpSessionPriority?: string | null;
  ipv4Mtu?: number | null;
  ipv6Mtu?: number | null;
  vlanrange: string;
  ipPrefixes: Array<string>;
  ipv4PointToPointPrefix?: {
    __typename?: "IpPointToPointPrefixType";
    clientFQDN: string;
    clientIpAddress: string;
    network: string;
    surfFQDN: string;
    surfIpAddress: string;
  } | null;
  ipv6PointToPointPrefix?: {
    __typename?: "IpPointToPointPrefixType";
    clientFQDN: string;
    clientIpAddress: string;
    network: string;
    surfFQDN: string;
    surfIpAddress: string;
  } | null;
  port?: {
    __typename?: "ServicePortSubscriptionType";
    amountReserved?: number | null;
    description: string;
    domain: string;
    locationDescription?: string | null;
    portMode: string;
    portSpeed: number;
    speed: string;
    status: string;
    subscriptionId: string;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
    services: Array<{
      __typename?: "ServiceType";
      description: string;
      percentage: number;
      speed: number;
      status: string;
      vlanrange?: string | null;
      product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
    }>;
    locations: Array<{
      __typename?: "LocationType";
      address?: {
        __typename?: "AddressType";
        addition?: string | null;
        city?: string | null;
        countryCode?: string | null;
        extraAddressLine?: string | null;
        number?: string | null;
        street?: string | null;
        zipcode?: string | null;
      } | null;
    }>;
  } | null;
};

export type IpBgpSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type IpBgpSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  ipBgpDetails: {
    __typename?: "IpSubscriptionDetailsType";
    domain: string;
    firewallEnabled: boolean;
    ipRoutingType: string;
    protection?: string | null;
    serviceSpeed?: number | null;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    ipss: { __typename?: "IpServiceSettingsType"; surfcertFilter: string; asn?: number | null };
    saps: Array<{
      __typename?: "IPServiceAttachPointType";
      subscriptionInstanceId: any;
      bfd?: boolean | null;
      bfdMinimumInterval?: number | null;
      bfdMultiplier?: number | null;
      bgpExportPolicy?: string | null;
      bgpHashAlgorithm?: string | null;
      bgpPassword?: string | null;
      bgpSessionPriority?: string | null;
      ipv4Mtu?: number | null;
      ipv6Mtu?: number | null;
      vlanrange: string;
      ipPrefixes: Array<string>;
      ipv4PointToPointPrefix?: {
        __typename?: "IpPointToPointPrefixType";
        clientFQDN: string;
        clientIpAddress: string;
        network: string;
        surfFQDN: string;
        surfIpAddress: string;
      } | null;
      ipv6PointToPointPrefix?: {
        __typename?: "IpPointToPointPrefixType";
        clientFQDN: string;
        clientIpAddress: string;
        network: string;
        surfFQDN: string;
        surfIpAddress: string;
      } | null;
      port?: {
        __typename?: "ServicePortSubscriptionType";
        amountReserved?: number | null;
        description: string;
        domain: string;
        locationDescription?: string | null;
        portMode: string;
        portSpeed: number;
        speed: string;
        status: string;
        subscriptionId: string;
        tags: Array<string>;
        customerDescription: string;
        product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
        customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
        services: Array<{
          __typename?: "ServiceType";
          description: string;
          percentage: number;
          speed: number;
          status: string;
          vlanrange?: string | null;
          product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
        }>;
        locations: Array<{
          __typename?: "LocationType";
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
        }>;
      } | null;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        topologyObjectType: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        vlanrange?: string | null;
        customerDescription: string;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export type IpStaticSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"];
  accessCustomerId: Scalars["UUID"];
}>;

export type IpStaticSubscriptionDetailsQuery = {
  __typename?: "PrivateQuery";
  ipStaticDetails: {
    __typename?: "IpSubscriptionDetailsType";
    serviceSpeed?: number | null;
    firewallEnabled: boolean;
    protection?: string | null;
    domain: string;
    ipRoutingType: string;
    customerId: any;
    description: string;
    endDate?: number | null;
    insync: boolean;
    name?: string | null;
    organisation?: string | null;
    startDate?: number | null;
    status: string;
    subscriptionId: any;
    tags: Array<string>;
    customerDescription: string;
    product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
    ipss: { __typename?: "IpServiceSettingsType"; surfcertFilter: string; asn?: number | null };
    saps: Array<{
      __typename?: "IPServiceAttachPointType";
      subscriptionInstanceId: any;
      bfd?: boolean | null;
      bfdMinimumInterval?: number | null;
      bfdMultiplier?: number | null;
      bgpExportPolicy?: string | null;
      bgpHashAlgorithm?: string | null;
      bgpPassword?: string | null;
      bgpSessionPriority?: string | null;
      ipv4Mtu?: number | null;
      ipv6Mtu?: number | null;
      vlanrange: string;
      ipPrefixes: Array<string>;
      ipv4PointToPointPrefix?: {
        __typename?: "IpPointToPointPrefixType";
        clientFQDN: string;
        clientIpAddress: string;
        network: string;
        surfFQDN: string;
        surfIpAddress: string;
      } | null;
      ipv6PointToPointPrefix?: {
        __typename?: "IpPointToPointPrefixType";
        clientFQDN: string;
        clientIpAddress: string;
        network: string;
        surfFQDN: string;
        surfIpAddress: string;
      } | null;
      port?: {
        __typename?: "ServicePortSubscriptionType";
        amountReserved?: number | null;
        description: string;
        domain: string;
        locationDescription?: string | null;
        portMode: string;
        portSpeed: number;
        speed: string;
        status: string;
        subscriptionId: string;
        tags: Array<string>;
        customerDescription: string;
        product: { __typename?: "ProductType"; id: any; name: string; tag: string; type: string };
        customer?: { __typename?: "CustomerType"; fullname: string; customerId: string } | null;
        services: Array<{
          __typename?: "ServiceType";
          description: string;
          percentage: number;
          speed: number;
          status: string;
          vlanrange?: string | null;
          product?: { __typename?: "ProductType"; type: string; tag: string; id: any; name: string } | null;
        }>;
        locations: Array<{
          __typename?: "LocationType";
          address?: {
            __typename?: "AddressType";
            addition?: string | null;
            city?: string | null;
            countryCode?: string | null;
            extraAddressLine?: string | null;
            number?: string | null;
            street?: string | null;
            zipcode?: string | null;
          } | null;
        }>;
      } | null;
    }>;
    topology: Array<
      Array<{
        __typename?: "TopologyObjectType";
        customerDescription: string;
        otherSubscriptionId?: any | null;
        subscriptionInstanceId: any;
        topologyObjectType: string;
        vlanrange?: string | null;
        product?: { __typename?: "ProductType"; id: any; name: string; type: string; tag: string } | null;
      }>
    >;
    minimalImpactNotifications: Array<{
      __typename?: "MinimalImpactNotificationType";
      customerId: any;
      impact: string;
    }>;
    references: Array<{ __typename?: "ReferenceType"; externalId: string; name: string }>;
  };
};

export const LinkedServicesFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LinkedServicesFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ServiceType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "insync" } },
          { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
          { kind: "Field", name: { kind: "Name", value: "portSubscriptionInstanceId" } },
          { kind: "Field", name: { kind: "Name", value: "customerId" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
          { kind: "Field", name: { kind: "Name", value: "organisation" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "speed" } },
          { kind: "Field", name: { kind: "Name", value: "percentage" } },
          { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
          { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
          { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "tag" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "services" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "percentage" } },
                { kind: "Field", name: { kind: "Name", value: "speed" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LinkedServicesFragmentFragment, unknown>;
export const LinkMemberFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LinkMemberFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LinkMemberDetailsType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
          { kind: "Field", name: { kind: "Name", value: "customerId" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "organisation" } },
          { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
          { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
          { kind: "Field", name: { kind: "Name", value: "patchPosition" } },
          { kind: "Field", name: { kind: "Name", value: "ieeeInterfaceType" } },
          { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
          { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
          { kind: "Field", name: { kind: "Name", value: "tags" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "tag" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "services" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LinkedServicesFragment" } }],
            },
          },
        ],
      },
    },
    ...LinkedServicesFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<LinkMemberFragmentFragment, unknown>;
export const BaseSubscriptionFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BaseSubscriptionFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SubscriptionDetails" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "customerId" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "endDate" } },
          { kind: "Field", name: { kind: "Name", value: "insync" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "organisation" } },
          { kind: "Field", name: { kind: "Name", value: "startDate" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
          { kind: "Field", name: { kind: "Name", value: "tags" } },
          { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "minimalImpactNotifications" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "customerId" } },
                { kind: "Field", name: { kind: "Name", value: "impact" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "tag" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "references" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "externalId" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseSubscriptionFragmentFragment, unknown>;
export const LightPathSubscriptionDetailsFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LightPathSubscriptionDetailsFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LightPathSubscriptionDetailsType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
          { kind: "Field", name: { kind: "Name", value: "domain" } },
          { kind: "Field", name: { kind: "Name", value: "protection" } },
          { kind: "Field", name: { kind: "Name", value: "serviceId" } },
          { kind: "Field", name: { kind: "Name", value: "serviceSpeed" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "tag" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "saps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "organisation" } },
                { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                { kind: "Field", name: { kind: "Name", value: "tag" } },
                { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "circuits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "remotePortShutdown" } },
                { kind: "Field", name: { kind: "Name", value: "serviceId" } },
                { kind: "Field", name: { kind: "Name", value: "serviceSpeed" } },
                { kind: "Field", name: { kind: "Name", value: "speedPolicer" } },
                { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                { kind: "Field", name: { kind: "Name", value: "tag" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "endpoints" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "organisation" } },
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "port" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
                            { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "domain" } },
                            { kind: "Field", name: { kind: "Name", value: "portMode" } },
                            { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
                            { kind: "Field", name: { kind: "Name", value: "speed" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                            { kind: "Field", name: { kind: "Name", value: "tags" } },
                            { kind: "Field", name: { kind: "Name", value: "usedVlans" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customer" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "customerId" } }],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "product" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                  { kind: "Field", name: { kind: "Name", value: "tag" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "services" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "LinkedServicesFragment" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "addition" } },
                                  { kind: "Field", name: { kind: "Name", value: "city" } },
                                  { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "extraAddressLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "number" } },
                                  { kind: "Field", name: { kind: "Name", value: "street" } },
                                  { kind: "Field", name: { kind: "Name", value: "zipcode" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "topology" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                { kind: "Field", name: { kind: "Name", value: "otherSubscriptionId" } },
                { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                { kind: "Field", name: { kind: "Name", value: "topologyObjectType" } },
                { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
    ...LinkedServicesFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<LightPathSubscriptionDetailsFragmentFragment, unknown>;
export const IpSapPortDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "IpSapPortData" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "IPServiceAttachPointType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "port" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "domain" } },
                { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                { kind: "Field", name: { kind: "Name", value: "portMode" } },
                { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
                { kind: "Field", name: { kind: "Name", value: "speed" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
                { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "fullname" } },
                      { kind: "Field", name: { kind: "Name", value: "customerId" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "percentage" } },
                      { kind: "Field", name: { kind: "Name", value: "speed" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "product" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "locations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "addition" } },
                            { kind: "Field", name: { kind: "Name", value: "city" } },
                            { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                            { kind: "Field", name: { kind: "Name", value: "extraAddressLine" } },
                            { kind: "Field", name: { kind: "Name", value: "number" } },
                            { kind: "Field", name: { kind: "Name", value: "street" } },
                            { kind: "Field", name: { kind: "Name", value: "zipcode" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IpSapPortDataFragment, unknown>;
export const IpSapDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "IpSapData" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "IPServiceAttachPointType" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "FragmentSpread", name: { kind: "Name", value: "IpSapPortData" } },
          { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
          { kind: "Field", name: { kind: "Name", value: "bfd" } },
          { kind: "Field", name: { kind: "Name", value: "bfdMinimumInterval" } },
          { kind: "Field", name: { kind: "Name", value: "bfdMultiplier" } },
          { kind: "Field", name: { kind: "Name", value: "bgpExportPolicy" } },
          { kind: "Field", name: { kind: "Name", value: "bgpHashAlgorithm" } },
          { kind: "Field", name: { kind: "Name", value: "bgpPassword" } },
          { kind: "Field", name: { kind: "Name", value: "bgpSessionPriority" } },
          { kind: "Field", name: { kind: "Name", value: "ipv4Mtu" } },
          { kind: "Field", name: { kind: "Name", value: "ipv6Mtu" } },
          { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
          { kind: "Field", name: { kind: "Name", value: "ipPrefixes" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "ipv4PointToPointPrefix" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "clientFQDN" } },
                { kind: "Field", name: { kind: "Name", value: "clientIpAddress" } },
                { kind: "Field", name: { kind: "Name", value: "network" } },
                { kind: "Field", name: { kind: "Name", value: "surfFQDN" } },
                { kind: "Field", name: { kind: "Name", value: "surfIpAddress" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "ipv6PointToPointPrefix" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "clientFQDN" } },
                { kind: "Field", name: { kind: "Name", value: "clientIpAddress" } },
                { kind: "Field", name: { kind: "Name", value: "network" } },
                { kind: "Field", name: { kind: "Name", value: "surfFQDN" } },
                { kind: "Field", name: { kind: "Name", value: "surfIpAddress" } },
              ],
            },
          },
        ],
      },
    },
    ...IpSapPortDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<IpSapDataFragment, unknown>;
export const CertMitigationPrefixesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CertMitigationPrefixes" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "customer" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ipPrefixDdos" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchFilter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "accessCustomerId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "customer" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "page" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "autoMitigation" } },
                      { kind: "Field", name: { kind: "Name", value: "customerId" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "insync" } },
                      { kind: "Field", name: { kind: "Name", value: "prefix" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "version" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertMitigationPrefixesQuery, CertMitigationPrefixesQueryVariables>;
export const PortSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PortSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "servicePortDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                { kind: "Field", name: { kind: "Name", value: "surfLocationCode" } },
                { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
                { kind: "Field", name: { kind: "Name", value: "portMode" } },
                { kind: "Field", name: { kind: "Name", value: "autoNegotiation" } },
                { kind: "Field", name: { kind: "Name", value: "patchPosition" } },
                { kind: "Field", name: { kind: "Name", value: "fiberType" } },
                { kind: "Field", name: { kind: "Name", value: "connectorType" } },
                { kind: "Field", name: { kind: "Name", value: "ieeeInterfaceType" } },
                { kind: "Field", name: { kind: "Name", value: "domain" } },
                { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      { kind: "Field", name: { kind: "Name", value: "street" } },
                      { kind: "Field", name: { kind: "Name", value: "number" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LinkedServicesFragment" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregateParent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
    ...LinkedServicesFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PortSubscriptionDetailsQuery, PortSubscriptionDetailsQueryVariables>;
export const MscSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MscSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "mscDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                { kind: "Field", name: { kind: "Name", value: "surfLocationCode" } },
                { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
                { kind: "Field", name: { kind: "Name", value: "portId" } },
                { kind: "Field", name: { kind: "Name", value: "portMode" } },
                { kind: "Field", name: { kind: "Name", value: "domain" } },
                { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
                { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                { kind: "Field", name: { kind: "Name", value: "serviceTag" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      { kind: "Field", name: { kind: "Name", value: "street" } },
                      { kind: "Field", name: { kind: "Name", value: "number" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "portSubscription" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "customerId" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LinkedServicesFragment" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
    ...LinkedServicesFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<MscSubscriptionDetailsQuery, MscSubscriptionDetailsQueryVariables>;
export const AggspSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AggspSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "aggspDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                { kind: "Field", name: { kind: "Name", value: "surfLocationCode" } },
                { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
                { kind: "Field", name: { kind: "Name", value: "portId" } },
                { kind: "Field", name: { kind: "Name", value: "portMode" } },
                { kind: "Field", name: { kind: "Name", value: "domain" } },
                { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
                { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                { kind: "Field", name: { kind: "Name", value: "portSubscription" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "linkMemberSubscriptions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LinkMemberFragment" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      { kind: "Field", name: { kind: "Name", value: "street" } },
                      { kind: "Field", name: { kind: "Name", value: "number" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LinkedServicesFragment" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
    ...LinkMemberFragmentFragmentDoc.definitions,
    ...LinkedServicesFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AggspSubscriptionDetailsQuery, AggspSubscriptionDetailsQueryVariables>;
export const IrbSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "IrbSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "irbDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                { kind: "Field", name: { kind: "Name", value: "locationDescription" } },
                { kind: "Field", name: { kind: "Name", value: "surfLocationCode" } },
                { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
                { kind: "Field", name: { kind: "Name", value: "portId" } },
                { kind: "Field", name: { kind: "Name", value: "portMode" } },
                { kind: "Field", name: { kind: "Name", value: "domain" } },
                { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
                { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                { kind: "Field", name: { kind: "Name", value: "portSubscription" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      { kind: "Field", name: { kind: "Name", value: "street" } },
                      { kind: "Field", name: { kind: "Name", value: "number" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LinkedServicesFragment" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
    ...LinkedServicesFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<IrbSubscriptionDetailsQuery, IrbSubscriptionDetailsQueryVariables>;
export const LightpathSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LightpathSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lpDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "LightPathSubscriptionDetailsFragment" } },
              ],
            },
          },
        ],
      },
    },
    ...LightPathSubscriptionDetailsFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<LightpathSubscriptionDetailsQuery, LightpathSubscriptionDetailsQueryVariables>;
export const LightpathRedundantSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LightpathRedundantSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lrDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "LightPathSubscriptionDetailsFragment" } },
              ],
            },
          },
        ],
      },
    },
    ...LightPathSubscriptionDetailsFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  LightpathRedundantSubscriptionDetailsQuery,
  LightpathRedundantSubscriptionDetailsQueryVariables
>;
export const WirelessSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "WirelessSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wirelessDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
                { kind: "Field", name: { kind: "Name", value: "sensorPresent" } },
                { kind: "Field", name: { kind: "Name", value: "supplier" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "country" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessPoints" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "brand" } },
                            { kind: "Field", name: { kind: "Name", value: "model" } },
                            { kind: "Field", name: { kind: "Name", value: "supplier" } },
                            { kind: "Field", name: { kind: "Name", value: "ciStatus" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<WirelessSubscriptionDetailsQuery, WirelessSubscriptionDetailsQueryVariables>;
export const FirewallSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FirewallSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "firewallDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "topology" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "otherSubscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "topologyObjectType" } },
                      { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "product" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "availabilityZone" } },
                { kind: "Field", name: { kind: "Name", value: "customerAsn" } },
                { kind: "Field", name: { kind: "Name", value: "domain" } },
                { kind: "Field", name: { kind: "Name", value: "firewallEnabled" } },
                { kind: "Field", name: { kind: "Name", value: "fwAsn" } },
                { kind: "Field", name: { kind: "Name", value: "protection" } },
                { kind: "Field", name: { kind: "Name", value: "serviceSpeed" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ipGateway" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "ipv4Mtu" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv6Mtu" } },
                      { kind: "Field", name: { kind: "Name", value: "serviceSpeed" } },
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "surfcertFilter" } },
                      { kind: "Field", name: { kind: "Name", value: "ipPrefixes" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "l3Endpoints" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "customerId" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "organisation" } },
                      { kind: "Field", name: { kind: "Name", value: "otherSubscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "product" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "l2Endpoints" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "customerId" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv4PtpPrefixes" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv6PtpPrefixes" } },
                      { kind: "Field", name: { kind: "Name", value: "organisation" } },
                      { kind: "Field", name: { kind: "Name", value: "otherSubscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "product" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<FirewallSubscriptionDetailsQuery, FirewallSubscriptionDetailsQueryVariables>;
export const L2vpnSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "L2vpnSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "l2vpnDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
                { kind: "Field", name: { kind: "Name", value: "firewallEnabled" } },
                { kind: "Field", name: { kind: "Name", value: "domain" } },
                { kind: "Field", name: { kind: "Name", value: "serviceSpeed" } },
                { kind: "Field", name: { kind: "Name", value: "protection" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "circuits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "speedPolicer" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endpoints" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "organisation" } },
                            { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                            { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "port" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "portMode" } },
                                  { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                                  { kind: "Field", name: { kind: "Name", value: "tags" } },
                                  { kind: "Field", name: { kind: "Name", value: "speed" } },
                                  { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                                        { kind: "Field", name: { kind: "Name", value: "insync" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "city" } },
                                  { kind: "Field", name: { kind: "Name", value: "street" } },
                                  { kind: "Field", name: { kind: "Name", value: "number" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "topology" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "otherSubscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "topologyObjectType" } },
                      { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "product" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "saps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "port" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "domain" } },
                            { kind: "Field", name: { kind: "Name", value: "portMode" } },
                            { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
                            { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
                            { kind: "Field", name: { kind: "Name", value: "speed" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "tags" } },
                            { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customer" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "fullname" } },
                                  { kind: "Field", name: { kind: "Name", value: "customerId" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "product" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "tag" } },
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "services" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "speed" } },
                                  { kind: "Field", name: { kind: "Name", value: "percentage" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "product" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                        { kind: "Field", name: { kind: "Name", value: "tag" } },
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                                  { kind: "Field", name: { kind: "Name", value: "status" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locations" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "address" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "addition" } },
                                        { kind: "Field", name: { kind: "Name", value: "city" } },
                                        { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                                        { kind: "Field", name: { kind: "Name", value: "extraAddressLine" } },
                                        { kind: "Field", name: { kind: "Name", value: "number" } },
                                        { kind: "Field", name: { kind: "Name", value: "street" } },
                                        { kind: "Field", name: { kind: "Name", value: "zipcode" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "esis" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "segmentId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firewallInterconnect" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "firewallSubscriptionId" } },
                            { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endpoints" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                            { kind: "Field", name: { kind: "Name", value: "organisation" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                            { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "port" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
                                  { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
                                  { kind: "Field", name: { kind: "Name", value: "tags" } },
                                  { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                                  { kind: "Field", name: { kind: "Name", value: "usedVlans" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "customer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "fullname" } },
                                        { kind: "Field", name: { kind: "Name", value: "customerId" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locations" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "address" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "addition" } },
                                              { kind: "Field", name: { kind: "Name", value: "city" } },
                                              { kind: "Field", name: { kind: "Name", value: "number" } },
                                              { kind: "Field", name: { kind: "Name", value: "street" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "address" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "addition" } },
                                        { kind: "Field", name: { kind: "Name", value: "city" } },
                                        { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                                        { kind: "Field", name: { kind: "Name", value: "extraAddressLine" } },
                                        { kind: "Field", name: { kind: "Name", value: "number" } },
                                        { kind: "Field", name: { kind: "Name", value: "street" } },
                                        { kind: "Field", name: { kind: "Name", value: "zipcode" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "percentage" } },
                                        { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                                        { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "product" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "type" } },
                                              { kind: "Field", name: { kind: "Name", value: "tag" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "product" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                        { kind: "Field", name: { kind: "Name", value: "tag" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<L2vpnSubscriptionDetailsQuery, L2vpnSubscriptionDetailsQueryVariables>;
export const L3vpnSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "L3vpnSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "l3vpnDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
                { kind: "Field", name: { kind: "Name", value: "firewallEnabled" } },
                { kind: "Field", name: { kind: "Name", value: "domain" } },
                { kind: "Field", name: { kind: "Name", value: "insync" } },
                { kind: "Field", name: { kind: "Name", value: "serviceSpeed" } },
                { kind: "Field", name: { kind: "Name", value: "protection" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "references" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "externalId" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "circuits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endpoints" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "organisation" } },
                            { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                            { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "port" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                                  { kind: "Field", name: { kind: "Name", value: "portMode" } },
                                  { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                                  { kind: "Field", name: { kind: "Name", value: "tags" } },
                                  { kind: "Field", name: { kind: "Name", value: "speed" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                                        { kind: "Field", name: { kind: "Name", value: "insync" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "city" } },
                                  { kind: "Field", name: { kind: "Name", value: "street" } },
                                  { kind: "Field", name: { kind: "Name", value: "number" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "speedPolicer" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "topology" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "otherSubscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "topologyObjectType" } },
                      { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "product" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "l3vpnss" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "l3vpnAsn" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "l3vpnsapss" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "bfd" } },
                      { kind: "Field", name: { kind: "Name", value: "bfdMinimumInterval" } },
                      { kind: "Field", name: { kind: "Name", value: "bfdMultiplier" } },
                      { kind: "Field", name: { kind: "Name", value: "bgpPassword" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv4Mtu" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv6Mtu" } },
                      { kind: "Field", name: { kind: "Name", value: "bgpMetric" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv4Address" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv4MaxPrefix" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv6Address" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv4RemoteAddress" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv6MaxPrefix" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv6RemoteAddress" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sap" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                            { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "port" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "domain" } },
                                  { kind: "Field", name: { kind: "Name", value: "portMode" } },
                                  { kind: "Field", name: { kind: "Name", value: "portSpeed" } },
                                  { kind: "Field", name: { kind: "Name", value: "amountReserved" } },
                                  { kind: "Field", name: { kind: "Name", value: "speed" } },
                                  { kind: "Field", name: { kind: "Name", value: "status" } },
                                  { kind: "Field", name: { kind: "Name", value: "subscriptionId" } },
                                  { kind: "Field", name: { kind: "Name", value: "tags" } },
                                  { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "product" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "tag" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "customer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "fullname" } },
                                        { kind: "Field", name: { kind: "Name", value: "customerId" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "speed" } },
                                        { kind: "Field", name: { kind: "Name", value: "percentage" } },
                                        { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                                        { kind: "Field", name: { kind: "Name", value: "status" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "product" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "type" } },
                                              { kind: "Field", name: { kind: "Name", value: "tag" } },
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "name" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "address" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "addition" } },
                                        { kind: "Field", name: { kind: "Name", value: "city" } },
                                        { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                                        { kind: "Field", name: { kind: "Name", value: "extraAddressLine" } },
                                        { kind: "Field", name: { kind: "Name", value: "number" } },
                                        { kind: "Field", name: { kind: "Name", value: "street" } },
                                        { kind: "Field", name: { kind: "Name", value: "zipcode" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locations" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "address" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "addition" } },
                                              { kind: "Field", name: { kind: "Name", value: "city" } },
                                              { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                                              { kind: "Field", name: { kind: "Name", value: "extraAddressLine" } },
                                              { kind: "Field", name: { kind: "Name", value: "number" } },
                                              { kind: "Field", name: { kind: "Name", value: "street" } },
                                              { kind: "Field", name: { kind: "Name", value: "zipcode" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firewallInterconnects" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "availabilityZone" } },
                      { kind: "Field", name: { kind: "Name", value: "firewallAsn" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv4Mtu" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv6Mtu" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv4Prefix" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv6Prefix" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv4MaxPrefix" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv6MaxPrefix" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv4AddressFirewall" } },
                      { kind: "Field", name: { kind: "Name", value: "ipv6AddressFirewall" } },
                      { kind: "Field", name: { kind: "Name", value: "firewallSubscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "organisation" } },
                      { kind: "Field", name: { kind: "Name", value: "customerId" } },
                      { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "saps" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                            { kind: "Field", name: { kind: "Name", value: "organisation" } },
                            { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "street" } },
                                  { kind: "Field", name: { kind: "Name", value: "number" } },
                                  { kind: "Field", name: { kind: "Name", value: "city" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<L3vpnSubscriptionDetailsQuery, L3vpnSubscriptionDetailsQueryVariables>;
export const IpBgpSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "IpBgpSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ipBgpDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
                { kind: "Field", name: { kind: "Name", value: "domain" } },
                { kind: "Field", name: { kind: "Name", value: "firewallEnabled" } },
                { kind: "Field", name: { kind: "Name", value: "ipRoutingType" } },
                { kind: "Field", name: { kind: "Name", value: "protection" } },
                { kind: "Field", name: { kind: "Name", value: "serviceSpeed" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ipss" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "surfcertFilter" } },
                      { kind: "Field", name: { kind: "Name", value: "asn" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "saps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "IpSapData" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "topology" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "topologyObjectType" } },
                      { kind: "Field", name: { kind: "Name", value: "otherSubscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                      { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "product" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
    ...IpSapDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<IpBgpSubscriptionDetailsQuery, IpBgpSubscriptionDetailsQueryVariables>;
export const IpStaticSubscriptionDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "IpStaticSubscriptionDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ipStaticDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "subscriptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "subscriptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "accessCustomerId" },
                value: { kind: "Variable", name: { kind: "Name", value: "accessCustomerId" } },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "namedClient" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "name" },
                    value: { kind: "StringValue", value: "replicatedData", block: false },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "BaseSubscriptionFragment" } },
                { kind: "Field", name: { kind: "Name", value: "serviceSpeed" } },
                { kind: "Field", name: { kind: "Name", value: "firewallEnabled" } },
                { kind: "Field", name: { kind: "Name", value: "protection" } },
                { kind: "Field", name: { kind: "Name", value: "domain" } },
                { kind: "Field", name: { kind: "Name", value: "ipRoutingType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ipss" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "surfcertFilter" } },
                      { kind: "Field", name: { kind: "Name", value: "asn" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "saps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "IpSapData" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "topology" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "customerDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "otherSubscriptionId" } },
                      { kind: "Field", name: { kind: "Name", value: "subscriptionInstanceId" } },
                      { kind: "Field", name: { kind: "Name", value: "topologyObjectType" } },
                      { kind: "Field", name: { kind: "Name", value: "vlanrange" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "product" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "tag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BaseSubscriptionFragmentFragmentDoc.definitions,
    ...IpSapDataFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<IpStaticSubscriptionDetailsQuery, IpStaticSubscriptionDetailsQueryVariables>;

export type PossibleTypesResultData = {
  possibleTypes: {
    BaseEnrichedTagsSubscription: [
      "FwSubscription",
      "IpPrefixSubscription",
      "Sn8AggregatedServicePortSubscription",
      "Sn8IpBgpSubscription",
      "Sn8IpStaticSubscription",
      "Sn8IrbServicePortSubscription",
      "Sn8L2VpnSubscription",
      "Sn8L3VpnSubscription",
      "Sn8LightPathRedundantSubscription",
      "Sn8LightPathSubscription",
      "Sn8MscSubscription",
      "Sn8ServicePortSubscription",
      "SurfWirelessSubscription",
    ];
    BaseHealthSubscription: [
      "FwSubscription",
      "IpPrefixSubscription",
      "Sn8AggregatedServicePortSubscription",
      "Sn8IpBgpSubscription",
      "Sn8IpStaticSubscription",
      "Sn8IrbServicePortSubscription",
      "Sn8L2VpnSubscription",
      "Sn8L3VpnSubscription",
      "Sn8LightPathRedundantSubscription",
      "Sn8LightPathSubscription",
      "Sn8MscSubscription",
      "Sn8ServicePortSubscription",
    ];
    SubscriptionDetails: [
      "AggspSubscriptionDetailsType",
      "FirewallSubscriptionDetailsType",
      "FirewallSubscriptionType",
      "IpPrefixSubscriptionType",
      "IpSubscriptionDetailsType",
      "IpSubscriptionListType",
      "IrbSubscriptionDetailsType",
      "L2VPNSubscriptionDetailsType",
      "L3VPNSubscriptionDetailsType",
      "LightPathSubscriptionDetailsType",
      "LightPathSubscriptionType",
      "MscSubscriptionDetailsType",
      "ServicePortSubscriptionDetailsType",
      "WirelessSubscriptionDetailsType",
      "WirelessSubscriptionType",
    ];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    BaseEnrichedTagsSubscription: [
      "FwSubscription",
      "IpPrefixSubscription",
      "Sn8AggregatedServicePortSubscription",
      "Sn8IpBgpSubscription",
      "Sn8IpStaticSubscription",
      "Sn8IrbServicePortSubscription",
      "Sn8L2VpnSubscription",
      "Sn8L3VpnSubscription",
      "Sn8LightPathRedundantSubscription",
      "Sn8LightPathSubscription",
      "Sn8MscSubscription",
      "Sn8ServicePortSubscription",
      "SurfWirelessSubscription",
    ],
    BaseHealthSubscription: [
      "FwSubscription",
      "IpPrefixSubscription",
      "Sn8AggregatedServicePortSubscription",
      "Sn8IpBgpSubscription",
      "Sn8IpStaticSubscription",
      "Sn8IrbServicePortSubscription",
      "Sn8L2VpnSubscription",
      "Sn8L3VpnSubscription",
      "Sn8LightPathRedundantSubscription",
      "Sn8LightPathSubscription",
      "Sn8MscSubscription",
      "Sn8ServicePortSubscription",
    ],
    SubscriptionDetails: [
      "AggspSubscriptionDetailsType",
      "FirewallSubscriptionDetailsType",
      "FirewallSubscriptionType",
      "IpPrefixSubscriptionType",
      "IpSubscriptionDetailsType",
      "IpSubscriptionListType",
      "IrbSubscriptionDetailsType",
      "L2VPNSubscriptionDetailsType",
      "L3VPNSubscriptionDetailsType",
      "LightPathSubscriptionDetailsType",
      "LightPathSubscriptionType",
      "MscSubscriptionDetailsType",
      "ServicePortSubscriptionDetailsType",
      "WirelessSubscriptionDetailsType",
      "WirelessSubscriptionType",
    ],
  },
};
export default result;

import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ApiHelper } from "../../helpers/apihelper";
import { Router } from "@angular/router";
import { Apollo } from "apollo-angular";
import { AuthService } from "../../services/AuthService";
import { CUSTOMER_WITH_MINIMAL_IMPACT_NOTIFICATIONS_QUERY } from "../../graphql/domain/customer";
import { Sort } from "@angular/material/sort";

@Component({
  selector: "page-notification-settings",
  templateUrl: "notification-settings.html",
  styleUrls: ["notification-settings.scss"],
  standalone: false,
})
export class NotificationSettingsPage implements OnInit {
  public sortedData = [];
  public loadingState = [];
  public loading = false;
  public impactSettings = [];

  private customerId = "";

  constructor(
    private api: ApiHelper,
    private apollo: Apollo,
    private router: Router,
    public auth: AuthService,
  ) {
    this.customerId = localStorage.getItem("viewingCustomerGUID");
  }

  ngOnInit(): void {
    this.retrieveSubscriptionData();
  }

  /**
   * Get subscription data
   */
  retrieveSubscriptionData() {
    this.loading = true;

    this.apollo
      .watchQuery<any>({
        query: CUSTOMER_WITH_MINIMAL_IMPACT_NOTIFICATIONS_QUERY,
        fetchPolicy: "network-only",
        variables: {
          customerId: this.customerId,
          products: ["IP", "FW", "L2VPN", "L3VPN", "LightPath", "Port"],
        },
      })
      .valueChanges.subscribe(({ data }) => {
        data.customer.subscriptions.map((subscription) => {
          this.impactSettings[subscription.subscriptionId] = this.getImpactSetting(
            subscription.minimalImpactNotifications,
          );
          this.sortedData.push(subscription);
          this.loading = false;
        });
      });
  }

  sortData(sort: Sort) {
    const data = this.sortedData;

    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "description":
          return this.compare(a.description, b.description, isAsc);
        case "presentableName":
          return this.compare(this.presentableName(a), this.presentableName(b), isAsc);
        case "type":
          return this.compare(a.product.type, b.product.type, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  presentableName(subscription) {
    const customerDescription = subscription.customerDescriptions.find(
      (desc: any) => desc.customerId === this.customerId,
    );
    if (customerDescription) {
      return customerDescription.description;
    }

    return "-";
  }

  public getImpactSetting(minimalImpactNotifications) {
    const customerGuid = localStorage.getItem("viewingCustomerGUID");
    let minimalImpactNotification = minimalImpactNotifications.find((el) => el.customerId === customerGuid);

    if (minimalImpactNotification) {
      return minimalImpactNotification.impact;
    }

    return "Loss of Resiliency";
  }

  inProgress(subscriptionId) {
    return this.loadingState[subscriptionId] ? true : false;
  }

  impactTypeChanged(subscriptionId) {
    this.loadingState[subscriptionId] = false;

    // Only allow for infraverantwoordelijke
    const notificationSetting = this.impactSettings[subscriptionId];
    const customerGuid = localStorage.getItem("viewingCustomerGUID");
    this.api
      .set_minimal_impact_notification(customerGuid, subscriptionId, notificationSetting)
      .then((result) => {
        this.loadingState[subscriptionId] = true;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  routeToService(subscription: any) {
    this.router.navigate([`/subscription/${subscription.subscriptionId}`]);
  }
}

import { Component, EventEmitter, Output, Input, SimpleChanges, OnChanges, OnDestroy } from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { ModifyErrorDetail, ModifyResponse } from "../../../helpers/self-service/models/types";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { ServicePort } from "./ServicePort";
import { ModifyL2VPNAddPort } from "../../../helpers/self-service/modify_l2vpn";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ApiHelper } from "../../../helpers/apihelper";
import { HttpErrorResponse } from "@angular/common/http";
import { isValidVlan } from "../../../helpers/self-service/vlanhelper";
import { Apollo, ApolloBase, gql } from "apollo-angular";
import { PORT_SUBSCRIPTIONS_OF_CUSTOMER_QUERY } from "../../../graphql/replication/subscription-list";
import { Subscription as RxSubscription } from "rxjs";

@Component({
  selector: "selfservice-add-port",
  templateUrl: "selfservice-add-port.html",
  styleUrls: ["selfservice-add-port.scss", "../selfservice-loader/selfservice-loader.scss"],
  standalone: false,
})
export class SelfserviceAddPortComponent implements OnChanges, OnDestroy {
  @Input() currentStep = 1;
  @Output() dialogCloseEmitter = new EventEmitter<string>();
  @Input() subscription;
  @Input() bus: EventEmitter<any>;

  public hasErrors = false;
  public errors: ModifyErrorDetail[];
  public buttonLoading = false;
  public loadingData = false;
  public summaryItemOneExpanded = true;
  public summaryItemTwoExpanded = false;
  public availablePorts: ServicePort[] = [];
  private processId: string;

  private rxSubscriptions: RxSubscription[] = [];
  private replicatedData: ApolloBase;

  public constructor(
    public auth: AuthService,
    public subscriptionService: SubscriptionService,
    public api: ApiHelper,
    private apolloProvider: Apollo,
  ) {
    this.replicatedData = this.apolloProvider.use("replicatedData");
  }

  get selectedPorts(): ServicePort[] {
    return this.availablePorts.filter((port) => port.selected === true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"]) {
      this.loadingData = true;
      this.loadPorts(this.subscription);
    }
  }

  loadPorts(subscription: any) {
    const rxSubscription = this.replicatedData
      .query<{ subscriptions: { page: Array<ServicePort> } }>({
        query: PORT_SUBSCRIPTIONS_OF_CUSTOMER_QUERY,
        fetchPolicy: "network-only",
        variables: { customer: this.auth.viewingCustomerId },
      })
      .subscribe((data) => {
        data.data.subscriptions.page.forEach((sub) => this.addPortIfValid(subscription, sub));
      });
    this.rxSubscriptions.push(rxSubscription);

    this.loadingData = false;
  }

  validVlan(port: ServicePort, vlanString: string) {
    return isValidVlan(port, vlanString);
  }

  addPortIfValid(subscription: any, port: ServicePort) {
    const portsInSubscription = subscription.esis
      .map((esi) => esi.endpoints.map((endpoint) => endpoint.port.subscriptionId))
      .flat();
    const attachedServiceTypes = port.services.map((service) => service.product.type);
    if (
      !portsInSubscription.includes(port.subscriptionId) &&
      // old business logic that self service can not be done on port used by IP/FW due to portfolio discussion
      port.portMode === "tagged" &&
      !port.tags.includes("MSC") &&
      !attachedServiceTypes.includes("IP") &&
      !attachedServiceTypes.includes("Firewall")
    ) {
      const extendedPort = {
        ...port,
        selected: false,
      } as ServicePort;
      this.availablePorts.push(extendedPort);
    }
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  toggleSummaryItemOne() {
    this.summaryItemOneExpanded = !this.summaryItemOneExpanded;
  }

  toggleSummaryItemTwo() {
    this.summaryItemTwoExpanded = !this.summaryItemTwoExpanded;
  }

  prev() {
    this.currentStep--;
  }

  next() {
    // reset error messages
    this.errors = [];
    this.hasErrors = false;

    this.currentStep++;
  }

  submit() {
    this.buttonLoading = true;
    const modify = new ModifyL2VPNAddPort();
    const command = new SelfServiceCommand(this.subscription.subscriptionId, SelfServiceCommandKeys.ModifyL2VpnAddPort);
    // set default values (current)
    modify.subscription = this.subscription;

    const esis_to_add = this.selectedPorts.map((port: ServicePort) => [
      {
        subscription_id: port.subscriptionId,
        vlan: port.vlan,
      },
    ]);
    // Fill in only the ESIs we want to add
    modify.setEsis(esis_to_add);
    command.payload = modify;

    // reset error div trigger
    this.hasErrors = false;

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.processId = (response as ModifyResponse).pid;
        this.bus.emit({
          processId: this.processId,
          action: SelfServiceCommandKeys.ModifyL2VpnAddPort,
        });
      })
      .catch((err) => {
        this.onError(err);
        this.buttonLoading = false;
      });
  }

  onError(err: HttpErrorResponse) {
    try {
      this.errors = err.error.detail as ModifyErrorDetail[];
      this.hasErrors = true;
    } catch (e) {
      console.log("unknown error", err);
    }
  }

  ngOnDestroy() {
    this.rxSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}

import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { RssNewsItem } from "../../schemas/rss-news-item";
import { ENV } from "../../../app.runtime";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "news-headers",
  templateUrl: "./news-headers-list.component.html",
  standalone: false,
})
export class NewsHeaders {
  newsUrl = ENV.NWD_NEWS_URL;
  @Input() newsItems: RssNewsItem[] = [];

  constructor(public translate: TranslateService) {}
}

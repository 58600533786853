import { Component, Input, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "page-tab",
  templateUrl: "page-tab.html",
  styleUrls: ["page-tab.scss"],
  standalone: false,
})
export class PageTabComponent {
  @Input() activeTab = "config";
  @Input() productType: string;
  @Output() activeTabEmitter = new EventEmitter<string>();

  constructor(public dialog: MatDialog) {}

  tab(tab: string) {
    this.activeTab = tab;
    this.emitTabState(tab);
  }

  emitTabState(tab) {
    this.activeTabEmitter.emit(tab);
  }
}

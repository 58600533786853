/**
 * Created by gert on 22/03/2018.
 */
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "shorten_guid",
  pure: true,
  standalone: false,
})
export class GuidPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return "";
    }
    return value.substr(0, 8);
  }
}

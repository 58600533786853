import { Component, Input, Inject, Injectable, PLATFORM_ID, Output, EventEmitter } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
  selector: "dialog-message",
  templateUrl: "dialog-message.html",
  styleUrls: ["dialog-message.scss"],
  standalone: false,
})
export class DialogMessageComponent {
  @Output() public emitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public title: any;
  public message: any;
  public isConfirm = false;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.message = data.message;
    this.isConfirm = data.isConfirm ?? false;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  emitPositive(): void {
    this.emitter.emit(true);
    this.closeDialog();
  }

  emitNegative(): void {
    this.emitter.emit(false);
    this.closeDialog();
  }
}

import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SubscriptionMessage } from "../../../helpers/types";
import { Router } from "@angular/router";
import { SurfLog } from "../../../helpers/surflog";

@Component({
  selector: "dialog-sls",
  templateUrl: "dialog-sls.html",
  standalone: false,
})
export class DialogSlsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<DialogSlsComponent>,
    private router: Router,
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}

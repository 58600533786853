<div class="card activity-card">
  <div *ngIf="newsItems.length === 0">
    <div class="activity-card__header">
      <svg-icon class="" src="assets/images/icons/news.svg"></svg-icon>
      <h2>{{ "Overview.Header.News" | translate }}</h2>
    </div>
    <div class="activity-card__empty-state">
      <svg-icon class="activity-card__empty-state--icon" src="assets/images/icons/icon-rocket.svg"></svg-icon>
      <span class="activity-card__empty-state--text">{{ "Subscription.News.NoNews" | translate }}</span>
    </div>
  </div>
  <!-- PLANNED ACTIVITY messages -->
  <div *ngIf="newsItems.length > 0">
    <div class="activity-card__header">
      <svg-icon class="" src="assets/images/icons/news.svg"></svg-icon>
      <h2>{{ "Overview.Header.News" | translate }}</h2>
    </div>
    <ul>
      <a
        *ngFor="let item of newsItems | slice: 0 : 2; let i = index"
        href="{{ newsUrl }}/{{ translate.currentLang }}/{{ item | getNewsItemLink }}"
        class="no-style-link"
      >
        <li class="activity-card__item">
          <span class="activity-card__message" [innerHTML]="item.title"></span>
          <span class="activity-card__time">
            {{ item.pubDate | parseDate | date: "d MMMM YYYY" : undefined : translate.currentLang }}
          </span>
        </li>
      </a>
    </ul>
    <div class="activity-card__footer">
      <a href="{{ newsUrl }}/{{ translate.currentLang }}">
        <span>{{ "Overview.News.ReadMore" | translate }}</span>
      </a>
    </div>
  </div>
</div>

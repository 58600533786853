import { Input, Component, SimpleChanges, OnChanges, Inject } from "@angular/core";
import { CompleterService, CompleterData } from "../../common/ng2-completer/ng2-completer";
import { MessageService } from "../../../services/messageservice";

@Component({
  selector: "completer-location",
  templateUrl: "completer-location.html",
  standalone: false,
})
export class CompleterLocationComponent {
  @Input() locations: string[] = [];
  public location: string;
  public locationSearch: string;
  public dataService: CompleterData;
  public selectedLocations: string[] = [];
  public searchResults: string[] = [];
  public searchActive = false;

  // public organisations = ['Bestemming 1', 'Bestemming 2 (lange naam)', 'Bestemming 3' ];

  constructor(@Inject(MessageService) private msgsrv: MessageService) {}

  get uniqueLocations() {
    return this.locations.filter((v, i, s) => s.indexOf(v) === i);
  }

  search() {
    const needle = this.location;
    this.searchResults = this.uniqueLocations.filter((v) => v.indexOf(needle.toLowerCase()) !== -1);
  }

  handleCheck(target: any) {
    if (target.checked) {
      this.addLocation(target.value);
    } else {
      this.removeLocation(target.value);
    }
  }

  isLocationFiltered(loc: string) {
    return this.selectedLocations.indexOf(loc) !== -1;
  }

  addLocation(loc: string) {
    const msg = { type: "location", value: loc };
    this.msgsrv.enableFilter(msg);
    if (this.selectedLocations.indexOf(loc) === -1) {
      this.selectedLocations.push(loc);
    }
  }

  removeLocation(org: string) {
    const msg = { type: "location", value: org };
    this.msgsrv.disableFilter(msg);
    const i = this.selectedLocations.indexOf(org);
    if (i > -1) {
      this.selectedLocations.splice(i, 1);
    }
  }

  onClickedOutside() {
    if (this.searchActive) {
      this.searchActive = false;
    }
  }

  searchIsActive() {
    this.searchActive = true;
    if (!this.location || this.location === "") {
      this.searchResults = this.uniqueLocations;
    }
  }
}

import { Component, Input } from "@angular/core";
import { MessageService } from "../../../services/messageservice";

@Component({
  selector: "checktag",
  templateUrl: "checktag.html",
  standalone: false,
})
export class ChecktagComponent {
  @Input() checktagTitle = "";
  public toggleChecktag = false;

  constructor(private msgsrv: MessageService) {}

  toggleCheck() {
    this.toggleChecktag = !this.toggleChecktag;
    const msg = { type: "tag", value: this.checktagTitle };
    if (this.toggleChecktag) {
      this.msgsrv.enableFilter(msg);
    } else {
      this.msgsrv.disableFilter(msg);
    }
  }
}

import { Component, OnDestroy } from "@angular/core";
import { Subscription as RxSubscription } from "rxjs";
import { CompleterService, CompleterData, CompleterItem } from "../../common/ng2-completer/ng2-completer";
import { AuthService } from "../../../services/AuthService";
import { Customer } from "../../models/customer";

@Component({
  selector: "completer",
  templateUrl: "completer.html",
  standalone: false,
})
export class CompleterComponent implements OnDestroy {
  public organisation: string;
  public selectedCustomer: Customer;
  public dataService: CompleterData;
  public organisations = ["test"];
  private rxSubscriptions: RxSubscription[] = [];

  constructor(
    public auth: AuthService,
    private completerService: CompleterService,
  ) {
    this.rxSubscriptions.push(
      auth.userLoaded.subscribe((signal) => {
        this.fillDataService();
      }),
    );
  }

  ngOnDestroy() {
    this.rxSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  fillDataService() {
    this.organisations = this.auth.state.organisations.map((org) => org.name);
    this.dataService = this.completerService.local(this.auth.state.organisations, "name,abbreviation", "name");
  }

  onOrganisationSelected(selected: CompleterItem) {
    console.log("PREPARE TO BOARD %s", selected.originalObject.customerId);
    this.selectedCustomer = selected.originalObject;
  }

  switchOrganisation() {
    // ng2-completer has issues. it fails to register the 'selected' eventemitter
    // but an updated version has many build errors.
    // quick fix until a new version is released without build errors...
    this.selectedCustomer = this.auth.state.organisations.find((org) => org.name === this.organisation);
    if (this.selectedCustomer instanceof Customer) {
      console.log("Instruct AuthService to load stuff for %s", this.selectedCustomer.name);
      this.auth.switchViewingCustomer(this.selectedCustomer.customerId, "/");
    }
  }

  processKey(event: any) {
    if (event.keyCode === 13) {
      this.switchOrganisation();
    }
  }
}

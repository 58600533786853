import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "dialog-tickets",
  templateUrl: "dialog-tickets.html",
  styleUrls: ["dialog-tickets.scss"],
  standalone: false,
})
export class DialogTicketsComponent {
  public demoMessages = [
    {
      id: "SNTT-52371",
      status: "Waiting",
      heading: "7Signal eyes offline in Hanzehogeschool Groningen",
      summary:
        "7Signal eyes offline\nThis ticket is generated because KPI IP008 returned no results for yesterday (2021-11-24) ON Local OR because EYE is not a member of a network in 7Signal.\n\nZUKU-EYE01-VD1",
      urgency: "low",
      start_timestamp: "2021-11-25T09:00:21.148000+01:00",
    },
    {
      id: "SNTT-52371",
      status: "Waiting",
      heading: "7Signal eyes offline in Hanzehogeschool Groningen",
      summary:
        "7Signal eyes offline\nThis ticket is generated because KPI IP008 returned no results for yesterday (2021-11-24) ON Local OR because EYE is not a member of a network in 7Signal.\n\nZUKU-EYE01-VD1",
      urgency: "normal",
      start_timestamp: "2021-11-25T09:00:21.148000+01:00",
    },
    {
      id: "SNTT-52371",
      status: "Waiting",
      heading: "7Signal eyes offline in Hanzehogeschool Groningen",
      summary:
        "7Signal eyes offline\nThis ticket is generated because KPI IP008 returned no results for yesterday (2021-11-24) ON Local OR because EYE is not a member of a network in 7Signal.\n\nZUKU-EYE01-VD1",
      urgency: "high",
      start_timestamp: "2021-11-25T09:00:21.148000+01:00",
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogTicketsComponent>,
    private router: Router,
  ) {}

  formattableDate(d: any) {
    if (typeof d === "string") {
      return new Date(d).getTime();
    } else if (typeof d === "number") {
      return d * 1000;
    }
    return d;
  }
}

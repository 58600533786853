import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { formatDate } from "@angular/common";
import { Apollo } from "apollo-angular";
import dayjs from "dayjs";
import { AuthService } from "../../../services/AuthService";
import { MalfunctionDateChangeType } from "../../../helpers/types";
import { MessageService } from "../../../services/messageservice";
import { CIM_QUERY } from "../../../graphql/domain/cim";
import { CimResult } from "../../../graphql/custom-types";
import { Category, Impact, NotificationFilter, ProcessState } from "../../../gql/generated";
import { StorageHelper } from "../../../helpers/storage/storagehelper";

@Component({
  selector: "webarchive",
  templateUrl: "webarchive.html",
  styleUrls: ["webarchive.scss"],
  standalone: false,
})
export class WebarchiveComponent implements OnChanges {
  @Input() filterSubscriptionName = "";
  @Input() filterSubscriptionId = "";
  public webarchiveData: Map<number, { [key: string]: any }> = new Map<number, { [key: string]: any }>();
  public calendarActive = false;
  public activeYear: number = null;
  public loading = false;
  private apolloQuery: any;

  constructor(
    private msgsrv: MessageService,
    public auth: AuthService,
    private apollo: Apollo,
  ) {
    this.msgsrv.malfunctionFilterDate$.subscribe((data: MalfunctionDateChangeType) => {
      // hide the dates after a date is selected
      this.activeYear = null;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["filterSubscriptionId"]) {
      this.addDefaultValues();
      this.fetchMessages();
    }
  }

  toggleCalendar(year: number) {
    this.calendarActive = false;
    this.activeYear = null;

    if (year !== this.activeYear) {
      setTimeout(() => {
        this.calendarActive = true;
        this.activeYear = year;
      }, 100);
    }
  }

  async fetchMessages() {
    this.loading = true;
    const startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 5);

    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);

    if (this.apolloQuery) {
      this.apolloQuery.unsubscribe();
    }

    const customerId = StorageHelper.currentUser;
    const filter: NotificationFilter = {
      customerId,
      subscriptionIds: [this.filterSubscriptionId],
      beginTimestamp: startDate,
      endTimestamp: endDate,
      impacts: [Impact.Down, Impact.ReducedRedundancy, Impact.ResilienceLoss, Impact.Unknown],
      statuses: [ProcessState.Open, ProcessState.Updated, ProcessState.Closed],
    };

    this.apolloQuery = this.apollo
      .watchQuery<CimResult>({
        query: CIM_QUERY,
        variables: {
          filter,
        },
      })
      .valueChanges.subscribe(({ data }) => {
        this.aggregateData(data);
        this.loading = false;
      });
  }

  aggregateData(messagesData: CimResult) {
    messagesData.notifications.forEach((message) => {
      const messageTime = dayjs(message.startTimestamp).toDate();
      const year = messageTime.getFullYear();
      if (!this.webarchiveData.has(year)) {
        this.addDefaultValueyear(year);
      }
      this.webarchiveData.get(year).get("tickets")[messageTime.getMonth()]++;

      const dateString = formatDate(messageTime, "yyyy-MM-dd", "en-US");
      if (message.category === Category.Plannedwork) {
        this.webarchiveData.get(year).get("maintenance").push(dateString);
      } else if (message.category === Category.Malfunction) {
        this.webarchiveData.get(year).get("malfunction").push(dateString);
      }
    });
  }

  addDefaultValues() {
    this.webarchiveData = new Map<number, { [key: string]: any }>();
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 5; i++) {
      this.addDefaultValueyear(currentYear - i);
    }
  }

  addDefaultValueyear(year: number) {
    this.webarchiveData.set(year, new Map<number, { [key: string]: any }>());
    this.webarchiveData.get(year).set("tickets", [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    this.webarchiveData.get(year).set("malfunction", []);
    this.webarchiveData.get(year).set("maintenance", []);
  }

  ticketsAreZero(year: number, tickets: number[]) {
    if (year === new Date().getFullYear()) {
      return false;
    }
    if (!tickets || tickets.length === 0) {
      return true;
    }
    let isZero = true;
    for (const ticket of tickets) {
      if (ticket > 0) {
        isZero = false;
        break;
      }
    }
    return isZero;
  }
}

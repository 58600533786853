import { Component, Input, OnChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../../services/AuthService";
import { SubscriptionDetailHeaderModel } from "./subscription-detail-header.model";

@Component({
  selector: "subscription-detail-header",
  templateUrl: "subscription-detail-header.html",
  standalone: false,
})
export class SubscriptionDetailHeader implements OnChanges {
  @Input() subscription: SubscriptionDetailHeaderModel;
  @Input() isTerminated: boolean;

  isCurrentOrganisation = true;
  isPort = true;
  isWireless = true;

  constructor(
    public translate: TranslateService,
    public auth: AuthService,
  ) {}

  ngOnChanges() {
    if (this.subscription) {
      this.isCurrentOrganisation = this.auth?.isCurrentOrganisation(this.subscription.customerId);
      this.isPort = this.subscription.product.type === "Port";
      this.isWireless = this.subscription.product.type === "Wireless";
    }
  }
}

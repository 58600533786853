import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { GenericSubscriptionComponent } from "../generic-subscription";
import {
  AggspSubscriptionDetailsGQL,
  AggspSubscriptionDetailsQuery,
  AggspSubscriptionDetailsType,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";
import { StorageHelper } from "../../../../helpers/storage/storagehelper";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AggspSubscriptionDetails } from "../../types";

@Component({
  selector: "aggsp-subscription",
  templateUrl: "port-subscription.html",
  standalone: false,
})
export class AggspSubscriptionComponent extends GenericSubscriptionComponent<
  AggspSubscriptionDetailsGQL,
  AggspSubscriptionDetailsQuery,
  AggspSubscriptionDetails
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";
  public linkPortSubscription = false; // customer can view the linked port subscription?
  public portSubscriptionName = "";

  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected query: AggspSubscriptionDetailsGQL,
    protected translate: TranslateService,
  ) {
    super(api, auth, dialog, route, translate);
    this.subscriptionChange.subscribe((s) => this.onSubscriptionChange(s));
  }

  protected onQuerySuccess(result: ApolloQueryResult<AggspSubscriptionDetailsQuery>): AggspSubscriptionDetails {
    return result.data.aggspDetails as AggspSubscriptionDetails;
  }

  private onSubscriptionChange(subscription: AggspSubscriptionDetails) {
    this.portSubscriptionName = subscription.portSubscription?.customerDescription;
    this.linkPortSubscription = subscription.portSubscription?.customerId === StorageHelper.currentUser;
  }
}

import { Component, Input } from "@angular/core";

@Component({
  selector: "terminated-card",
  templateUrl: "terminated-card.html",
  styleUrls: ["terminated-card.scss"],
  standalone: false,
})
export class TerminatedCardComponent {
  @Input() date: any;
}

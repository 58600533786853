import { Component, Input } from "@angular/core";

@Component({
  selector: "selfservice-toggle",
  templateUrl: "selfservice-toggle.html",
  styleUrls: ["selfservice-toggle.scss"],
  standalone: false,
})
export class SelfserviceToggleComponent {
  @Input() active = false;
  @Input() tooltipText = "";
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { Subscription as RxSubscription } from "rxjs";
import { MessageService } from "../../../services/messageservice";
import { ApiHelper } from "../../../helpers/apihelper";
import { AuthService } from "../../../services/AuthService";
import { ProductType } from "../../../helpers/enums/productType";
import { SurfNotification } from "../../models/notification";
import { Instance } from "../../models/instance";
import { collectTags } from "../../../helpers/subscription/collect-tags";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { FilterBarComponent } from "../../common/filter-bar/filter-bar";
import { MalfunctionDateChangeType, MalfunctionFilterType } from "../../../helpers/types";
import { Apollo } from "apollo-angular";
import { BASE_SUBSCRIPTIONS_OF_TYPE_QUERY } from "../../../graphql/domain/subscription";

@Component({
  selector: "malfunction-filter",
  templateUrl: "malfunction-filter.html",
  styleUrls: ["malfunction-filter.scss"],
  standalone: false,
})
export class MalfunctionFilterComponent implements OnChanges, OnDestroy {
  @ViewChildren(FilterBarComponent) filterBarChildren: QueryList<FilterBarComponent>;
  @Input() presetSubscriptionId = "";
  @Input() presetProductType = "";

  public mobileFilterActive: boolean;
  public loadMoreActive: boolean;
  public hasMore = true;
  public initialStartDate: Date;
  public initialEndDate: Date;
  public productType = "";
  public subscription: any;

  public productTypes: Array<ProductType> = [
    ProductType.IP,
    ProductType.LightPath,
    ProductType.L2VPN,
    ProductType.L3VPN,
    ProductType.Firewall,
    ProductType.Port,
  ];
  public subscriptionList: [] = [];
  private loadNewTimeFilterPreset: boolean;
  private rxSubscriptions: RxSubscription[] = [];

  constructor(
    private msgsrv: MessageService,
    public auth: AuthService,
    public api: ApiHelper,
    private subscriptionService: SubscriptionService,
    private apollo: Apollo,
  ) {
    this.mobileFilterActive = false;
    this.loadMoreActive = false;
    this.loadNewTimeFilterPreset = true;
    this.productType = "";
    this.subscription = "";

    const rxAuth = auth.userLoaded.subscribe((d) => {
      this.getSubscriptionData();
    });
    this.rxSubscriptions.push(rxAuth);

    this.resetTimeFilter();

    /**
     * listen for changes in the webarchive datepickers
     * */
    const rxFilterDate = this.msgsrv.malfunctionFilterDate$.subscribe((data: MalfunctionDateChangeType) => {
      this.initialStartDate = new Date(data.filterDate.getTime());
      this.initialEndDate = new Date(data.filterDate.getTime());

      this.triggerFilter();
    });
    this.rxSubscriptions.push(rxFilterDate);

    /**
     * listen for loadmore event
     * */
    const rxLoadMore = this.msgsrv.malfunctionLoadMore$.subscribe((data) => {
      this.loadMore();
    });
    this.rxSubscriptions.push(rxLoadMore);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["presetSubscriptionId"]) {
      // if no preset, trigger filter
      if (this.presetSubscriptionId === null) {
        this.triggerFilter();
      }
      this.loadNewTimeFilterPreset = true;
      this.selectPresets();
    }
    if (changes["presetProductType"]) {
      this.loadNewTimeFilterPreset = true;
      if (this.presetProductType !== null) {
        this.triggerFilter();
      }
      this.selectPresets();
    }
  }

  ngOnDestroy(): void {
    this.rxSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  loadMore() {
    this.loadMoreActive = true;

    // paginate by month
    this.initialEndDate = new Date(this.initialStartDate);
    this.initialStartDate.setMonth(new Date(this.initialStartDate).getMonth() - 1);
    this.triggerFilter();
  }

  selectPresets() {
    if (this.presetSubscriptionId === "") {
      return;
    }

    if (this.presetProductType !== null) {
      this.productType = this.presetProductType;
    }

    if (this.subscriptionList[this.productType] === undefined) {
      return;
    }

    const test: [] = this.subscriptionList[this.productType];
    const subscriptionFound = test.find((value: Instance) => value.subscriptionId === this.presetSubscriptionId);

    if (subscriptionFound !== undefined) {
      this.subscription = subscriptionFound;
      this.presetProductType = "";
      this.presetSubscriptionId = "";

      this.triggerFilter();
    }
  }

  toggleFilter() {
    this.mobileFilterActive = !this.mobileFilterActive;
  }

  resetAllFilters() {
    this.productType = "";
    this.subscription = "";
    this.resetTimeFilter();
    this.loadMoreActive = false;
    this.loadNewTimeFilterPreset = true;

    this.filterBarChildren.map((item) => {
      item.reset();
    });

    this.triggerFilter();
  }

  resetTimeFilter() {
    if (this.subscription === "" && !this.loadMoreActive) {
      if (this.loadNewTimeFilterPreset) {
        this.loadNewTimeFilterPreset = false;

        this.initialStartDate = new Date();
        this.initialStartDate.setMonth(this.initialStartDate.getMonth() - 1);

        this.initialEndDate = new Date();
        this.initialEndDate.setMonth(this.initialEndDate.getMonth() + 1);
      }
    }

    if (this.subscription !== "" && this.loadNewTimeFilterPreset) {
      this.loadNewTimeFilterPreset = false;
      this.initialStartDate = new Date();
      this.initialStartDate.setFullYear(this.initialStartDate.getFullYear() - 1);
    }
  }

  productTypeChanged() {
    this.subscription = "";
    this.loadMoreActive = false;
    if (this.productType === "") {
      this.loadNewTimeFilterPreset = true;
    }
    this.triggerFilter();
  }

  checkBeforeTriggerFilter() {
    this.loadNewTimeFilterPreset = true;
    this.triggerFilter();
  }

  triggerFilter() {
    // reset the time filter if no subscription is selected AND the load more function is not triggerd
    this.resetTimeFilter();

    let subscriptionId = "";
    let presentableName = "";

    if (this.subscription) {
      subscriptionId = this.subscription.subscriptionId;
      presentableName = this.subscription.presentableName;
    }

    const msg = {
      filterStartDate: this.initialStartDate,
      filterEndDate: this.initialEndDate,
      productType: this.productType,
      subscriptionId,
      subscriptionName: presentableName,
      loadMoreActive: this.loadMoreActive,
    };

    this.msgsrv.enableMalfunctionFilter(msg);
  }

  getSubscriptionData() {
    this.apollo
      .query({
        query: BASE_SUBSCRIPTIONS_OF_TYPE_QUERY,
        variables: {
          customer: localStorage.getItem("viewingCustomerGUID"),
          products: this.productTypes,
          status: ["active"],
        },
      })
      .subscribe((result: any) => {
        for (const productType of this.productTypes) {
          this.subscriptionList[productType] = result.data.customer.subscriptions
            .filter((sub) => sub.product.type === productType)
            .map((subscription) => Object.assign(new Instance(), subscription));
        }
        this.selectPresets();
      });
  }

  showTypeFilter(productType) {
    if (this.subscriptionList[productType] === undefined) {
      return false;
    }

    const productTypeList: [] = this.subscriptionList[productType];
    if (productTypeList.length > 0) {
      return true;
    }

    return false;
  }
}

// This file informs Angular that there is a global variable called ENV
// available and that it is structured as below. The actual contents come from
// assets/env.js.

const dummy = {
  // DO NOT MODIFY OR USE; VALUES COMES FROM assets/env.js
  DOMAINS_API: "",
  DOMAINS_ENABLED: false,
  ENABLED_ROUTES: "dashboard,news,domains",
  FILTER_ACTIVE_MESSAGES: false,
  NEWS_ENABLED: false,
  NEWS_RSS_FEED_URL: "",
  NWD_API_BASE_URL: "http://mockApi/",
  NWD_DASHBOARD_URL: "",
  NWD_NEWS_URL: "",
  SELFSERVICE_ENABLED: false,
  SENTRY_DSN: "",
  WIRELESS_ENABLED: false,
};

export const ENV: {
  DOMAINS_API: string;
  DOMAINS_ENABLED: boolean;
  ENABLED_ROUTES: string;
  FILTER_ACTIVE_MESSAGES: boolean;
  NEWS_RSS_FEED_URL: string;
  NWD_API_BASE_URL: string;
  NWD_DASHBOARD_URL: string;
  NWD_NEWS_URL: string;
  SELFSERVICE_ENABLED: boolean;
  SENTRY_DSN: string;
  WIRELESS_ENABLED: boolean;
} = (window as any).ENV ?? dummy;

export const apiUrl = (path: string): string => ENV.NWD_API_BASE_URL + path;

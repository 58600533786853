/**
 * Created by gert on 14/06/2021.
 */
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "twentyfourhourtime",
  standalone: false,
})
export class TwentyFourHourTimePipe implements PipeTransform {
  transform(value: number): string {
    const t = new Date(value);
    const hours = t.getHours();
    const minutes = t.getMinutes();

    return `${hours < 10 ? 0 : ""}${hours}:${minutes < 10 ? 0 : ""}${minutes}`;
  }
}

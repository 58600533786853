import { Component, Input, Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
  selector: "dialog-order",
  templateUrl: "dialog-order.html",
  standalone: false,
})
export class DialogOrderComponent {
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogOrderComponent>,
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}

const infraRules: any = {};
for (const productType of ["Port", "IP", "IP_PREFIX", "L2VPN", "L3VPN", "LightPath", "FW"]) {
  infraRules[productType] = {
    view: [
      "Infrabeheerder",
      "Infraverantwoordelijke",
      "Beveiligingsverantwoordelijke",
      "surfcert-kernel",
      "SuperuserRO",
      "noc-fls",
      "noc-engineers",
    ],
    edit: ["Infraverantwoordelijke", "Beveiligingsverantwoordelijke", "surfcert-kernel"],
  };
}
export const AccessRules: any = {
  ...infraRules,
  Wireless: {
    view: ["SURFwireless-beheerder", "SuperuserRO", "noc-fls", "noc-engineers"],
    edit: [],
  },
  cert: {
    view: [
      "Infrabeheerder",
      "Infraverantwoordelijke",
      "Beveiligingsverantwoordelijke",
      "surfcert-kernel",
      "SuperuserRO",
      "noc-fls",
      "noc-engineers",
    ],
    edit: ["Infraverantwoordelijke", "Beveiligingsverantwoordelijke", "surfcert-kernel"],
  },
  domains: {
    view: ["DNS-Beheerder", "Domeinnamenverantwoordelijke", "SuperuserRO"],
    edit: ["DNS-Beheerder", "Domeinnamenverantwoordelijke", "SuperuserRO"],
  },
  support: {
    view: [],
    edit: [],
  },
  maintenance: {
    view: ["Infrabeheerder", "Infraverantwoordelijke", "SuperuserRO", "noc-fls", "noc-engineers"],
    edit: ["Infrabeheerder", "Infraverantwoordelijke"],
  },
  notification_settings: {
    view: ["Infrabeheerder", "Infraverantwoordelijke", "SuperuserRO"],
    edit: ["Infrabeheerder", "Infraverantwoordelijke"],
  },
};

export interface RoleCheckResult {
  ok: boolean;
  event: "requirements-not-met" | "no-roles" | "requirements-met" | "no-organization";
  need: string[];
  productType?: string;
  requestedAction?: "view" | "edit";
}

export const SuperUserRoles = ["SuperuserRO", "noc-fls", "noc-engineers"];

export const rulesAreAvailable = (productType: string): boolean => productType in AccessRules;

import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ApiHelper } from "../../helpers/apihelper";

@Component({
  selector: "page-support",
  templateUrl: "support.html",
  standalone: false,
})
export class SupportPage {
  constructor(private api: ApiHelper) {}
}

import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { GenericSubscriptionComponent } from "../generic-subscription";
import {
  PortSubscriptionDetailsGQL,
  PortSubscriptionDetailsQuery,
  ServicePortSubscriptionDetailsType,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ServicePortSubscriptionDetails } from "../../types";

@Component({
  selector: "port-subscription",
  templateUrl: "port-subscription.html",
  standalone: false,
})
export class PortSubscriptionComponent extends GenericSubscriptionComponent<
  PortSubscriptionDetailsGQL,
  PortSubscriptionDetailsQuery,
  ServicePortSubscriptionDetails
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";
  public linkPortSubscription = false; // customer can view the linked port subscription?
  public portSubscriptionName = "Port subscription name";

  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected query: PortSubscriptionDetailsGQL,
    protected translate: TranslateService,
  ) {
    super(api, auth, dialog, route, translate);
  }

  protected onQuerySuccess(result: ApolloQueryResult<PortSubscriptionDetailsQuery>): ServicePortSubscriptionDetails {
    return result.data.servicePortDetails;
  }
}

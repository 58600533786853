import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "../../../services/AuthService";

@Component({
  selector: "dialog-auth",
  templateUrl: "dialog-auth.html",
  styleUrls: ["dialog-auth.scss"],
  standalone: false,
})
export class DialogAuthComponent {
  public isAuthenticatedForSelfService = false;
  public errorMessage = false;
  public tokenValidUntil = "";
  private initialAction = "";
  private selfserviceState = "";

  constructor(
    public auth: AuthService,
    public dialogRef: MatDialogRef<DialogAuthComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selfserviceState: string;
      state: boolean;
      initialAction?: string;
    },
  ) {
    if (data.state) {
      this.isAuthenticatedForSelfService = this.auth.isAuthenticatedForSelfService();
      this.errorMessage = !this.isAuthenticatedForSelfService;
    }

    this.initialAction = data.initialAction || "";
    this.selfserviceState = data.selfserviceState || "";

    localStorage.setItem("selfservice_state", JSON.stringify(this.selfserviceState));

    this.isAuthenticatedForSelfService = this.auth.isAuthenticatedForSelfService();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  startAuthentication() {
    // this.isAuthenticatedForSelfService = true;
    this.auth.redirectToAuthorizationServer("strong", this.initialAction);
  }
}
